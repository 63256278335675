import Container from '@material-ui/core/Container';
import useStyles from './styles';
import Sidebar from './Sidebar';
import { Paper } from '@material-ui/core';

const DashboardLayout = ({ role, children }) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Sidebar role={role} />
      <main className={classes.content}>
        {/* <div className={classes.appBarSpacer} /> */}
        <Container maxWidth='md' className={classes.container}>
          <Paper className={classes.paper}>{children}</Paper>
        </Container>
      </main>
    </div>
  );
};

export default DashboardLayout;
