import React from 'react';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import useStyles from './styles';
import FacebookIcon from '@material-ui/icons/Facebook';
import InstagramIcon from '@material-ui/icons/Instagram';
import { Grid, Link } from '@material-ui/core';
import { Link as RouterLink } from 'react-router-dom';

export default function StickyFooter() {
  const classes = useStyles();

  return (
    <footer className={classes.footer}>
      <Container maxWidth='lg'>
        <Grid container>
          <Grid item md={3}>
            <Typography variant='h6' className={classes.footerTitle}>
              Conectemos
            </Typography>
            <div className={classes.socialIcons}>
              <Link
                href='https://www.facebook.com/midoctorbolivia'
                target='_blank'
                rel='noreferrer'
                className={classes.socialIcon}
              >
                <FacebookIcon fontSize='large' />
              </Link>
              <Link
                href='https://www.instagram.com/midoctorbolivia/'
                target='_blank'
                rel='noreferrer'
                className={classes.socialIcon}
              >
                <InstagramIcon fontSize='large' />{' '}
              </Link>
            </div>
          </Grid>
          <Grid item xs={12} md={3}>
            <Typography variant='h6' className={classes.footerTitle}>
              Contacto
            </Typography>
            <ul className={classes.siteMapLinks}>
              <li>
                Calle Puerto Rico #74,
                <br />
                Santa Cruz, Bolivia
              </li>
              <li>
                <br />
                Tel: <a href='tel:+59168939003'>+591 68939003</a>
              </li>
            </ul>
          </Grid>
          <Grid item xs={12} md={3}>
            <Typography variant='h6' className={classes.footerTitle}>
              Mapa de Sitio
            </Typography>
            <ul className={classes.siteMapLinks}>
              <li>
                <Link component={RouterLink} to='/nosotros'>
                  Nosotros
                </Link>
              </li>
              <li>
                <Link component={RouterLink} to='/consultorios'>
                  Consultorios
                </Link>
              </li>
              <li>
                <Link component={RouterLink} to='/doctores'>
                  Buscar Doctores
                </Link>
              </li>
              <li>
                <Link component={RouterLink} to='/register'>
                  Regístrate
                </Link>
              </li>
              <li>
                <Link component={RouterLink} to='/login'>
                  Ingresar
                </Link>
              </li>
            </ul>
          </Grid>
          <Grid item xs={12} md={3}>
            <Typography variant='h6' className={classes.footerTitle}>
              Políticas
            </Typography>
            <ul className={classes.siteMapLinks}>
              <li>
                <Link component={RouterLink} to='/terms-of-use'>
                  Términos de Uso
                </Link>
              </li>
              <li>
                <Link component={RouterLink} to='/privacy-policy'>
                  Política de Privacidad
                </Link>
              </li>
            </ul>
          </Grid>
        </Grid>
        <Typography
          variant='subtitle2'
          component='p'
          align='center'
          className={classes.copyright}
        >
          Derechos Reservados © miDoctor {new Date().getFullYear()}
        </Typography>
      </Container>
    </footer>
  );
}
