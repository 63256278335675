import React from 'react';
import HeroSection from './Sections/HeroSection/HeroSection';
import HowItWorks from './Sections/HowItWorks/HowItWorks';
import ProductSection from './Sections/ProductSection/ProductSection';
import SpecialistsSection from './Sections/SpecialistsSection/SpecialistsSection';
import StatsSection from './Sections/StatsSection/StatsSection';
import TestimonialSection from './Sections/TestimonialSection/TestimonialSection';
import Footer from '../../components/layout/Footer/Footer';

const LandingPage = () => {
  return (
    <>
      <HeroSection />
      <ProductSection />
      <HowItWorks />
      <TestimonialSection />
      <StatsSection />
      <SpecialistsSection />
      <Footer />
    </>
  );
};

export default LandingPage;
