import { Grid, Typography } from '@material-ui/core';
import MySelectInput from '../../../../components/shared/form/MySelectInput';
import MyTextInput from '../../../../components/shared/form/MyTextInput';

const _states = [
  { label: 'Beni', value: 'Beni' },
  { label: 'Cochabamba', value: 'Cochabamba' },
  { label: 'La Paz', value: 'La Paz' },
  { label: 'Oruro', value: 'Oruro' },
  { label: 'Pando', value: 'Pando' },
  { label: 'Potosí', value: 'Potosí' },
  { label: 'Santa Cruz', value: 'Santa Cruz' },
  { label: 'Tarija', value: 'Tarija' },
];

const PatientInfoForm = (props) => {
  const {
    formField: {
      name,
      lastName,
      symptoms,
      address1,
      address2,
      city,
      state,
      phone,
    },
  } = props;
  return (
    <>
      <Typography variant='h6' color='primary' align='left' gutterBottom>
        Datos de la consulta
      </Typography>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <MyTextInput
            name={symptoms.name}
            label={symptoms.label}
            placeholder='Dínos el motivo de tu consulta describiendo tus síntomas brevemente...'
            multiline
            rows={3}
            rowsMax={5}
            fullWidth
          />
        </Grid>
      </Grid>
      <Typography variant='h6' color='primary' align='left' gutterBottom>
        Datos del Paciente
      </Typography>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={6}>
          <MyTextInput name={name.name} label={name.label} fullWidth />
        </Grid>
        <Grid item xs={12} sm={6}>
          <MyTextInput name={lastName.name} label={lastName.label} fullWidth />
        </Grid>
        <Grid item xs={12}>
          <MyTextInput name={address1.name} label={address1.label} fullWidth />
        </Grid>
        <Grid item xs={12}>
          <MyTextInput name={address2.name} label={address2.label} fullWidth />
        </Grid>
        <Grid item xs={12} sm={6}>
          <MyTextInput name={city.name} label={city.label} fullWidth />
        </Grid>
        <Grid item xs={12} sm={6}>
          <MySelectInput
            name={state.name}
            label={state.label}
            data={_states}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <MyTextInput name={phone.name} label={phone.label} fullWidth />
        </Grid>
      </Grid>
    </>
  );
};

export default PatientInfoForm;
