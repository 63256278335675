import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(1, 0),
  },
  mainContent: {
    margin: theme.spacing(3, 0),
  },
  profileHeader: {
    marginBottom: theme.spacing(3),
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    padding: theme.spacing(5, 0),
    background: theme.palette.secondary.main,
    borderRadius: 5,
  },
  profileHeaderText: {
    marginTop: theme.spacing(1),
    color: 'white',
  },
  profilePhotoWrapper: {
    height: 200,
    width: 200,
    overflow: 'hidden',
    margin: '0 auto',
    borderRadius: '50%',
    '& > img': {
      objectFit: 'cover',
      objectPosition: 'center',
      width: '100%',
    },
  },
  profileContent: {
    padding: theme.spacing(2),
    '& p': {
      textTransform: 'capitalize',
    },
  },
  profileContentRow: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
}));

export default useStyles;
