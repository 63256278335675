import { useField, useFormikContext } from 'formik';
import DatePicker, { registerLocale, setDefaultLocale } from 'react-datepicker';
import { format, set, getDay, getHours, addMinutes } from 'date-fns-2';
import es from 'date-fns-2/locale/es';
import 'react-datepicker/dist/react-datepicker.css';
import { FormControl, FormHelperText, Typography } from '@material-ui/core';
import { useEffect } from 'react';
registerLocale('es', es);
setDefaultLocale('es');

const MyDateInput = ({
  label,
  schedule,
  prevBookings,
  maxPerSlot = 1,
  ...props
}) => {
  const { setFieldValue, setFieldError } = useFormikContext();
  const [field, meta] = useField(props);

  // TODO: Preselect first valid time when choosing a valid date

  const handleChange = (value) => {
    // value is a date object

    if (schedule && prevBookings) {
      // Get range of available times
      const range = Object.keys(schedule)
        .map((key) => [schedule[key][0].toDate(), schedule[key][1].toDate()])
        .sort((a, b) => a[0] - b[0]);

      const times = [];
      for (let item of range) {
        if (getDay(item[0]) === getDay(value)) {
          // TODO: convert schedule date to value's
          const start = set(value, {
            hours: item[0].getHours(),
            minutes: item[0].getMinutes(),
          });
          const end = set(value, {
            hours: item[1].getHours(),
            minutes: item[1].getMinutes(),
          });
          let currentTime = start;
          //console.log(currentTime);
          while (currentTime < end) {
            times.push(currentTime);
            currentTime = addMinutes(currentTime, 30);
          }
        }
      }

      // Filter out booked times
      const validTimes = times.filter((t) => {
        // Limit number of bookings to "maxPerSlot"
        // Create hashmap with booking <UnixTimestamp, Integer>
        const bookingAmt = {};
        for (let booking of prevBookings) {
          let unixTimestamp = Math.floor(booking.getTime() / 1000);
          if (!bookingAmt[unixTimestamp]) {
            bookingAmt[unixTimestamp] = 1;
          } else {
            bookingAmt[unixTimestamp] += 1;
          }
        }

        if (bookingAmt[Math.floor(t.getTime() / 1000)] >= maxPerSlot) {
          return false;
        }
        return true;
      });

      if (
        validTimes.length &&
        !validTimes.some(
          (t) =>
            Math.floor(t.getTime() / 1000) ===
            Math.floor(value.getTime() / 1000)
        )
      ) {
        value = validTimes[0];
      }
    }
    setFieldValue(field.name, value);
  };

  return (
    <FormControl fullWidth error={meta.touched && !!meta.error}>
      <DatePicker
        {...field}
        {...props}
        locale='es'
        selected={(field.value && new Date(field.value)) || null}
        onChange={handleChange}
      />
      <Typography variant='subtitle1'>
        {field.value &&
          format(new Date(field.value), 'MMMM d, yyyy h:mm a', { locale: es })}
      </Typography>

      <FormHelperText color='red' style={{ textAlign: 'center' }}>
        {meta.error && 'Ingresa una fecha y hora válida'}
      </FormHelperText>
    </FormControl>
  );
};

export default MyDateInput;
