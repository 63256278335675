import { useField } from 'formik';
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormHelperText,
} from '@material-ui/core';

const MyCheckbox = ({ label, termsLink, ...props }) => {
  const [field, meta] = useField(props);

  return (
    <FormControl fullWidth error={meta.touched && !!meta.error} {...props}>
      <div
        style={{
          display: 'flex',
          justifyContent: 'flex-start',
          alignItems: 'center',
        }}
      >
        <FormControlLabel
          control={
            <Checkbox {...field} checked={field.value} color='primary' />
          }
          label={label}
        />
        {termsLink && (
          <>
            Acepto los
            <a href='/terms-of-use' target='_blank'>
              {' '}
              términos y condiciones
            </a>
          </>
        )}
      </div>
      <FormHelperText color='red'>{meta.error}</FormHelperText>
    </FormControl>
  );
};
export default MyCheckbox;
