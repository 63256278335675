import {
  Button,
  Container,
  Paper,
  Step,
  StepLabel,
  Stepper,
  Typography,
} from '@material-ui/core';
import { Form, Formik } from 'formik';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import MyButton from '../../components/shared/form/MyButton';
import Footer from '../../components/layout/Footer/Footer';
import { addReservation } from '../../services/firestore';
import consultorioFormModel from './formModel/consultorioFormModel';
import formInitialValues from './formModel/formInitialValues';
import validationSchema from './formModel/validationSchema';
import DateReservationForm from './Forms/DateReservationForm';
import PatientReservationForm from './Forms/PatientReservationForm';
import ReviewReservation from './ReviewReservation/ReviewReservation';
import useStyles from './styles';

const steps = ['Lugar y Fecha', 'Datos del Paciente', 'Revisar Datos'];
const { formId, formField } = consultorioFormModel;

const ConsultorioBooking = () => {
  const classes = useStyles();
  const [activeStep, setActiveStep] = useState(0);
  const currentValidationSchema = validationSchema[activeStep];
  const isLastStep = activeStep === steps.length - 1;
  const [selectedSucursal, setSelectedSucursal] = useState('');
  const { currentUser } = useSelector((state) => state.auth);

  function _renderStepContent(step) {
    switch (step) {
      case 0:
        return (
          <DateReservationForm
            formField={formField}
            selectedSucursal={selectedSucursal}
            setSelectedSucursal={setSelectedSucursal}
          />
        );
      case 1:
        return <PatientReservationForm formField={formField} />;
      case 2:
        return <ReviewReservation selectedSucursal={selectedSucursal} />;
      default:
        return <div>Not Found</div>;
    }
  }

  async function _submitForm(values, actions) {
    try {
      await addReservation(values);
      actions.setSubmitting(false);
      setActiveStep(activeStep + 1);
    } catch (error) {
      toast.error('No se pudo finalizar la reserva');
    }
  }

  function _handleSubmit(values, actions) {
    if (isLastStep) {
      _submitForm(values, actions);
    } else {
      setActiveStep(activeStep + 1);
      actions.setTouched({});
      actions.setSubmitting(false);
    }
  }

  function _handleBack() {
    setActiveStep((prevState) => prevState - 1);
  }

  return (
    <div className={classes.root}>
      <Container maxWidth='lg' className={classes.mainContent}>
        <Paper className={classes.paper}>
          <Typography
            component='h1'
            variant='h4'
            color='primary'
            align='center'
            className={classes.title}
          >
            Reserva tu Cita en Consultorio
          </Typography>
          <Stepper activeStep={activeStep} className={classes.stepper}>
            {steps.map((label) => (
              <Step key={label}>
                <StepLabel>{label}</StepLabel>
              </Step>
            ))}
          </Stepper>
          <>
            {activeStep === steps.length ? (
              <div style={{ textAlign: 'center', padding: '5rem 0' }}>
                <Typography variant='h5' color='primary' gutterBottom>
                  Gracias por rellenar el formulario de reserva
                </Typography>
                <Typography>
                  Enseguida recibirás un correo electrónico con los datos de tu
                  reserva
                </Typography>
                <Button
                  component={Link}
                  to='/'
                  variant='contained'
                  color='primary'
                  style={{ marginTop: '2rem' }}
                >
                  Volver al Inicio
                </Button>
              </div>
            ) : (
              <Formik
                initialValues={formInitialValues}
                validationSchema={currentValidationSchema}
                onSubmit={_handleSubmit}
              >
                {({ isSubmitting, isValid }) => (
                  <Form id={formId}>
                    {_renderStepContent(activeStep)}

                    <div className={classes.buttons}>
                      {activeStep !== 0 && (
                        <Button
                          onClick={_handleBack}
                          className={classes.button}
                        >
                          Volver
                        </Button>
                      )}
                      <div className={classes.wrapper}>
                        <MyButton
                          disabled={isSubmitting || !isValid}
                          loading={isSubmitting}
                          type='submit'
                          variant='contained'
                          color='primary'
                          className={classes.button}
                          label={isLastStep ? `Reservar` : 'Seguir'}
                        />
                      </div>
                    </div>
                  </Form>
                )}
              </Formik>
            )}
          </>
        </Paper>
      </Container>
      <Footer />
    </div>
  );
};

export default ConsultorioBooking;
