import { Button, makeStyles } from '@material-ui/core';
import { useField, useFormikContext } from 'formik';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  fileName: {
    marginLeft: theme.spacing(1),
  },
}));

const MyFileInput = ({ label, variant, icon = null, ...props }) => {
  const { setFieldValue } = useFormikContext();
  const [field, meta] = useField(props);
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Button variant={variant} color='primary' component='label'>
        {icon}
        {label}
        <input
          name={field.name}
          {...props}
          onChange={(e) => setFieldValue(field.name, e.currentTarget.files[0])}
          type='file'
          accept='application/msword, application/vnd.ms-excel, application/vnd.ms-powerpoint,
text/plain, application/pdf, image/*'
          hidden
        />
      </Button>
      <span className={classes.fileName}>
        {field.value && field.value.name}
      </span>
      {meta.touched && meta.error ? <span>{meta.error}</span> : null}
    </div>
  );
};

export default MyFileInput;
