import consultorioFormModel from './consultorioFormModel';
const {
  formField: { sucursal, date, name, lastName, email, phone },
} = consultorioFormModel;

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  [sucursal.name]: '',
  [date.name]: '',
  [name.name]: '',
  [lastName.name]: '',
  [email.name]: '',
  [phone.name]: '',
};
