import { Button, Container, Grid, Paper, Typography } from '@material-ui/core';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { Link } from 'react-router-dom';
import useFirestoreDoc from '../../../hooks/useFirestoreDoc';
import { fetchDoctorProfile } from '../../../services/firestore';
import Loader from '../../layout/Loader/Loader';
import useStyles from './styles';
import EventAvailableIcon from '@material-ui/icons/EventAvailable';

const DoctorProfile = () => {
  const { doctorId } = useParams();
  const { loading, error } = useSelector((state) => state.async);
  const { authenticated, currentUser } = useSelector((state) => state.auth);
  const [profile, setProfile] = useState();
  const classes = useStyles();

  useFirestoreDoc({
    query: () => fetchDoctorProfile(doctorId),
    data: (data) => setProfile(data),
    deps: [doctorId],
  });

  if (loading || (!profile && !error)) return <Loader />;

  return (
    <div className={classes.root}>
      <Container maxWidth='md' className={classes.mainContent}>
        <div className={classes.profileHeader}>
          <div className={classes.profilePhotoWrapper}>
            <img src={profile.photoURL} height='100%' alt='user' />
          </div>
          <div className={classes.profileHeaderText}>
            <Typography variant='h4'>{profile.displayName}</Typography>
            {profile.paymentInfo && (
              <Typography align='center' gutterBottom>
                {profile.paymentInfo.appointmentRate} Bs. / Consulta
              </Typography>
            )}
          </div>
          {currentUser?.role !== 'doctor' && (
            <Button
              variant='contained'
              component={Link}
              color='primary'
              startIcon={<EventAvailableIcon />}
              to={
                authenticated
                  ? `/patient-dashboard/booking/${profile.id}`
                  : '/login'
              }
            >
              Agendar Cita
            </Button>
          )}
          {currentUser?.id === profile.id && (
            <Button
              variant='contained'
              component={Link}
              color='primary'
              startIcon={<EventAvailableIcon />}
              to={'/doctor-dashboard/profile/edit'}
            >
              Editar Perfil
            </Button>
          )}
        </div>

        <Typography variant='h5' color='primary' gutterBottom>
          Datos del Especialista
        </Typography>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <Typography color='primary' variant='subtitle2'>
              Universidad
            </Typography>
            <Typography color='info' variant='body1'>
              {profile.university}
            </Typography>
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography color='primary' variant='subtitle2'>
              Departamento
            </Typography>
            <Typography color='info' variant='body1'>
              {profile.state}
            </Typography>
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography color='primary' variant='subtitle2'>
              Especialidad Principal
            </Typography>
            <Typography color='info' variant='body1'>
              {profile.specialtyPrimary}
            </Typography>
          </Grid>
          <Grid item xs={12} md={6}>
            {profile.specialtySecondary && (
              <>
                <Typography color='primary' variant='subtitle2'>
                  Especialidad Secundaria
                </Typography>
                <Typography color='info' variant='body1'>
                  {profile.specialtySecondary}
                </Typography>
              </>
            )}
          </Grid>
          <Grid item xs={12}>
            <Typography color='primary' variant='subtitle2'>
              Descripción
            </Typography>
            <Typography color='info' variant='body1'>
              {profile.description}
            </Typography>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};

export default DoctorProfile;
