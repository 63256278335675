import { useEffect, useRef } from 'react';
import IframeResizer from 'iframe-resizer-react';
import { useLocation } from 'react-router';
import { Button, Typography } from '@material-ui/core';

const LiveesCheckout = ({ history }) => {
  const location = useLocation();
  const { appointment } = location.state;
  const { patient } = appointment;

  const liveesForm = useRef(null);

  useEffect(() => {
    // submit post form to livees
    window.scrollTo(0, 0);
    if (!appointment) {
      history.push('/patient-dashboard');
    } else {
      localStorage.setItem('bookingId', appointment.id);
      liveesForm && liveesForm.current.submit();
    }
  }, [history, appointment]);

  const liveesURL = 'https://www.livees.net/Checkout/api4';

  const postURL = `${process.env.REACT_APP_CLIENT_URL}/patient-dashboard/livees-result`;

  return (
    <div className='livees-wrapper'>
      <Typography variant='h4' color='primary' align='center'>
        Completa el pago para terminar de agendar tu consulta
      </Typography>
      {/* <Button style={{ margin: '0 auto' }} onClick={() => history.goBack()}>
        Volver
      </Button> */}
      <form
        target='livees-iframe'
        action={liveesURL}
        method='POST'
        id='ID-FORM'
        style={{ display: 'none' }}
        autoComplete='off'
        ref={liveesForm}
      >
        <input
          type='hidden'
          name='_'
          value='ne67gowu2s6fdql1tvm3i0bya0k36pfd5j922ezh59dc28x4r'
        />
        <input
          type='hidden'
          name='__'
          value='ye5870d7gh563dxaujkls19wrfmt7cq06in232643po8ve2bz'
        />
        <input type='hidden' name='postURL' value={postURL} readOnly />
        {/* <input type='text' name='amt2' value={total} readOnly /> */}
        <input type='text' name='amt2' value={appointment.total} readOnly />
        <input type='hidden' name='currency' value='BOB' />
        <input type='text' name='invno' value={appointment.id} readOnly />
        <input type='text' name='name' value={patient.name} readOnly />
        <input type='text' name='lastname' value={patient.lastName} readOnly />
        <input type='email' name='email' value={patient.email} readOnly />
        <input type='text' name='pais' value='BO' readOnly />
        <input type='text' name='ciudad' value={patient.city} readOnly />
        <input type='text' name='estado_lbl' value={patient.state} readOnly />
        <input type='tel' name='phone' value={patient.phone} readOnly />
        <input type='hidden' name='param2' value='' />
        <input type='submit' value='Ir al pago' />
      </form>
      {/* <iframe
        id='livees-iframe'
        name='livees-iframe'
        scrolling='no'
        height='100%'
        style={{ width: '100%', border: 'none' }}
      /> */}
      <div className='iframe-wrapper'>
        <IframeResizer
          heightCalculationMethod='lowestElement'
          log
          id='livees-iframe'
          name='livees-iframe'
          style={{ width: '1px', minWidth: '100%' }}
        />
      </div>
    </div>
  );
};

export default LiveesCheckout;
