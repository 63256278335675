import Container from '@material-ui/core/Container';
import useStyles from './styles';

const MainLayout = ({ children }) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <main className={classes.content}>
        {/* <div className={classes.appBarSpacer} /> */}
        <Container maxWidth='md' className={classes.container}>
          {children}
        </Container>
      </main>
    </div>
  );
};

export default MainLayout;
