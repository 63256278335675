import { makeStyles } from '@material-ui/core';

export default makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
  },
  mainContent: {
    margin: '1rem auto',
    [theme.breakpoints.up('sm')]: {
      marginTop: theme.spacing(3),
      marginBottom: theme.spacing(3),
    },
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
  },
  paper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    flexGrow: 1,
    padding: theme.spacing(2),
    [theme.breakpoints.up('sm')]: {
      padding: theme.spacing(8),
    },
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(5),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  backBtn: {
    margin: '0 auto',
    marginTop: theme.spacing(3),
    width: '200px',
  },
  termsCheckbox: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    color: theme.palette.primary.main,
    fontWeight: 600,
    fontSize: '1.1rem',
    '& a': {
      fontWeight: 600,
      color: theme.palette.secondary.main,
      textDecoration: 'none',
      transition: 'all 0.3s ease',
      marginLeft: '5px',
      '&:hover': {
        color: theme.palette.primary.main,
      },
    },
  },
}));
