import { makeStyles } from '@material-ui/core';

export default makeStyles((theme) => ({
  root: {
    overflow: 'hidden',
  },
  profileHeader: {
    padding: theme.spacing(2),
    display: 'flex',
    alignItems: 'center',
  },
  profilePhotoWrapper: {
    height: 250,
    width: 250,
    overflow: 'hidden',
    margin: '0 auto',
    borderRadius: '50%',
    '& > img': {
      objectFit: 'cover',
      objectPosition: 'center',
      width: '100%',
    },
  },
  profileContent: {
    padding: theme.spacing(2),
    '& p': {
      textTransform: 'capitalize',
    },
  },
  profileContentRow: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
  photoBtn: {
    display: 'block',
    margin: '1rem auto 3rem',
    width: 150,
    textAlign: 'center',
  },
  separator: {
    border: '2px solid black',
    backgroundColor: 'black',
    width: '100%',
    marginTop: '0.8rem',
  },
}));
