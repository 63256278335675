import { Paper, Typography } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import { useCallback, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import useStyles from './styles';

const Notifications = ({ user }) => {
  const [errors, setErrors] = useState([]);
  const classes = useStyles();

  const checkErrors = useCallback(() => {
    const errs = [];

    const hasPaymentData = user.paymentInfo ? true : false;
    const hasSchedule =
      user.schedule && Object.keys(user.schedule).length ? true : false;

    if (!hasPaymentData)
      errs.push({
        severity: 'error',
        text: 'Necesita ingresar sus información de pago. ',
        link: '/doctor-dashboard/payment',
      });

    if (!hasSchedule)
      errs.push({
        severity: 'error',
        text: 'Necesita ingresar los horarios de su consulta. ',
        link: '/doctor-dashboard/schedule',
      });

    if (user.photoURL === '/assets/images/user.png') {
      errs.push({
        severity: 'warning',
        text: 'Cambia tu foto de perfil. ',
        link: '/doctor-dashboard/profile/edit',
      });
    }
    return setErrors(errs);
  }, [user]);

  useEffect(() => {
    checkErrors();
  }, [checkErrors]);

  return (
    <div className={classes.root}>
      <Typography component='h2' variant='h6' color='primary' gutterBottom>
        Notificaciones
      </Typography>
      <div className={classes.alerts}>
        {errors.map((err, index) => (
          <Alert severity={err.severity} key={index}>
            {err.text}{' '}
            <strong>
              <Link className={classes.alertLink} to={err.link}>
                {' '}
                Resolver aquí
              </Link>
            </strong>
          </Alert>
        ))}
        {!errors.length && <Alert severity='info'>No hay notificaciones</Alert>}
      </div>
    </div>
  );
};

export default Notifications;
