import { Box, Container, Grid, Typography } from '@material-ui/core';
import img1 from '../../../../assets/images/testimonial-1.jpg';
import img2 from '../../../../assets/images/testimonial-2.jpg';
import quotes from '../../../../assets/images/quotes.svg';
import useStyles from './styles';
import clsx from 'clsx';

const TestimonialSection = () => {
  const classes = useStyles();

  return (
    <Container maxWidth='lg' className={classes.root}>
      <Grid container alignItems='center'>
        <Box clone order={{ xs: 2, sm: 1 }}>
          <Grid item xs={12} sm={5}>
            <div className={classes.testimonialText}>
              <img src={quotes} alt='quotes' className={classes.quotes} />
              <Typography variant='body1' color='primary'>
                Con miDoctor no solo puedo atender en video consultas o
                consultas presenciales, sino que también, les ofrezco las
                facilidades de reservas y pagos online
              </Typography>
              <img
                src={quotes}
                alt='quotes'
                className={clsx(classes.quotes, classes.quotesFlipped)}
              />
            </div>
            <Typography
              variant='body1'
              className={classes.textContent}
              color='primary'
            >
              Habla con tu médico especialista online y obtendrás respuestas
              oportunas y efectivas a tus consultas.
            </Typography>
          </Grid>
        </Box>
        <Box clone order={{ xs: 1, sm: 2 }}>
          <Grid item xs={12} sm={7}>
            <img src={img1} alt='' className={classes.img} />
          </Grid>
        </Box>
      </Grid>

      <Grid container className={classes.secondRow} alignItems='center'>
        <Grid item xs={12} sm={7}>
          <img src={img2} alt='' className={classes.img} />
        </Grid>

        <Grid item xs={12} sm={5}>
          <div className={classes.secondRowTextContent}>
            <Typography
              variant='h3'
              color='primary'
              className={classes.sectionTitle}
            >
              Tu salud, más organizada y conveniente
            </Typography>
            <Typography
              variant='body1'
              className={`${classes.textContent}`}
              color='primary'
            >
              Creando tu perfil de paciente en la plataforma digital miDoctor
              puedes enviarle estudios en imágenes, análisis o pruebas de
              laboratorio a tu doctor. Toda tu información estará protegida, es
              muy simple y segura, desde la comodidad de tu casa.
            </Typography>
          </div>
        </Grid>
      </Grid>
    </Container>
  );
};

export default TestimonialSection;
