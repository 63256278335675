import { makeStyles } from '@material-ui/core';

export default makeStyles((theme) => ({
  fileBtn: {
    width: '250px',
    textAlign: 'center',
    margin: '0 auto',
    marginTop: theme.spacing(5),
  },
  fileLink: {
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
  tableContainer: {
    marginTop: theme.spacing(5),
  },
  tableHead: {
    '& *': {
      fontWeight: 600,
    },
  },
  tableTitle: {
    fontWeight: 600,
  },
  permissionsRoot: {
    margin: theme.spacing(5, 0),
  },
  permissionsText: {
    marginBottom: theme.spacing(2),
  },
}));
