import { Button, Container } from '@material-ui/core';
import useStyles from './styles';
import * as Yup from 'yup';
import { useHistory } from 'react-router-dom';
import { Formik, Form } from 'formik';
import MyTextInput from '../../../../components/shared/form/MyTextInput';
import MyButton from '../../../../components/shared/form/MyButton';
import MyDivider from '../../../../components/shared/form/MyDivider';
import SocialLogin from '../../../../components/shared/SociaLogin/SocialLogin';
import { registerInFirebase } from '../../../../services/firebaseAuth';
import { toast } from 'react-toastify';
import { displayFirebaseAuthError } from '../../../../util';

const _validationSchema = Yup.object({
  email: Yup.string()
    .required('Campo requerido')
    .email('El email debe ser válido'),
  password: Yup.string()
    .min(6, 'La contraseña debe contener un mínimo de 6 caracteres')
    .required('Campo requerido'),
  confirmPassword: Yup.string().when('password', {
    is: (val) => (val && val.length > 0 ? true : false),
    then: Yup.string()
      .required('Campo requerido')
      .oneOf([Yup.ref('password')], 'Las contraseñas deben ser iguales'),
  }),
});

const AccountRegisterForm = ({ role, setRole }) => {
  const classes = useStyles();

  const handleSubmit = async (values, { setSubmitting, setFieldError }) => {
    try {
      await registerInFirebase(values, role);
      setSubmitting(false);
    } catch (error) {
      console.log(error);
      toast.error(displayFirebaseAuthError(error.code));
    }
  };

  return (
    <Container component='main' maxWidth='xs'>
      <div className={classes.paper}>
        <SocialLogin role={role} />
        <MyDivider className={classes.divider}>ó</MyDivider>
        <Formik
          initialValues={{ email: '', password: '', confirmPassword: '' }}
          validationSchema={_validationSchema}
          onSubmit={handleSubmit}
        >
          {({ isSubmitting, isValid, dirty }) => (
            <Form>
              <MyTextInput
                variant='outlined'
                fullWidth
                label='Email'
                name='email'
                className={classes.formInput}
              />
              <MyTextInput
                variant='outlined'
                fullWidth
                label='Contraseña'
                name='password'
                type='password'
                className={classes.formInput}
              />
              <MyTextInput
                variant='outlined'
                fullWidth
                label='Repetir contraseña'
                name='confirmPassword'
                type='password'
                className={classes.formInput}
              />
              <MyButton
                label='Entrar'
                type='submit'
                fullWidth
                variant='contained'
                color='primary'
                className={classes.submit}
                disabled={!isValid || !dirty || isSubmitting}
                loading={isSubmitting}
              />
              <Button
                label='volver'
                type='button'
                fullWidth
                size='small'
                color='primary'
                className={classes.backBtn}
                onClick={() => setRole(null)} // returns to user type selection
              >
                Volver
              </Button>
            </Form>
          )}
        </Formik>
      </div>
    </Container>
  );
};

export default AccountRegisterForm;
