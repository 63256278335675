import { Form, Formik } from 'formik';
import MyButton from '../../components/shared/form/MyButton';
import MyTextInput from '../../components/shared/form/MyTextInput';
import useStyles from './styles';
import * as Yup from 'yup';
import {
  addCategory,
  deleteCategory,
  getCategories,
} from '../../services/firestore';
import { useState } from 'react';
import useFirestoreCollection from '../../hooks/useFirestoreCollection';
import { toast } from 'react-toastify';
import {
  Grid,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';

const AdminCategories = () => {
  const classes = useStyles();
  const [categories, setCategories] = useState([]);

  const handleSubmit = async (values, { setSubmitting, resetForm }) => {
    try {
      await addCategory(values);
      resetForm();
    } catch (error) {
      console.log(error);
      toast.error('Error creando categoria');
    }
  };

  const handleDelete = async (id) => {
    try {
      await deleteCategory(id);
      toast.success('Categoría borrada');
    } catch (error) {
      toast.error('Error borrando categoría');
    }
  };

  useFirestoreCollection({
    query: () => getCategories(),
    data: (categories) => setCategories(categories),
    deps: [],
  });

  return (
    <div>
      <Formik
        initialValues={{ name: '' }}
        validationSchema={Yup.object({
          name: Yup.string().required('Campo requerido'),
        })}
        onSubmit={handleSubmit}
      >
        {({ isSubmitting, isValid, dirty }) => (
          <Form>
            <Grid container direction='column'>
              <MyTextInput
                variant='outlined'
                margin='normal'
                label='Nombre'
                name='name'
              />
              <MyButton
                label='Crear'
                type='submit'
                variant='contained'
                color='primary'
                className={classes.submit}
                disabled={!isValid || !dirty || isSubmitting}
                loading={isSubmitting}
              />
            </Grid>
          </Form>
        )}
      </Formik>
      <TableContainer className={classes.tableContainer}>
        <Table aria-label='simple table'>
          <TableHead className={classes.tableHead}>
            <TableRow>
              <TableCell>Nombre</TableCell>
              <TableCell align='right'>Borrar</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {categories.map((category) => (
              <TableRow key={category.id}>
                <TableCell component='th' scope='row'>
                  {category.name}
                </TableCell>
                <TableCell align='right'>
                  <IconButton
                    aria-label='delete'
                    className={classes.margin}
                    size='small'
                    onClick={() => handleDelete(category.id)}
                  >
                    <DeleteIcon fontSize='inherit' />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default AdminCategories;
