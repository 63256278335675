import { useState } from 'react';
import {
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core';
import GetAppIcon from '@material-ui/icons/GetApp';

import useFirestoreDoc from '../../../hooks/useFirestoreDoc';
import { getUserProfile } from '../../../services/firestore';

import useStyles from './styles';

const FilesTable = ({ booking }) => {
  const classes = useStyles();
  const [files, setFiles] = useState([]);

  useFirestoreDoc({
    query: () => getUserProfile(booking.patient.id),
    data: (user) => {
      if (user.files && user.files) {
        setFiles(
          user.files.filter((file) => {
            if (file.permissions) {
              return file.permissions.includes(booking.doctor.id);
            }
            return false;
          })
        );
      }
    },
    deps: [booking],
  });

  return (
    <TableContainer className={classes.tableContainer}>
      <Typography
        variant='body1'
        color='primary'
        align='center'
        className={classes.title}
      >
        Archivos Compartidos
      </Typography>
      <Table aria-label='simple table'>
        <TableHead className={classes.tableHead}>
          <TableRow>
            <TableCell component='th'>Nombre de Archivo</TableCell>
            <TableCell component='th' align='center'>
              Descargar
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {files.map((file) => (
            <TableRow key={file.id}>
              <TableCell scope='row'>{file.name}</TableCell>

              <TableCell scope='row' align='center'>
                <IconButton
                  component='a'
                  aria-label='download'
                  size='medium'
                  href={file.path}
                  target='_blank'
                  rel='noreferrer'
                >
                  <GetAppIcon />
                </IconButton>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>

      {!files.length && (
        <Typography align='center' style={{ padding: '5rem' }}>
          No hay archivos compartidos para mostrar
        </Typography>
      )}
    </TableContainer>
  );
};

export default FilesTable;
