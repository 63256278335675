import { makeStyles } from '@material-ui/core';

export default makeStyles((theme) => ({
  socialBtn: {
    color: 'white',
    textTransform: 'none',
    marginBottom: theme.spacing(1),
  },
  socialBtnFacebook: {
    backgroundColor: '#4267B2',
    '&:hover': {
      backgroundColor: '#375695',
    },
  },
  socialBtnGoogle: {
    backgroundColor: '#de5246 ',
    '& img': {
      marginRight: '0.5rem',
    },
    '&:hover': {
      backgroundColor: '#d93526',
    },
  },
}));
