import firebase, { storage } from '../config/firebase';
import { getFileExtension } from '../util';
import cuid from 'cuid';

export const uploadProfilePhoto = (file) => {
  const user = firebase.auth().currentUser;

  const filename = cuid() + '.' + getFileExtension(file.name);

  const storageRef = storage.ref();
  return storageRef
    .child(`users/${user.uid}/user_images/${filename}`)
    .put(file);
};

export const uploadTaxDocs = (file) => {
  const user = firebase.auth().currentUser;

  const filename = cuid() + '.' + getFileExtension(file.name);

  const storageRef = storage.ref();
  return storageRef.child(`users/${user.uid}/tax_docs/${filename}`).put(file);
};

export const uploadSedesDocs = (file) => {
  const user = firebase.auth().currentUser;

  const filename = cuid() + '.' + getFileExtension(file.name);

  const storageRef = storage.ref();
  return storageRef.child(`users/${user.uid}/sedes_docs/${filename}`).put(file);
};

export const uploadAcademicDocs = (file) => {
  const user = firebase.auth().currentUser;

  const filename = cuid() + '.' + getFileExtension(file.name);

  const storageRef = storage.ref();
  return storageRef
    .child(`users/${user.uid}/academic_docs/${filename}`)
    .put(file);
};

export const uploadSpecalizationDocs = (file) => {
  const user = firebase.auth().currentUser;

  const filename = cuid() + '.' + getFileExtension(file.name);

  const storageRef = storage.ref();
  return storageRef
    .child(`users/${user.uid}/academic_docs/${filename}`)
    .put(file);
};

export const uploadUserFile = (file) => {
  const user = firebase.auth().currentUser;

  const filename = cuid() + '.' + getFileExtension(file.name);

  const storageRef = storage.ref();
  return storageRef.child(`users/${user.uid}/userFiles/${file.name}`).put(file);
};

export const deleteUserFileFromStorage = async (filename) => {
  const user = firebase.auth().currentUser;

  const storageRef = storage.ref();
  return await storageRef
    .child(`users/${user.uid}/userFiles/${filename}`)
    .delete();
};

export const uploadBookingFile = (bookingId, file) => {
  const filename = cuid() + '.' + getFileExtension(file.name);

  const storageRef = storage.ref();
  return storageRef.child(`bookings/${bookingId}/${filename}`).put(file);
};
