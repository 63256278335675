import firebase from '../config/firebase';

const db = firebase.firestore();

/* Helper function: Adds an id field to data object obtained from snapshot */
export const dataFromSnapshot = (snapshot) => {
  if (!snapshot.exists) return undefined;
  const data = snapshot.data();

  for (const prop in data) {
    if (data.hasOwnProperty(prop)) {
      if (data[prop] instanceof firebase.firestore.Timestamp) {
        data[prop] = data[prop].toDate();
      }
    }
  }

  return {
    ...data,
    id: snapshot.id,
  };
};

/* Set User profile data in db */
export const setNewUserProfileData = (user) => {
  return db
    .collection('users')
    .doc(user.uid)
    .set({
      displayName: user.displayName,
      email: user.email,
      photoURL: user.photoURL || '/assets/images/user.png',
      createdAt: firebase.firestore.FieldValue.serverTimestamp(),
      role: user.role,
      activated: user.activated,
      isProcessing: false,
      emailVerified: user.emailVerified,
    });
};

/* Set Lab profile data in db */
export const setNewLabProfileData = (user) => {
  console.log(user);
  const { billingName, billingId, address1, address2, state, phone } = user;

  return db
    .collection('users')
    .doc(user.uid)
    .set({
      displayName: user.displayName,
      billingName,
      billingId,
      address1,
      address2,
      state,
      phone,
      description: user.description || '',
      email: user.email,
      photoURL: user.photoURL || '/assets/images/user.png',
      createdAt: firebase.firestore.FieldValue.serverTimestamp(),
      role: user.role,
      activated: user.activated,
      isProcessing: false,
      emailVerified: user.emailVerified,
    });
};

/*  */
export const updateUserProfile = async (profile) => {
  const user = firebase.auth().currentUser;
  try {
    if (user.displayName !== profile.displayName) {
      await user.updateProfile({
        displayName: profile.displayName,
      });
    }
    return await db.collection('users').doc(user.uid).update(profile);
  } catch (error) {
    throw error;
  }
};

export const updateUserProfilePhoto = async (downloadURL) => {
  const user = firebase.auth().currentUser;
  const userDocRef = db.collection('users').doc(user.uid);
  try {
    const userDoc = await userDocRef.get();
    if (!userDoc.data().photoURL) {
      await db.collection('users').doc(user.uid).update({
        photoURL: downloadURL,
      });
      await user.updateProfile({
        photoURL: downloadURL,
      });
    }
  } catch (error) {
    throw error;
  }
};

export const setEmailVerified = async (userUid, role) => {
  return await db
    .collection('users')
    .doc(userUid)
    .update({
      emailVerified: true,
      activated: role === 'patient',
    });
};

/* Get user profile from db */
export const getUserProfile = (userId) => {
  return db.collection('users').doc(userId);
};

// Get Active Doctors
export const fetchActiveDoctors = () => {
  return db
    .collection('users')
    .where('role', '==', 'doctor')
    .where('activated', '==', true);
};

/* Get all doctors */
export const fetchDoctorsFromFirestore = (
  predicate,
  limit,
  lastDocSnapshot = null
) => {
  return db
    .collection('users')
    .where('role', '==', 'doctor')
    .where('activated', '==', true);
};

export const fetchDoctorProfile = (doctorId) => {
  return db.collection('users').doc(doctorId);
};

export const saveBookingInFirestore = (booking) => {
  return db.collection('bookings').add({
    ...booking,
    createdAt: firebase.firestore.FieldValue.serverTimestamp(),
  });
};

export const getBookingsCurrentMonth = (doctorId) => {
  let now = new Date();
  const queryDate = new Date(now.getFullYear(), now.getMonth(), 1);
  return db
    .collection('bookings')
    .where('date', '>=', queryDate)
    .where('isPaid', '==', true);
};

export const getLastTenBookings = () => {
  return db
    .collection('bookings')
    .where('isPaid', '==', true)
    .orderBy('date', 'desc')
    .limit(10);
};

export const updateBookingToPaid = (bookingId, liveesId) => {
  return db.collection('bookings').doc(bookingId).update({
    isPaid: true,
    liveesId,
  });
};

export const addFileToBooking = async (bookingId, file) => {
  return await db
    .collection('bookings')
    .doc(bookingId)
    .update({
      files: firebase.firestore.FieldValue.arrayUnion(file),
    });
};

export const getFutureDoctorBookings = (doctorId) => {
  let now = new Date();
  return db
    .collection('bookings')
    .where('date', '>', now)
    .where('doctor.id', '==', doctorId);
};

export const deleteBooking = (bookingId) => {
  return db.collection('bookings').doc(bookingId).delete();
};

export const getMyBookings = () => {
  const user = firebase.auth().currentUser;
  return db
    .collection('bookings')
    .where('isPaid', '==', true)
    .where('patient.id', '==', user.uid);
};

export const getDoctorBookings = () => {
  const user = firebase.auth().currentUser;
  return db
    .collection('bookings')
    .where('isPaid', '==', true)
    .where('doctor.id', '==', user.uid);
};

export const getBookingOnce = (bookingId) => {
  return db.collection('bookings').doc(bookingId);
};

export const getAllDoctors = () => {
  return db
    .collection('users')
    .where('role', '==', 'doctor')
    .orderBy('displayName');
};

export const updateDoctorActiveStatus = (value, userId) => {
  return db.collection('users').doc(userId).update({
    activated: value,
    isProcessing: false,
  });
};

export const saveDoctorSchedule = (scheduleArr) => {
  const user = firebase.auth().currentUser;

  // map schedule to object keys
  const schedule = {};
  scheduleArr.forEach((item, index) => (schedule[index] = item));
  return db.collection('users').doc(user.uid).update({
    schedule,
  });
};

// Admin Functions
export const addCategory = (category) => {
  return db.collection('categories').add(category);
};

export const getCategories = () => {
  return db.collection('categories').orderBy('name');
};

export const deleteCategory = (id) => {
  return db.collection('categories').doc(id).delete();
};

// File Upload
export const saveFileInFirestore = (file) => {
  const user = firebase.auth().currentUser;

  return db
    .collection('users')
    .doc(user.uid)
    .update({
      files: firebase.firestore.FieldValue.arrayUnion(file),
    });
};

export const getUserFiles = (userId) => {
  return db.collection('users').doc(userId);
};

export const deleteUserFile = async (fileId) => {
  const user = firebase.auth().currentUser;

  const userDoc = await db.collection('users').doc(user.uid).get();

  return db
    .collection('users')
    .doc(user.uid)
    .update({
      files: userDoc.data().files.filter((file) => file.id !== fileId),
    });
};

export const setFilePermissions = async (fileId, permissions) => {
  const user = firebase.auth().currentUser;

  const userDoc = await db.collection('users').doc(user.uid).get();

  return db
    .collection('users')
    .doc(user.uid)
    .update({
      files: userDoc.data().files.map((file) => {
        if (file.id === fileId) {
          file.permissions = permissions;
          return file;
        } else {
          return file;
        }
      }),
    });
};

// Consultorio reservation
export const addReservation = async (values) => {
  return db.collection('reservations').add(values);
};

export const getReservationsForSucursal = (sucursalId) => {
  return db.collection('reservations').where('sucursal', '==', sucursalId);
};

export const getReservationsMonth = () => {
  let now = new Date();
  const queryDate = new Date(now.getFullYear(), now.getMonth(), 1);
  return db.collection('bookings').where('date', '>=', queryDate);
};

// Sucursales
export const createSucursal = async (values) => {
  const schedule = {};
  values.schedule.forEach((item, index) => (schedule[index] = item));
  values.schedule = schedule;
  return db.collection('sucursales').add(values);
};

export const getSucursales = () => {
  return db.collection('sucursales');
};

export const getSucursal = (sucursalId) => {
  return db.collection('sucursales').doc(sucursalId);
};

export const updateSucursal = async (sucursal) => {
  // convert schedule array to object because Firestore doesn't allow nested arrays
  const schedule = {};
  sucursal.schedule.forEach((item, index) => (schedule[index] = item));
  sucursal.schedule = schedule;
  return await db.collection('sucursales').doc(sucursal.id).update(sucursal);
};

// Lab Queries
export const getLaboratories = () => {
  return db.collection('users').where('role', '==', 'laboratory');
};
