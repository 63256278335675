import AboutHero from './Sections/Hero/AboutHero';
import WhoWeAre from './Sections/WhoWeAre/WhoWeAre';
import Mision from './Sections/Mision/Mision';
import OurServices from './Sections/OurServices/OurServices';
import Footer from '../../components/layout/Footer/Footer';
import { Container } from '@material-ui/core';

const AboutUsPage = () => {
  return (
    <>
      <AboutHero />
      <WhoWeAre />
      <Mision />
      <OurServices />
      <Container
        maxWidth='md'
        style={{ textAlign: 'center', padding: '5rem 0' }}
      >
        <iframe
          width='100%'
          height='640'
          title='miDoctor Video Promo'
          frameBorder='0'
          allowfullscreen
          src='https://www.youtube.com/embed/1yJAltT1TKE'
        ></iframe>
      </Container>
      <Footer />
    </>
  );
};

export default AboutUsPage;
