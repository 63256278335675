import * as Yup from 'yup';
import sucursalFormModel from './sucursalFormModel';

const {
  formField: {
    name,
    address1,
    city,
    state,
    phone,
    googleLoc,
    numRooms,
    isVisible,
  },
} = sucursalFormModel;

// eslint-disable-next-line import/no-anonymous-default-export
export default Yup.object({
  [name.name]: Yup.string().required(`${name.requiredErrorMsg}`),
  [address1.name]: Yup.string().required(`${address1.requiredErrorMsg}`),
  [phone.name]: Yup.string().required(`${phone.requiredErrorMsg}`),
  [city.name]: Yup.string().required(`${city.requiredErrorMsg}`),
  [state.name]: Yup.string().required(`${state.requiredErrorMsg}`),
  [googleLoc.name]: Yup.string().required(`${googleLoc.requiredErrorMsg}`),
  [numRooms.name]: Yup.string().required(`${numRooms.requiredErrorMsg}`),
  [isVisible.name]: Yup.boolean(),
});
