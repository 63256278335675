import { useField } from 'formik';
import { TextField } from '@material-ui/core';

const MyTextInput = ({ label, ...props }) => {
  const [field, meta] = useField(props);

  return (
    <TextField
      {...field}
      {...props}
      label={label}
      error={meta.touched && Boolean(meta.error)}
      helperText={meta.touched && meta.error}
    />
  );
};
export default MyTextInput;
