import { Typography } from '@material-ui/core';
import useStyles from './styles';

const UserStatus = ({ user }) => {
  const classes = useStyles();

  const isActive =
    user.paymentInfo && user.schedule && Object.keys(user.schedule).length;

  return (
    <div className={classes.root}>
      <Typography component='h2' variant='h6' color='primary' gutterBottom>
        Estado
      </Typography>
      <Typography variant='body2' gutterBottom>
        <span style={{ color: isActive ? 'green' : 'red' }}>
          <strong>{isActive ? 'Activo' : 'Inactivo'}</strong>
        </span>
      </Typography>
      {isActive ? (
        <Typography variant='subtitle2' gutterBottom>
          Su perfil se encuentra activo. Los pacientes pueden crear una consulta
          con usted.
        </Typography>
      ) : (
        <Typography variant='subtitle2' gutterBottom>
          Su perfil se encuentra inactivo. Los pacientes no pueden crear una
          consulta con usted.
        </Typography>
      )}
    </div>
  );
};

export default UserStatus;
