import { IconButton, Typography } from '@material-ui/core';
import CustomModal from '../../components/layout/CustomModal/CustomModal';
import useStyles from './styles';
import NoteIcon from '@material-ui/icons/Note';

const AdminUserModal = ({ open, setOpen, doctor }) => {
  const classes = useStyles();

  return (
    <CustomModal
      title='Detalles'
      open={open}
      setOpen={setOpen}
      cancelText='Cerrar'
    >
      <div className={classes.modalRoot}>
        {doctor && (
          <div className={classes.textContent}>
            <Typography variant='h6' color='primary' gutterBottom>
              Datos Personales
            </Typography>
            <Typography variant='body2'>
              <strong>Usuario</strong>: {doctor.displayName}
            </Typography>
            <Typography variant='body2'>
              <strong>Nombre</strong>: {doctor.name}
            </Typography>
            <Typography variant='body2'>
              <strong>Apellido</strong>: {doctor.lastName}
            </Typography>
            <Typography variant='body2'>
              <strong>Dirección1</strong>: {doctor.address1}
            </Typography>
            <Typography variant='body2'>
              <strong>Dirección2</strong>: {doctor.address2}
            </Typography>
            <Typography variant='body2'>
              <strong>Ciudad</strong>: {doctor.city}
            </Typography>
            <Typography variant='body2'>
              <strong>Departamento</strong>: {doctor.state}
            </Typography>
            <Typography variant='body2'>
              <strong>Teléfono</strong>: {doctor.phone}
            </Typography>
            <Typography variant='body2'>
              <strong>Email</strong>: {doctor.email}
            </Typography>
            <Typography variant='body2'>
              <strong>Razón Social</strong>: {doctor.billingName}
            </Typography>
            <Typography variant='body2'>
              <strong>NIT</strong>: {doctor.billingId}
            </Typography>
            <Typography
              style={{ marginTop: '1rem' }}
              variant='h6'
              color='primary'
              gutterBottom
            >
              Datos Profesionales
            </Typography>
            <Typography variant='body2'>
              <strong>Especialización Primaria</strong>:{' '}
              {doctor.specialtyPrimary}
            </Typography>
            <Typography variant='body2'>
              <strong>Especialización Secundaria</strong>:{' '}
              {doctor.specialtySecondary || 'Ninguna'}
            </Typography>
            <Typography variant='body2'>
              <strong>Nro. Matrícula</strong>: {doctor.medicalLicenseId}
            </Typography>
            <Typography variant='body2'>
              <strong>Universidad</strong>: {doctor.university}
            </Typography>
            <div className={classes.doctorFile}>
              <Typography variant='body2'>
                <strong>Titulación Médica</strong>:
              </Typography>
              <IconButton
                size='small'
                component='a'
                aria-label='download'
                href={doctor.academicRecords}
                target='_blank'
                rel='noreferrer'
                download
                color='primary'
              >
                <NoteIcon />
              </IconButton>
            </div>
            <div className={classes.doctorFile}>
              <Typography variant='body2'>
                <strong>Titulación Especialización</strong>:
              </Typography>
              <IconButton
                size='small'
                size='small'
                component='a'
                aria-label='download'
                href={doctor.specializationRecords}
                target='_blank'
                rel='noreferrer'
                download
                color='primary'
              >
                <NoteIcon />
              </IconButton>
            </div>
            <Typography
              style={{ marginTop: '1rem' }}
              variant='h6'
              color='primary'
              gutterBottom
            >
              Cuenta de Banco
            </Typography>
            {!doctor.paymentInfo ? (
              <Typography variant='body2'>
                El usuario no ha ingresado sus datos bancarios
              </Typography>
            ) : (
              <>
                <Typography variant='body2'>
                  <strong>Titular</strong>: {doctor.paymentInfo.bankOwner}
                </Typography>
                <Typography variant='body2'>
                  <strong>CI / NIT</strong>: {doctor.paymentInfo.bankOwnerId}
                </Typography>
                <Typography variant='body2'>
                  <strong>Nro. Cuenta</strong>: {doctor.paymentInfo.bankAccount}
                </Typography>
                <Typography variant='body2'>
                  <strong>Banco</strong>: {doctor.paymentInfo.bankName}
                </Typography>
                <Typography variant='body2'>
                  <strong>Moneda</strong>: {doctor.paymentInfo.currency}
                </Typography>
              </>
            )}
          </div>
        )}
      </div>
    </CustomModal>
  );
};

export default AdminUserModal;
