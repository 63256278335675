import {
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
} from '@material-ui/core';
import { useField } from 'formik';

const MySelectInput = ({
  label,
  data,
  nullField,
  fullWidth = true,
  ...props
}) => {
  const [field, meta] = useField(props);

  return (
    <FormControl
      fullWidth={fullWidth}
      error={meta.touched && !!meta.error}
      {...props}
      style={{ textAlign: 'left' }}
    >
      <InputLabel>{label}</InputLabel>
      <Select {...field} value={field.value || ''}>
        {nullField && (
          <MenuItem key='nullField' value=''>
            {nullField}
          </MenuItem>
        )}
        {data.map((item, index) => (
          <MenuItem key={index} value={item.value}>
            {item.label}
          </MenuItem>
        ))}
      </Select>
      {meta.touched && meta.error && (
        <FormHelperText color='red'>{meta.error}</FormHelperText>
      )}
    </FormControl>
  );
};

export default MySelectInput;
