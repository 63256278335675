import { useEffect, useState } from 'react';
import {
  Card,
  CardContent,
  CardMedia,
  Container,
  Grid,
  Paper,
  Typography,
} from '@material-ui/core';
import useStyles from './styles';
import docImg from '../../../assets/images/register-doc.jpg';
import patientImg from '../../../assets/images/register-patient.jpg';
import Footer from '../../../components/layout/Footer/Footer';
import AccountRegisterForm from './AccountRegisterForm/AccountRegisterForm';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { ReactComponent as FlaskIcon } from '../../../assets/icons/flask2.svg';

const RegisterPage = ({ history }) => {
  const [role, setRole] = useState(null);
  const classes = useStyles();
  const { authenticated } = useSelector((state) => state.auth);

  useEffect(() => {
    if (authenticated) {
      history.push('/resolve-auth');
    }
  }, [authenticated, history]);

  return (
    <div className={classes.root}>
      <Container className={classes.mainContent}>
        <Typography
          align='center'
          color='primary'
          variant='h4'
          component='h1'
          className={classes.title}
        >
          Crear cuenta{' '}
          {role && `como ${role === 'patient' ? 'paciente' : role}`}
        </Typography>
        {!role ? (
          <>
            <Grid container spacing={4} justify='center'>
              <Grid item xs={12} sm={6} md={4}>
                <Card
                  className={classes.card}
                  onClick={() => setRole('doctor')}
                >
                  <CardMedia
                    image={docImg}
                    title='Image title'
                    className={classes.media}
                  />
                  <CardContent>
                    <Typography
                      color='primary'
                      variant='h6'
                      component='h2'
                      align='center'
                    >
                      Soy Doctor
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <Card
                  className={classes.card}
                  onClick={() => setRole('patient')}
                >
                  <CardMedia
                    image={patientImg}
                    title='Image title'
                    className={classes.media}
                  />
                  <CardContent>
                    <Typography
                      color='primary'
                      variant='h6'
                      component='h2'
                      align='center'
                    >
                      Soy Paciente
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
            <Grid container spacing={4} justify='center'>
              <Grid item sm={12} md={8}>
                <Paper>
                  <Typography
                    variant='body1'
                    to='/lab-register'
                    color='primary'
                    align='center'
                    className={classes.labRegisterText}
                  >
                    <FlaskIcon className='flask-icon' /> <br />
                    Cree una cuenta para su laboratorio o estudios
                    complementarios.{' '}
                    <Link to='/lab-register'>Regístrese aquí.</Link>
                  </Typography>
                </Paper>
              </Grid>
            </Grid>
          </>
        ) : (
          <AccountRegisterForm role={role} setRole={setRole} />
        )}
      </Container>
      <Footer />
    </div>
  );
};

export default RegisterPage;
