import { Grid, Paper } from '@material-ui/core';
import clsx from 'clsx';
import { useState } from 'react';
import {
  getAllDoctors,
  getBookingsCurrentMonth,
  getLaboratories,
  getReservationsMonth,
} from '../../services/firestore';
import AdminLastBookings from './home/AdminLastBookings';
import AdminStatus from './home/AdminStatus';
import useFirestoreCollection from '../../hooks/useFirestoreCollection';
import useStyles from './styles';

const AdminHome = () => {
  const classes = useStyles();
  const [doctors, setDoctors] = useState([]);
  const [inactive, setInactive] = useState([]);
  const [bookingsMonth, setBookingsMonth] = useState([]);
  const [reservations, setReservations] = useState([]);
  const [labs, setLabs] = useState([]);
  const [labsInactive, setLabsInactive] = useState([]);

  useFirestoreCollection({
    query: () => getLaboratories(),
    data: (data) => {
      setLabs(data.filter((d) => d.activated));
      setLabsInactive(data.filter((d) => !d.activated));
    },
    deps: [],
  });

  useFirestoreCollection({
    query: () => getAllDoctors(),
    data: (data) => {
      setDoctors(data.filter((d) => d.activated));
      setInactive(data.filter((d) => !d.activated && d.medicalLicenseId));
    },
    deps: [],
  });

  useFirestoreCollection({
    query: () => getBookingsCurrentMonth(),
    data: (data) => setBookingsMonth(data),
    deps: [],
  });

  useFirestoreCollection({
    query: () => getReservationsMonth(),
    data: (data) => setReservations(data),
    deps: [],
  });

  return (
    <>
      <Grid container spacing={3}>
        <Grid item xs={12} md={4}>
          <Paper className={clsx(classes.minHeight, classes.paper)}>
            <AdminStatus
              label='Doctores'
              text={doctors.length}
              subText='Activos'
              text2={inactive.length}
              subText2='Sin Activar'
              link='/admin-dashboard/users'
            />
          </Paper>
        </Grid>
        <Grid item xs={12} md={4}>
          <Paper className={clsx(classes.minHeight, classes.paper)}>
            <AdminStatus
              label='Laboratorios'
              text={labs.length}
              subText='Activos'
              link='/admin-dashboard/laboratorios'
              subText2='Sin Activar'
              text2={labsInactive.length}
            />
          </Paper>
        </Grid>
        <Grid item xs={12} md={4}>
          <Paper className={clsx(classes.minHeight, classes.paper)}>
            <AdminStatus
              label='Teleconsultas (mes)'
              text={bookingsMonth.length}
            />
          </Paper>
        </Grid>

        {/* <Grid item xs={12} md={4} lg={3}>
          <Paper className={clsx(classes.minHeight, classes.paper)}>
            <AdminStatus label='Reservas (mes)' text={reservations.length} />
          </Paper>
        </Grid> */}
        <Grid item xs={12}>
          <Paper className={clsx(classes.minHeight, classes.paper)}>
            <AdminLastBookings />
          </Paper>
        </Grid>
      </Grid>
    </>
  );
};

export default AdminHome;
