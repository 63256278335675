import { Container, Grid, Link, Typography } from '@material-ui/core';
import useStyles from './styles';
import * as Yup from 'yup';
import { Link as RouterLink, useHistory } from 'react-router-dom';

import Footer from '../../../components/layout/Footer/Footer';
import MyButton from '../../../components/shared/form/MyButton';
import { Formik, Form } from 'formik';
import MyTextInput from '../../../components/shared/form/MyTextInput';
import SocialLogin from '../../../components/shared/SociaLogin/SocialLogin';
import MyDivider from '../../../components/shared/form/MyDivider';
import { signInWithEmail } from '../../../services/firebaseAuth';
import { toast } from 'react-toastify';
import { displayFirebaseAuthError } from '../../../util';
import { useSelector } from 'react-redux';
import { useEffect } from 'react';

const _validationSchema = Yup.object({
  email: Yup.string()
    .required('El email es un campo requerido')
    .email('El email debe ser válido'),
  password: Yup.string().required(),
});

const LoginPage = () => {
  const classes = useStyles();
  const history = useHistory();
  const { authenticated } = useSelector((state) => state.auth);

  useEffect(() => {
    if (authenticated) {
      history.push('/resolve-auth');
    }
  }, [authenticated, history]);

  const handleSubmit = async (values, { setSubmitting }) => {
    try {
      await signInWithEmail(values);
      setSubmitting(false);
    } catch (error) {
      console.log(error);
      toast.error(displayFirebaseAuthError(error.code) || error.message);
    }
  };

  return (
    <>
      <Container maxWidth='xs' className={classes.root}>
        <Typography
          align='center'
          color='primary'
          variant='h4'
          component='h1'
          className={classes.title}
        >
          Ingresa a tu cuenta
        </Typography>
        <SocialLogin />
        <MyDivider className={classes.divider}>ó</MyDivider>
        <Formik
          initialValues={{ email: '', password: '' }}
          validationSchema={_validationSchema}
          onSubmit={handleSubmit}
        >
          {({ isSubmitting, isValid, dirty }) => (
            <Form>
              <MyTextInput
                variant='outlined'
                fullWidth
                margin='normal'
                label='Email'
                name='email'
              />
              <MyTextInput
                variant='outlined'
                fullWidth
                margin='normal'
                label='Contraseña'
                name='password'
                type='password'
              />
              <MyButton
                label='Entrar'
                type='submit'
                fullWidth
                variant='contained'
                color='primary'
                className={classes.submit}
                disabled={!isValid || !dirty || isSubmitting}
                loading={isSubmitting}
              />
              <Grid container>
                {/*               <Grid item xs>
                <Link
                  component={RouterLink}
                  to='/forgot-password'
                  variant='body2'
                >
                  Olvide mi contraseña
                </Link>
              </Grid> */}
                <Grid item>
                  <Link component={RouterLink} to='/register' variant='body2'>
                    {'¿No tienes una cuenta? Regístrate.'}
                  </Link>
                </Grid>
              </Grid>
            </Form>
          )}
        </Formik>
      </Container>
      <Footer />
    </>
  );
};

export default LoginPage;
