import { Button, makeStyles, TextField, Typography } from '@material-ui/core';
import { useEffect, useState } from 'react';
import logo from '../../assets/images/logo.svg';
import { setWithExpiry } from '../../util/index.js';

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100vh',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  mainText: {
    color: '#2F6189',
    marginTop: '3rem',
  },
  secondaryText: {
    color: '#999',
    fontWeight: 300,
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  form: {
    marginTop: '2rem',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  submitBtn: {
    marginTop: '0.5rem',
  },
}));

const MaintenancePage = ({ setIsAuth }) => {
  const classes = useStyles();
  const [password, setPassword] = useState('');

  useEffect(() => {}, []);

  const handleSubmit = () => {
    if (password === 'Midoctor01') {
      setWithExpiry('isAuth', true);
      setIsAuth(true);
    }
  };

  return (
    <div className={classes.root}>
      <div className={classes.content}>
        <img src={logo} alt='logo' width='350px' />
        <Typography variant='h3' className={classes.mainText}>
          En construcción
        </Typography>
        <Typography variant='body1' className={classes.secondaryText}>
          Próximamente estaremos contigo ofreciendote los mejores servicios en
          telemedicina.
        </Typography>
        <form onSubmit={handleSubmit} className={classes.form}>
          <TextField
            id='outlined-basic'
            label='Contraseña'
            variant='outlined'
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          <Button
            fullWidth
            color='primary'
            size='large'
            type='submit'
            variant='contained'
            className={classes.submitBtn}
          >
            Entrar
          </Button>
        </form>
      </div>
    </div>
  );
};

export default MaintenancePage;
