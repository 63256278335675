import { Container } from '@material-ui/core';
import Footer from '../../components/layout/Footer/Footer';

const TermsOfUse = () => {
  return (
    <>
      <Container maxWidth='lg' style={{ margin: '3rem auto' }}>
        <h1>
          TÉRMINOS Y CONDICIONES PARA EL ACCESO Y AFILIACIÓN A LA PLATAFORMA
          DIGITAL “miDoctor”
        </h1>
        <h2>
          SECCIÓN I <br />
          PARA ESPECIALISTAS MÉDICOS
        </h2>

        <h4>ANTECEDENTES</h4>

        <p>
          I. La presente página web o plataforma digital pertenece a la empresa
          ABMEDICAL SRL, con matrícula de comercio 435324, con NIT 394609024,
          con domicilio en la calle Puerto Rico No 74 en la ciudad de Santa Cruz
          de la Sierra – Bolivia, que en adelante y a los fines de este acuerdo
          se denominará la “EMPRESA”.
        </p>

        <p>
          II. El médico o doctor que utilizará los servicios de la presente
          página web será denominado como el “CLIENTE”.
        </p>

        <p>
          III. El CLIENTE es un profesional médico que requiere un servicio de
          captación de usuarios pacientes vía virtual o a través de una
          plataforma online, por ello requiere de una empresa que le provecha
          dicha prestación.
        </p>

        <p>
          IV. La EMPRESA tiene interés de proveer el servicio de captación de
          usuarios o pacientes a través de una plataforma online o virtual, a
          favor del CLIENTE.
        </p>

        <h4>OBJETO, DURACION Y RENOVACION DEL SERVICIO</h4>

        <p>
          I. En virtud del artículo 732 y siguientes del Código Civil de
          Bolivia, mediante el presente servicio la EMPRESA se obliga a prestar
          servicios de captación de usuarios o pacientes a favor del CLIENTE,
          realizándolo vía virtual o a través de una plataforma online, a cambio
          de la retribución convenida, por lo que se ejecutarán las siguientes
          prestaciones:
        </p>

        <ol start='1'>
          <li>
            <p>
              Servicio de captación de usuarios o pacientes a favor del CLIENTE,
              a través de una plataforma online o por vía virtual.
            </p>
          </li>
        </ol>

        <ol start='2'>
          <li>
            <p>
              Para la captación y atención de los pacientes, la EMPRESA
              permitirá al CLIENTE el acceso a su plataforma online o virtual,
              para las consultas con los respectivos pacientes finales.
            </p>
          </li>
        </ol>

        <p>
          II. Las partes deberán acordar la retribución específica para aquellos
          servicios que NO se encuentren mencionados en el anterior numeral.
        </p>

        <p>
          III. Es un acuerdo indefinido que podrá terminar cuando el médico lo
          desee o se podrá retirar al médico o cliente cuando recurrentemente no
          cumple con las reglas de esta plataforma digital.
        </p>

        <p>
          IV. Cualquier pago o retribución pendiente se le otorgara en las
          fechas de pagos estipuladas.
        </p>

        <p>
          V. El presente acuerdo de servicio NO es laboral, toda vez que ambas
          partes no tienen relación de dependencia, subordinación, remuneración,
          continuidad y trabajo por cuenta ajena, asimismo no hay horario
          laboral, ni exclusividad.
        </p>

        <h4>RETRIBUCIÓN</h4>

        <p>
          I. Las partes acuerdan que la retribución que debe pagar el CLIENTE a
          favor de la EMPRESA es del 20% del valor de la consulta captada a
          través de la plataforma online o digital, que incluye los impuestos de
          ley, por tanto, la EMPRESA dará una factura por el servicio prestado.
        </p>

        <p>II. El valor de la consulta lo establecerá el CLIENTE.</p>

        <p>
          III. La citada retribución se aplicará por cualquier paciente que el
          CLIENTE atienda a través de la plataforma online o digital de la
          EMPRESA.
        </p>

        <p>IV. LA EMPRESA cobrará la retribución de la siguiente manera:</p>

        <ol start='1'>
          <li>
            <p>
              La EMPRESA realizará el cobro del servicio médico online al
              paciente.
            </p>
          </li>
        </ol>

        <ol start='2'>
          <li>
            <p>
              El CLIENTE colocará el monto a cobrar, mínimo de Bs. 150 por
              consulta de médico especialista.
            </p>
          </li>
        </ol>

        <ol start='3'>
          <li>
            <p>
              Posteriormente la EMPRESA realizará la transferencia respectiva a
              favor del CLIENTE, a la cuenta bancaria que especifique en los
              datos que se le solicitan, cada quincena del mes o al siguiente
              día hábil, la suma de las consultas acumuladas hasta el día
              anterior de la fecha de pago, realizando el respectivo descuento
              que cubrirá el costo de retribución anteriormente mencionado.
            </p>
          </li>
        </ol>

        <ol start='4'>
          <li>
            <p>
              La EMPRESA extenderá una factura a favor del CLIENTE por el
              porcentaje de retribución cobrado.
            </p>
          </li>
        </ol>

        <p>
          IV. Las partes acuerdan, que la EMPRESA a través de sus dependientes y
          personal de apoyo, contabilizarán los pacientes atendidos por el
          CLIENTE.
        </p>

        <h4>RESCISIÓN DEL ACUERDO DE SERVICIO</h4>

        <p>
          Las partes podrán rescindir el presente acuerdo, en base a las reglas
          previstas por el artículo 746 del código civil.
        </p>

        <h4>OBLIGACIONES DE LA EMPRESA</h4>

        <p>
          I. La EMPRESA se obliga y compromete a brindar sus servicios y
          asistencia con eficiencia, espero, equidad, honradez, responsabilidad,
          capacidad, decoro, con estricta sujeción a las normas jurídicas y
          morales, observando en todo momento los principios legales contenidos
          en la normativa nacional.
        </p>

        <p>
          II. La EMPRESA otorgará asesoramiento, capacitación y soporte técnico
          en el manejo de la plataforma online o virtual.
        </p>

        <h4>OBLIGACIONES DEL CLIENTE</h4>

        <p>
          I. El CLIENTE, se compromete y obliga a pagar el total de la
          retribución convenida, actuando con absoluta sinceridad con la
          EMPRESA, para permitirle un correcto servicio, mantenimiento en todo
          momento un trato cordial, respetuoso y de confianza mutua.
        </p>

        <p>
          II. El CLIENTE se compromete a facilitar la documentación legal y
          contable que estime necesario la EMPRESA.
        </p>

        <p>
          III. La EMPRESA no tendrá ningún tipo de responsabilidad ante la
          falsedad de información y/o documentación proporcionada por el
          CLIENTE, para el cumplimiento de los servicios acordados en el
          presente acuerdo.
        </p>

        <p>
          IV. El CLIENTE se compromete a emitir la respectiva factura fiscal a
          favor de sus clientes o pacientes finales atendidos a través de la
          plataforma online o virtual de la EMPRESA.
        </p>

        <p>
          V. El CLIENTE se compromete a cumplir con sus obligaciones tributarias
          emergentes de los servicios médicos que presta a sus pacientes.
        </p>

        <p>
          VI. EL CLIENTE se compromete a cumplir con sus obligaciones
          municipales, socio laborales y de cualquier índole que se vinculen con
          los servicios médicos prestados a favor de sus pacientes.
        </p>

        <p>
          VII. El CLIENTE se compromete a cumplir con su responsabilidad civil
          por cualquier daño causado a sus pacientes atendidos a través de la
          plataforma online o virtual de la EMPRESA, liberando y dejando indemne
          a la EMPRESA en caso de demandas judiciales vinculadas a los servicios
          prestados por el CLIENTE en favor de sus pacientes.
        </p>

        <h4>CONFIDENCIALIDAD</h4>

        <p>
          I. La EMPRESA y el CLIENTE están obligados a guardar en la más
          absoluta reserva y confidencialidad todos los datos e informaciones
          que llegara a conocer durante la prestación del servicio, sin
          limitarse a notas, informes, análisis, compilaciones, traducciones,
          estudios o cualquier otro tipo de documento o hecho que obtengan y/o
          lleguen a conocer en sus dependencias durante la prestación del
          servicio, aunque esta documentación no constituya información
          directamente relacionada con la ejecución de las prestaciones
          convenidas.
        </p>

        <p>
          II. La EMPRESA y el CLIENTE son responsables de asegurar que las
          personas que reciban la información precedentemente especificada
          guarden la respectiva confidencialidad, de acuerdo a estos mismos
          términos y condiciones.
        </p>

        <p>
          III. Las obligaciones de confidencialidad de la EMPRESA y el CLIENTE
          subsistirán una vez finalizado el servicio.
        </p>

        <p>
          IV. Cualquier divulgación sobre la información confidencial debe
          contar con la previa autorización del CLIENTE y la EMPRESA, y en su
          caso, a través de resolución judicial emanada por autoridad
          competente.
        </p>

        <h4>ACEPTACIÓN</h4>

        <p>
          Al hacer clic o presionar en el botón de aceptar, el CLIENTE declara
          que ha leído, entendido y aceptado los términos de uso.
        </p>

        <h2>
          SECCIÓN II <br />
          PARA PACIENTES
        </h2>

        <p>
          I. La presente página web o plataforma digital pertenece a la empresa
          ABMEDICAL SRL, con matrícula de comercio 435324, con NIT 394609024,
          con domicilio en la calle Puerto Rico No 74 en la ciudad de Santa Cruz
          de la Sierra – Bolivia, que en adelante y a los fines de este acuerdo
          se denominará la “EMPRESA”.
        </p>

        <p>
          II. El usuario que utilizará los servicios de la presente página web
          será denominado como el “PACIENTE”
        </p>

        <p>
          III. El PACIENTE es una persona natural que requiere servicio de
          consulta médica vía online en una plataforma virtual.
        </p>

        <p>
          IV. La EMPRESA tiene la presente página web que presta el servicio de
          captación o búsqueda de un médico o doctor que pueda proveer atención
          de consulta vía online, a favor del PACIENTE.
        </p>

        <h4>OBJETO Y DURACION DEL SERVICIO</h4>

        <p>
          I. En virtud del artículo 732 y siguientes del Código Civil de
          Bolivia, mediante el presente texto de condiciones de uso la EMPRESA a
          través de su plataforma virtual se obliga a prestar servicio de
          captación o búsqueda de un profesional médico especializado que provea
          atención de consulta vía online a favor del PACIENTE, a cambio de la
          retribución convenida, por lo que se ejecutarán las siguientes
          prestaciones:
        </p>

        <ol start='1'>
          <li>
            <p>
              Servicio de búsqueda y captación de un profesional médico
              especializado que provea atención de consulta a favor del
              PACIENTE, a través de una plataforma online o por vía virtual.
            </p>
          </li>
        </ol>

        <ol start='2'>
          <li>
            <p>
              Para la atención de consulta que proveerá el médico especializado,
              la EMPRESA permitirá al PACIENTE el acceso a su plataforma online
              o virtual.
            </p>
          </li>
        </ol>

        <ol start='3'>
          <li>
            <p>
              La EMPRESA solo es un intermediario entre el profesional médico
              especializado y el PACIENTE.
            </p>
          </li>
        </ol>

        <ol start='4'>
          <li>
            <p>
              El servicio de atención de consulta es de absoluta responsabilidad
              del profesional médico especialista.
            </p>
          </li>
        </ol>

        <ol start='5'>
          <li>
            <p>
              Las partes acuerdan y convienen que el PACIENTE solo podrá
              demandar en la vía civil, administrativa, penal y de toda índole,
              al profesional médico especialista que lo atendió en las consultas
              liberando y dejando indemne a la EMPRESA de cualquier
              resarcimiento de daños y perjuicios.
            </p>
          </li>
        </ol>

        <ol start='6'>
          <li>
            <p>
              Las partes acuerdan, convienen y reconocen que la EMPRESA no tiene
              relación laboral con el profesional médico especialista que
              prestará el servicio de atención de consultas a través de la
              plataforma online de la EMPRESA.
            </p>
          </li>
        </ol>

        <ol start='7'>
          <li>
            <p>
              La EMPRESA solo presta el servicio de búsqueda de un profesional
              médico especialista, permitiendo al médico y al PACIENTE que se
              comuniquen a través de su plataforma online o virtual, la duración
              de la consulta será de una hora una vez se comience con la cesión.
            </p>
          </li>
        </ol>

        <ol start='8'>
          <li>
            <p>
              El servicio de atención de consulta solo lo realiza el médico
              profesional especialista, NO la EMPRESA, cuya tarea es reunir al
              médico especialista con el PACIENTE.
            </p>
          </li>
        </ol>

        <p>
          II. Las partes deberán acordar la retribución específica para aquellos
          servicios que NO se encuentren mencionados en el numeral I de la
          presente cláusula.
        </p>

        <p>
          III. El servicio concluirá con el cese del uso de la plataforma online
          o virtual de la EMPRESA, por parte del PACIENTE.
        </p>

        <p>
          V. El presente servicio NO es laboral, toda vez que ambas partes no
          tienen relación de dependencia, subordinación, remuneración,
          continuidad y trabajo por cuenta ajena, asimismo no hay horario
          laboral, ni exclusividad.
        </p>

        <p>
          VI. El profesional médico especializado que prestará el servicio de
          consulta favor del PACIENTE, emitirá la factura fiscal respectiva a
          favor del PACIENTE.
        </p>

        <h4>RETRIBUCIÓN</h4>

        <p>
          Las partes acuerdan que la retribución emergente por el uso de la
          plataforma online o virtual de la EMPRESA y la consulta médica, será
          pagado por el PACIENTE, quien realizará el pago a través de la
          plataforma de pago Livees incorporada en la página web en su calidad
          de intermediario con el MÉDICO. El MÉDICO coloca el precio que será
          retribuido por el PACIENTE
        </p>

        <h4>RESCISIÓN DEL ACUERDO DE SERVICIO</h4>

        <p>
          Las partes podrán rescindir el presente acuerdo, en base a las reglas
          previstas por el artículo 746 del código civil.
        </p>

        <h4>OBLIGACIONES DE LA EMPRESA</h4>

        <p>
          I. La EMPRESA se obliga y compromete a brindar sus servicios y
          asistencia con eficiencia, espero, equidad, honradez, responsabilidad,
          capacidad, decoro, con estricta sujeción a las normas jurídicas y
          morales, observando en todo momento los principios legales contenidos
          en la normativa nacional.
        </p>

        <p>
          II. La EMPRESA otorgará asesoramiento, capacitación y soporte técnico
          en el manejo de la plataforma online o virtual.
        </p>

        <h4>OBLIGACIONES DEL PACIENTE</h4>

        <p>
          El PACIENTE se compromete a reclamar o demandar al médico especialista
          que le prestó el servicio de atención de consulta, por cualquier daño
          y perjuicio ocasionado durante la atención médica online, dejando
          indemne a la EMPRESA de cualquier responsabilidad civil de
          resarcimiento de daños y perjuicios.
        </p>

        <h4>ACEPTACIÓN</h4>

        <p>
          Al hacer click o presionar en el botón de aceptar, el PACIENTE declara
          que ha leído, entendido y aceptado los términos de privacidad y
          confidencialidad.
        </p>
      </Container>
      <Footer />
    </>
  );
};

export default TermsOfUse;
