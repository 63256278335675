import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  root: {
    padding: '3rem 0',
  },
  textContent: {
    fontSize: '1.3rem',
    padding: '1rem',
    fontWeight: 300,
    [theme.breakpoints.up('md')]: {
      fontSize: '1.3rem',
    },
  },
  sectionTitle: {
    marginTop: '1rem',
    fontWeight: 600,
    fontSize: '2.5rem',
    paddingLeft: '1.5rem',
    fontStyle: 'italic',
    [theme.breakpoints.up('md')]: {
      paddingRight: '1.5rem',
      paddingLeft: '0.5rem',
    },
  },
  testimonialText: {
    display: 'flex',
    flexDirection: 'column',
    fontSize: '1.3rem',
    padding: '1rem',
    fontWeight: 300,
    '& > p': {
      fontSize: '1.3rem',
      fontWeight: 300,
      paddingTop: '0.5rem',
    },
  },
  img: {
    width: '100%',
  },
  quotes: {
    marginTop: '1rem',
    width: '3rem',
  },
  quotesFlipped: {
    transform: 'scale(-1)',
    alignSelf: 'flex-end',
    marginTop: 0,
    marginRight: '2rem',
  },
  secondRow: {
    marginTop: '1.5rem',
  },
  secondRowTextContent: {
    [theme.breakpoints.up('md')]: {
      textAlign: 'right',
    },
  },
}));
