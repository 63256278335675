import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    minWidth: 350,
    width: '100%',
  },
  textHeading: {
    marginTop: theme.spacing(2),
  },
  textContent: {
    marginBottom: theme.spacing(2),
    '& > p': {
      fontSize: 15,
    },
  },
  table: {
    '& *': {
      fontSize: 15,
    },
  },
  tableHead: {
    '& *': {
      fontWeight: 600,
    },
  },
  tableBody: {
    '& td': {
      padding: theme.spacing(0, 2),
    },
  },
  downloadIcon: {
    fontSize: 24,
  },
  fileBtn: {
    width: 200,
    textAlign: 'center',
    margin: '0 auto',
    marginTop: theme.spacing(2),
  },
}));

export default useStyles;
