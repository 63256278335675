import { makeStyles } from '@material-ui/core';

export default makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  formInput: {
    marginTop: '0.8rem',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  socialBtn: {
    color: 'white',
    textTransform: 'none',
    marginBottom: theme.spacing(1),
  },
  socialBtnFacebook: {
    backgroundColor: '#4267B2',
    '&:hover': {
      backgroundColor: '#375695',
    },
  },
  socialBtnGoogle: {
    backgroundColor: '#de5246 ',
    '& img': {
      marginRight: '0.5rem',
    },
    '&:hover': {
      backgroundColor: '#d93526',
    },
  },
  divider: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
}));
