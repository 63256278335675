import {
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import moment from 'moment';
import { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import useFirestoreCollection from '../../../hooks/useFirestoreCollection';
import useFirestoreDoc from '../../../hooks/useFirestoreDoc';
import { getUserProfile } from '../../../services/firestore';
import useStyles from './styles';

const LabLastFiles = () => {
  const classes = useStyles();

  const [userFiles, setUserFiles] = useState([]);

  const { currentUser } = useSelector((state) => state.auth);

  useFirestoreDoc({
    query: () => getUserProfile(currentUser.id),
    data: (user) => {
      if (user.files) {
        setUserFiles(user.files);
      }
    },
    deps: [currentUser.id],
  });

  return (
    <>
      <Typography component='h2' variant='h6' color='primary' gutterBottom>
        Últimos Archivos Compartidos
      </Typography>
      <Table size='small'>
        <TableHead className={classes.thead}>
          <TableRow>
            <TableCell>Nombre</TableCell>
            <TableCell>Doctores Compartidos</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {userFiles.map((file, i) =>
            i < 5 ? (
              <TableRow key={file.id}>
                <TableCell scope='row'>{file.name}</TableCell>
                <TableCell scope='row'>{file.permissions.length}</TableCell>
              </TableRow>
            ) : null
          )}
        </TableBody>
      </Table>

      {!userFiles.length && (
        <Typography align='center' style={{ padding: '5rem' }}>
          No tienes archivos
        </Typography>
      )}
    </>
  );
};

export default LabLastFiles;
