import sucursalFormModel from './sucursalFormModel';
const {
  formField: {
    name,
    address1,
    city,
    state,
    phone,
    googleLoc,
    numRooms,
    isVisible,
  },
} = sucursalFormModel;

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  [name.name]: '',
  [address1.name]: '',
  [phone.name]: '',
  [city.name]: '',
  [state.name]: '',
  [googleLoc.name]: '',
  [numRooms.name]: '',
  [isVisible.name]: true,
};
