import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  root: {
    padding: '4rem',
    '& h2': {
      fontWeight: 600,
    },
  },
  title: {
    fontSize: '3rem',
    [theme.breakpoints.up('md')]: {
      fontSize: '4rem',
    },
  },
  gridItem: {
    padding: '1rem',
    textAlign: 'center',
    [theme.breakpoints.up('md')]: {
      padding: '2rem',
    },
    '& p': {
      fontSize: '2rem',
      [theme.breakpoints.up('md')]: {
        fontSize: '2.3rem',
      },
      lineHeight: '1',
    },
  },
  img: {
    height: '160px',
    width: '160px',
  },
}));
