import { Grid, Typography } from '@material-ui/core';
import MySelectInput from '../../../components/shared/form/MySelectInput';
import MyTextInput from '../../../components/shared/form/MyTextInput';

const PatientReservationForm = (props) => {
  const {
    formField: { name, lastName, phone, email },
  } = props;
  return (
    <>
      <Typography variant='h6' color='primary' align='left' gutterBottom>
        Datos del Paciente
      </Typography>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={6}>
          <MyTextInput name={name.name} label={name.label} fullWidth />
        </Grid>
        <Grid item xs={12} sm={6}>
          <MyTextInput name={lastName.name} label={lastName.label} fullWidth />
        </Grid>
        <Grid item xs={12} sm={6}>
          <MyTextInput name={email.name} label={email.label} fullWidth />
        </Grid>
        <Grid item xs={12} sm={6}>
          <MyTextInput name={phone.name} label={phone.label} fullWidth />
        </Grid>
      </Grid>
    </>
  );
};

export default PatientReservationForm;
