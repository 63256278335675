import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/storage';
import 'firebase/functions';
//import 'firebase/analytics';

const firebaseConfig = {
  //apiKey: process.env.REACT_APP_API_KEY,
  apiKey: 'AIzaSyCPhmSu_9aNoeG7MxYpFcg2CN-jAAJzFnw',
  authDomain: 'midoctor-5810e.firebaseapp.com',
  projectId: 'midoctor-5810e',
  storageBucket: 'midoctor-5810e.appspot.com',
  messagingSenderId: '1046197866909',
  appId: '1:1046197866909:web:4aaf7231dea998dbdc1f74',
  measurementId: 'G-DVNEBZWD4P',
};

if (!firebase.apps.length) {
  // Initialize Firebase
  firebase.initializeApp(firebaseConfig);
  //firebase.analytics();
}

if (window.location.hostname === 'localhost') {
  console.log(
    'testing locally -- hitting local functions and firestore emulators'
  );
  firebase.functions().useEmulator('localhost', 5001);
  firebase.firestore().useEmulator('localhost', 8080);
  firebase
    .auth()
    .useEmulator('http://localhost:9099/', { disableWarnings: true });
}

export const auth = firebase.auth();
export const db = firebase.firestore();
export const storage = firebase.storage();

export default firebase;
