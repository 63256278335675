import {
  Grid,
  Button,
  TextField,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import useStyles from './styles';
import useFirestoreCollection from '../../../hooks/useFirestoreCollection';
import { useState } from 'react';
import {
  fetchActiveDoctors,
  saveFileInFirestore,
} from '../../../services/firestore';
import { toast } from 'react-toastify';
import { v4 as uuidv4 } from 'uuid';
import { uploadUserFile } from '../../../services/firebaseStorage';
import { Form, Formik } from 'formik';
import MyTextInput from '../../../components/shared/form/MyTextInput';
import MyFileInput from '../../../components/shared/form/MyFileInput';
import { initialValues, validationSchema } from './formModel';
import MyButton from '../../../components/shared/form/MyButton';
import PublishIcon from '@material-ui/icons/Publish';

export default function LabFileModal({ open, setOpen }) {
  const classes = useStyles();
  const [uploading, setUploading] = useState(false);
  const [doctors, setDoctors] = useState([]);
  const [permissions, setPermissions] = useState([]);

  useFirestoreCollection({
    query: () => fetchActiveDoctors(),
    data: (doctors) =>
      setDoctors(
        doctors.map((doctor) => ({
          id: doctor.id,
          text: doctor.displayName,
        }))
      ),
    deps: [],
  });

  const handleUpload = async ({ file, description }) => {
    if (file == null) return;
    setUploading(true);
    try {
      const id = uuidv4();

      const uploadTask = uploadUserFile(file);
      uploadTask.on(
        'state_changed',
        (snapshot) => {
          const progress =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100;

          console.log('Upload is ' + progress + '% done');
        },
        (error) => {
          toast.error(error.message);
        },
        () => {
          uploadTask.snapshot.ref.getDownloadURL().then(async (downloadURL) => {
            await saveFileInFirestore({
              id,
              name: file.name,
              path: downloadURL,
              description,
              permissions: permissions.map((p) => p.id),
            });
            setUploading(false);
            setOpen(false);
            setPermissions([]);
            toast.success('Archivo cargado con éxito');
          });
        }
      );
    } catch (error) {
      toast.error('Error cargando el archivo');
      console.log(error);
    }
  };

  const handleSubmit = async (values, { setSubmitting }) => {
    try {
      await handleUpload(values);
    } catch (error) {
      toast.error('Hubo un error guardando el archivo. Inténtelo de nuevo');
    }
    setSubmitting(false);
  };

  return (
    <Dialog open={open} onBackdropClick={() => setOpen(false)}>
      <DialogTitle>
        <Typography color='primary' variant='h6'>
          Sube un archivo
        </Typography>
      </DialogTitle>
      <Formik
        validationSchema={validationSchema}
        initialValues={initialValues}
        onSubmit={handleSubmit}
      >
        {({ values, isSubmitting, isValid, dirty }) => (
          <Form>
            <DialogContent>
              <Grid container>
                <Grid item xs={12}>
                  <MyFileInput
                    variant='outlined'
                    label='Subir Archivo'
                    id='file'
                    name='file'
                    icon={
                      <PublishIcon
                        fontSize='small'
                        style={{ marginRight: '1rem' }}
                      />
                    }
                  />
                  {values.file == null && (
                    <Typography variant='subtitle2' color='primary'>
                      {' '}
                      * requerido / subir como PDF o imagen
                    </Typography>
                  )}
                </Grid>
                <Grid item xs={12} style={{ marginTop: '1rem' }}>
                  <MyTextInput
                    name='description'
                    label='Descripción *'
                    variant='outlined'
                    multiline
                    rows={3}
                    rowsMax={5}
                    fullWidth
                  />
                </Grid>
              </Grid>
              <Grid item xs={12} style={{ marginTop: '1rem' }}>
                <Typography gutterBottom variant='subtitle2'>
                  Busque el nombre del doctor en la lista abajo para otogarle
                  permiso de ver el archivo. Este paso puede completarlo mas
                  tarde.
                </Typography>
                <Autocomplete
                  multiple
                  id='tags-standard'
                  options={doctors}
                  getOptionLabel={(option) => option.text}
                  value={permissions}
                  className={classes.autocomplete}
                  onChange={(e, newValue) => setPermissions(newValue)}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant='outlined'
                      label='Doctores'
                      placeholder='Buscar Doctores...'
                    />
                  )}
                />
              </Grid>
            </DialogContent>
            <DialogActions>
              <Button onClick={() => setOpen(false)}>Cancelar</Button>
              <MyButton
                type='submit'
                label='Guardar'
                color='primary'
                size='medium'
                variant='contained'
                className={classes.submit}
                disabled={!isValid || !dirty || isSubmitting}
                loading={uploading}
              />
            </DialogActions>
          </Form>
        )}
      </Formik>
    </Dialog>
  );
}
