import { createRef, useEffect } from 'react';
import DailyIframe from '@daily-co/daily-js';

const VideoCallFrame = ({ roomName }) => {
  const iframeRef = createRef();

  useEffect(() => {
    const daily = DailyIframe.wrap(iframeRef.current, {
      iframeStyle: {
        position: 'fixed',
        width: '100%',
        height: '100%',
      },
      showLeaveButton: true,
      showFullscreenButton: true,
      lang: 'es',
    });
    const dailyURL = process.env.REACT_APP_DAILY_URL;
    if (!dailyURL) {
      console.error('please set REACT_APP_DAILY_ROOM_URL env variable!');
      return;
    }
    console.log(`${dailyURL}/${roomName}`);
    daily.join({ url: `${dailyURL}/${roomName}` });
  }, [roomName, iframeRef]);

  return (
    <iframe
      className='Video-Frame'
      title='video call iframe'
      height='600px'
      width='100%'
      ref={iframeRef}
      allow='camera; microphone; fullscreen'
    ></iframe>
  );
};

export default VideoCallFrame;
