import React, { useState } from 'react';
import { Button, Grid, Typography } from '@material-ui/core';
import { Form, Formik } from 'formik';
import MyButton from '../../../components/shared/form/MyButton';
import MySelectInput from '../../../components/shared/form/MySelectInput';
import MyTextInput from '../../../components/shared/form/MyTextInput';
import initialValues from './formModel/initialValues';
import validationSchema from './formModel/validationSchema';
import useStyles from './styles';
import sucursalFormModel from './formModel/sucursalFormModel';
import {
  createSucursal,
  getSucursal,
  updateSucursal,
} from '../../../services/firestore';
import { toast } from 'react-toastify';
import Tippy from '@tippyjs/react';

import es from 'date-fns/locale/es';
import 'resize-observer-polyfill/dist/ResizeObserver.global';
import {
  DefaultEventRootComponent,
  TimeGridScheduler,
  SchedulerContext,
  classes as classesScheduler,
} from '@remotelock/react-week-scheduler';
import '@remotelock/react-week-scheduler/index.css';
import { startOfWeek } from 'date-fns';
import DeleteIcon from '@material-ui/icons/Delete';
import { Link, useParams } from 'react-router-dom';
import useFirestoreDoc from '../../../hooks/useFirestoreDoc';
import { useSelector } from 'react-redux';
import Loader from '../../../components/layout/Loader/Loader';
import MyCheckbox from '../../../components/shared/form/MyCheckbox';

const {
  formId,
  formField: {
    name,
    address1,
    city,
    state,
    phone,
    googleLoc,
    numRooms,
    isVisible,
  },
} = sucursalFormModel;

const _states = [
  { label: 'Beni', value: 'Beni' },
  { label: 'Cochabamba', value: 'Cochabamba' },
  { label: 'La Paz', value: 'La Paz' },
  { label: 'Oruro', value: 'Oruro' },
  { label: 'Pando', value: 'Pando' },
  { label: 'Potosí', value: 'Potosí' },
  { label: 'Santa Cruz', value: 'Santa Cruz' },
  { label: 'Tarija', value: 'Tarija' },
];

const EventRoot = React.forwardRef(
  ({ handleDelete, disabled, ...props }, ref) => {
    return (
      <Tippy
        arrow
        interactive
        animation='fade'
        hideOnClick={false}
        className='tooltip'
        content={
          <button disabled={disabled} type='button' onClick={handleDelete}>
            <DeleteIcon className='icon' />
            Borrar
          </button>
        }
      >
        <DefaultEventRootComponent
          handleDelete={handleDelete}
          disabled={disabled}
          {...props}
          ref={ref}
        />
      </Tippy>
    );
  }
);

const EditSucursalForm = ({ history }) => {
  const classes = useStyles();
  const [schedule, setSchedule] = useState([]);
  const [sucursal, setSucursal] = useState();
  const [scheduleError, setScheduleError] = useState(false);
  const { loading } = useSelector((state) => state.async);

  const { sucursalId } = useParams();

  const handleSetSchedule = (schedule) => {
    if (!schedule.length) {
      setScheduleError(true);
      return;
    }
    setScheduleError(false);
    setSchedule(schedule);
  };

  useFirestoreDoc({
    query: () => getSucursal(sucursalId),
    data: (sucursal) => {
      setSucursal(sucursal);
      const defaultSchedule = Object.keys(sucursal.schedule).map((key) => [
        sucursal.schedule[key][0].toDate(),
        sucursal.schedule[key][1].toDate(),
      ]);
      setSchedule(defaultSchedule);
    },
    deps: [sucursalId],
  });

  const handleSubmit = async (values, { setSubmitting, resetForm }) => {
    if (!schedule.length) {
      setScheduleError(true);
      return;
    }
    try {
      await updateSucursal({ ...values, id: sucursalId, schedule });
      console.log({ ...values, id: sucursalId, schedule });
      history.goBack();
      toast.success('Sucursal editada');
    } catch (error) {
      console.log(error);
      toast.error('Error editando sucursal');
    } finally {
      setSubmitting(false);
    }
  };

  if (loading) return <Loader />;

  return (
    <div>
      <Button
        component={Link}
        variant='outlined'
        color='primary'
        to={'/admin-dashboard/sucursales'}
      >
        {'< Volver'}
      </Button>
      <Typography variant='h5' color='primary' align='center' gutterBottom>
        Editar Sucursal
      </Typography>
      <Formik
        initialValues={sucursal}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({ isSubmitting, isValid, dirty }) => (
          <Form id={formId} className={classes.form}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <MyTextInput name={name.name} label={name.label} fullWidth />
              </Grid>
              <Grid item xs={12}>
                <MyTextInput
                  name={address1.name}
                  label={address1.label}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <MyTextInput name={city.name} label={city.label} fullWidth />
              </Grid>
              <Grid item xs={12} sm={6}>
                <MySelectInput
                  name={state.name}
                  label={state.label}
                  data={_states}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <MyTextInput name={phone.name} label={phone.label} fullWidth />
              </Grid>
              <Grid item xs={12} sm={6}>
                <MyTextInput
                  name={numRooms.name}
                  type='number'
                  min={1}
                  InputProps={{ inputProps: { min: 1, max: 10 } }}
                  label={numRooms.label}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12}>
                <MyTextInput
                  name={googleLoc.name}
                  label={googleLoc.label}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12}>
                <MyCheckbox
                  name={isVisible.name}
                  color='primary'
                  label={isVisible.label}
                />
              </Grid>
              <Grid item xs={12}>
                <Typography
                  className={classes.schedulerTitle}
                  color='primary'
                  gutterBottom
                >
                  Horarios de Atención
                </Typography>
                <SchedulerContext.Provider value={{ locale: es }}>
                  <div
                    className='root'
                    style={{
                      width: '100%',
                      height: '600px',
                      '--cell-height': '20px',
                      '--cell-width': '20px',
                    }}
                  >
                    <TimeGridScheduler
                      classes={classesScheduler}
                      style={{ width: '100%', height: '100%' }}
                      originDate={startOfWeek(new Date('2020-01-01'), {
                        weekStartsOn: 1,
                      })}
                      schedule={schedule}
                      onChange={handleSetSchedule}
                      visualGridVerticalPrecision={30}
                      verticalPrecision={30}
                      cellClickPrecision={60}
                      eventRootComponent={EventRoot}
                    />
                  </div>
                </SchedulerContext.Provider>
                {scheduleError && (
                  <Typography className={classes.schedulerError}>
                    Por favor ingrese los horarias de la sucursal
                  </Typography>
                )}
              </Grid>

              <Grid item xs={12}>
                <MyButton
                  type='submit'
                  label='Guardar'
                  color='primary'
                  size='medium'
                  variant='contained'
                  className={classes.submit}
                  //disabled={!isValid || !dirty || isSubmitting || scheduleError}
                  loading={isSubmitting}
                />
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default EditSucursalForm;
