import { Container, Paper, Typography } from '@material-ui/core';
import { classes } from '@remotelock/react-week-scheduler';
import Footer from '../../components/layout/Footer/Footer';
import useStyles from './styles';

const NotFoundView = () => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Container maxWidth='lg' className={classes.content}>
        <Paper>
          <Typography
            align='center'
            variant='h6'
            color='primary'
            style={{ padding: '5rem 0' }}
          >
            Esta página no se encuentra disponible
          </Typography>
        </Paper>
      </Container>
      <Footer />
    </div>
  );
};

export default NotFoundView;
