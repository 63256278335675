import React from 'react';
import { Switch, Route } from 'react-router-dom';
import Loader from './components/layout/Loader/Loader';
import LandingPage from './views/home/LandingPage';
import LoginPage from './views/auth/LoginPage/LoginPage';
import RegisterPage from './views/auth/RegisterPage/RegisterPage';
import { useSelector } from 'react-redux';
import DoctorCompleteRegistrationForm from './views/auth/RegisterPage/DoctorCompleteRegistrationForm/DoctorCompleteRegistrationForm';
import ResolveAuthPage from './views/auth/ResolveAuthPage/ResolveAuthPage';
import NotFoundView from './views/errors/NotFoundView';
import DoctorHome from './views/doctor/DoctorHome';
import DoctorRoute from './components/routes/DoctorRoute';
import PatientRoute from './components/routes/PatientRoute';
import AdminRoute from './components/routes/AdminRoute';
import PatientHome from './views/patient/PatientHome';
import BookingPage from './views/patient/BookingPage/BookingPage';
import MyBookings from './views/patient/MyBookings';
import PatientCallPage from './views/patient/PatientCallPage';
import DoctorBookings from './views/doctor/DoctorBookings';
import DoctorCallPage from './views/doctor/videoCall/DoctorCallPage';
import ConsultorioBookingWix from './views/consultorio/ConsultorioBookingWix';
import ConsultorioBooking from './views/consultorio/ConsultorioBooking';
import AboutUsPage from './views/about/AboutUsPage';
import UnauthDoctorListPage from './views/unauthDoctorList/UnauthDoctorListPage';
import DoctorProfilePage from './components/doctorList/DoctorProfile/DoctorProfilePage';
import TermsOfUse from './views/policies/TermsOfUse';
import PrivacyPolicy from './views/policies/PrivacyPolicy';
import AwaitingEmailVerificationPage from './views/auth/AwaitingEmailVerificationPage/AwaitingEmailVerificationPage';
import AdminHome from './views/admin/AdminHome';
import AdminLabs from './views/admin/AdminLabs';
import DoctorProfileEdit from './views/doctor/profile/DoctorProfileEdit';
import AdminUsers from './views/admin/AdminUsers';
import AdminCategories from './views/admin/AdminCategories';
import DoctorPaymentForm from './views/doctor/profile/DoctorPaymentForm';
import DoctorSchedule from './views/doctor/DoctorSchedule';
import LiveesCheckout from './views/patient//Livees/LiveesCheckout';
import LiveesResult from './views/patient/Livees/LiveesResult';
import PatientFiles from './views/patient/PatientFiles/PatientFiles';
import DoctorList from './components/doctorList/DoctorList';
import AdminSucursales from './views/admin/sucursales/AdminSucursales';
import CreateSucursalForm from './views/admin/sucursales/CreateSucursalForm';
import EditSucursalForm from './views/admin/sucursales/EditSucursalForm';
import UnauthDoctorProfilePage from './views/unauthDoctorList/UnauthDoctorProfilePage';
import LabRegisterForm from './views/auth/RegisterPage/LabRegisterForm/LabRegisterForm';
import LabRoute from './components/routes/LabRoute';
import LabHome from './views/laboratorios/home/LabHome';
import LabFiles from './views/laboratorios/LabFiles/LabFiles';
import DoctorLabFiles from './views/doctor/DoctorLabFiles/DoctorLabFiles';
import AwaitingActivationPage from './views/auth/AwaitingActivationPage/AwaitingActivationPage';

const UserRoutes = () => {
  const { initialized } = useSelector((state) => state.async);

  if (!initialized) return <Loader />;

  return (
    <Switch>
      {/* Home Page */}
      <Route exact path='/' component={LandingPage} />
      {/* About Us Page */}
      <Route path='/nosotros' component={AboutUsPage} />
      {/* Doctor List - Unauthenticated User */}
      <Route exact path='/doctores' component={UnauthDoctorListPage} />
      <Route path='/doctores/:doctorId' component={UnauthDoctorProfilePage} />

      {/* Auth Routes */}
      <Route path='/resolve-auth' component={ResolveAuthPage} />
      <Route path='/login' component={LoginPage} />
      <Route exact path='/register' component={RegisterPage} />
      <Route exact path='/lab-register' component={LabRegisterForm} />
      <Route
        path='/register/doctor-complete'
        component={DoctorCompleteRegistrationForm}
      />
      <Route
        path='/register/email-verification'
        component={AwaitingEmailVerificationPage}
      />
      <Route
        exact
        path='/awaiting-activation'
        component={AwaitingActivationPage}
      />

      <Route path='/consultorios-wix' component={ConsultorioBookingWix} />
      <Route path='/consultorios' component={ConsultorioBooking} />

      {/* Policies */}
      <Route path='/terms-of-use' component={TermsOfUse} />
      <Route path='/privacy-policy' component={PrivacyPolicy} />

      {/* Doctor Routes */}
      <DoctorRoute exact path='/doctor-dashboard' component={DoctorHome} />

      <DoctorRoute
        path='/doctor-dashboard/profile/edit'
        component={DoctorProfileEdit}
      />
      <DoctorRoute
        exact
        path='/doctor-dashboard/my-bookings'
        component={DoctorBookings}
      />
      <DoctorRoute
        path='/doctor-dashboard/payment/'
        component={DoctorPaymentForm}
      />
      <DoctorRoute
        path='/doctor-dashboard/schedule/'
        component={DoctorSchedule}
      />
      <DoctorRoute
        path='/doctor-dashboard/my-bookings/:bookingId'
        component={DoctorCallPage}
      />
      <DoctorRoute
        exact
        path='/doctor-dashboard/doctors'
        component={DoctorList}
      />
      <DoctorRoute
        path='/doctor-dashboard/doctors/:doctorId'
        component={DoctorProfilePage}
      />
      <DoctorRoute
        exact
        path='/doctor-dashboard/lab-files'
        component={DoctorLabFiles}
      />

      {/* Patient Routes */}
      <PatientRoute exact path='/patient-dashboard' component={PatientHome} />
      <PatientRoute
        path='/patient-dashboard/doctors/:doctorId'
        component={DoctorProfilePage}
      />
      <PatientRoute
        path='/patient-dashboard/my-files'
        component={PatientFiles}
      />
      {/* New Booking Form */}
      <PatientRoute
        path='/patient-dashboard/booking/:doctor_id'
        component={BookingPage}
      />
      <PatientRoute
        exact
        path='/patient-dashboard/my-bookings'
        component={MyBookings}
      />
      <PatientRoute
        path='/patient-dashboard/my-bookings/:bookingId'
        component={PatientCallPage}
      />
      <PatientRoute
        path='/patient-dashboard/livees-checkout'
        component={LiveesCheckout}
      />
      <PatientRoute
        path='/patient-dashboard/livees-result'
        component={LiveesResult}
      />

      {/* Admin Routes */}
      <AdminRoute exact path='/admin-dashboard' component={AdminHome} />
      <AdminRoute exact path='/admin-dashboard/users' component={AdminUsers} />
      <AdminRoute
        exact
        path='/admin-dashboard/categories'
        component={AdminCategories}
      />
      <AdminRoute
        exact
        path='/admin-dashboard/laboratorios'
        component={AdminLabs}
      />
      <AdminRoute
        exact
        path='/admin-dashboard/sucursales'
        component={AdminSucursales}
      />
      <AdminRoute
        exact
        path='/admin-dashboard/sucursales/create'
        component={CreateSucursalForm}
      />
      <AdminRoute
        exact
        path='/admin-dashboard/sucursales/edit/:sucursalId'
        component={EditSucursalForm}
      />

      {/* Lab Routes */}
      <LabRoute exact path='/lab-dashboard' component={LabHome} />
      <LabRoute exact path='/lab-dashboard/my-files' component={LabFiles} />

      <Route component={NotFoundView} />

      {/* <Route path='/forgot-password' component={ForgotPassword} /> */}
    </Switch>
  );
};

export default UserRoutes;
