// eslint-disable-next-line import/no-anonymous-default-export
export default {
  formId: 'consultorioForm',
  formField: {
    sucursal: {
      name: 'sucursal',
      label: 'Sucursal*',
      requiredErrorMsg: 'Campo requerido',
    },
    date: {
      name: 'date',
    },
    name: {
      name: 'name',
      label: 'Nombre*',
      requiredErrorMsg: 'Campo requerido',
    },
    lastName: {
      name: 'lastName',
      label: 'Apellidos*',
      requiredErrorMsg: 'Campo requerido',
    },
    email: {
      name: 'email',
      label: 'Correo Electrónico*',
      requiredErrorMsg: 'Campo requerido',
    },
    phone: {
      name: 'phone',
      label: 'Teléfono*',
      requiredErrorMsg: 'Campo requerido',
    },
  },
};
