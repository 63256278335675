import { Paper, TextField } from '@material-ui/core';

const SearchBox = ({ searchByTerm }) => {
  return (
    <TextField
      placeholder='Buscar por nombre o especialidad...'
      size='small'
      variant='outlined'
      fullWidth
      onChange={(e) => searchByTerm(e.target.value)}
    />
  );
};

export default SearchBox;
