import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  root: {
    maxWidth: '1600px',
    marginTop: '2rem',
    [theme.breakpoints.up('md')]: {
      padding: '0 6rem',
      margin: '6rem auto',
    },
  },
  textGroup: {
    fontWeight: 300,
    [theme.breakpoints.up('md')]: {
      fontSize: '1rem',
      paddingRight: '1rem',
    },
  },
  textContent: {
    fontSize: '1.1rem',
    marginTop: '1rem',
  },
  img: {
    width: '100%',
    margin: '2rem 0',
    [theme.breakpoints.up('md')]: {
      margin: 0,
    },
  },
  secondRow: {
    marginTop: '1.5rem',
  },
  title: {
    textTransform: 'uppercase',
    fontWeight: 800,
    fontStyle: 'italic',
    textAlign: 'center',
    fontSize: '2.2rem',
    [theme.breakpoints.up('md')]: {
      fontSize: '3rem',
      textAlign: 'left',
    },
  },
  separator: {
    border: '2px solid',
    borderColor: theme.palette.secondary.main,
    backgroundColor: theme.palette.secondary.main,
    width: '10rem',
    marginTop: '0.8rem',
    [theme.breakpoints.up('md')]: {
      marginLeft: 0,
    },
  },
}));
