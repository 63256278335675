import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import moment from 'moment';
import { useCallback, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import useFirestoreCollection from '../../../hooks/useFirestoreCollection';
import { getLastTenBookings } from '../../../services/firestore';
import useStyles from './styles';

const AdminNotifications = () => {
  const classes = useStyles();

  const [bookings, setBookings] = useState([]);

  useFirestoreCollection({
    query: () => getLastTenBookings(),
    data: (data) => setBookings(data),
    deps: [],
  });

  return (
    <>
      <Typography component='h2' variant='h6' color='primary' gutterBottom>
        Últimas Teleconsultas
      </Typography>
      <Table size='small'>
        <TableHead className={classes.thead}>
          <TableRow>
            <TableCell>Fecha Creación</TableCell>
            <TableCell>Paciente</TableCell>
            <TableCell>Doctor</TableCell>
            <TableCell align='right'>Total Pagado</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {bookings.map((row) => (
            <TableRow key={row.id}>
              <TableCell>
                {row.createdAt
                  ? moment(row.createdAt).format('DD/MM/YY hh:mm')
                  : 'No disponible'}
              </TableCell>
              <TableCell>
                {row.patient.name} {row.patient.lastName}
              </TableCell>
              <TableCell>
                {row.doctor.name} {row.doctor.lastName}
              </TableCell>
              <TableCell align='right'>{row.total} Bs.</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </>
  );
};

export default AdminNotifications;
