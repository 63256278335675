import { CssBaseline, ThemeProvider } from '@material-ui/core';
import theme from './theme/theme';
import Routes from './Routes';
import { ToastContainer } from 'react-toastify';
import {
  BrowserRouter as Router,
  useLocation,
  withRouter,
} from 'react-router-dom';
import Navbar from './components/layout/Header/Navbar';
import { useEffect, useState } from 'react';
import MaintenancePage from './views/maintenance/MaintenancePage';
import { getWithExpiry } from './util';
import MessengerCustomerChat from 'react-messenger-customer-chat/lib/MessengerCustomerChat';

function _ScrollToTop(props) {
  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  return props.children;
}

const ScrollToTop = withRouter(_ScrollToTop);

const App = () => {
  // Set to true to unblock site
  const [isAuth, setIsAuth] = useState(true);

  useEffect(() => {
    if (getWithExpiry('isAuth')) {
      setIsAuth(true);
    }
  }, []);

  if (!isAuth) return <MaintenancePage setIsAuth={setIsAuth} />;

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <ToastContainer position='bottom-right' hideProgressBar />
      <Router>
        <ScrollToTop>
          <Navbar />
          <Routes />
          <MessengerCustomerChat
            pageId='101756531722644'
            appId='443826500302731'
          />
          {/* For Testing with Ruralstock */}
          {/* <MessengerCustomerChat pageId='752862011733792' appId='443826500302731' /> */}
        </ScrollToTop>
      </Router>
    </ThemeProvider>
  );
};

export default App;
