import React, { useState } from 'react';
import { Button, Grid, Typography } from '@material-ui/core';
import { Form, Formik } from 'formik';
import MyButton from '../../../components/shared/form/MyButton';
import MySelectInput from '../../../components/shared/form/MySelectInput';
import MyTextInput from '../../../components/shared/form/MyTextInput';
import MyCheckbox from '../../../components/shared/form/MyCheckbox';
import initialValues from './formModel/initialValues';
import validationSchema from './formModel/validationSchema';
import useStyles from './styles';
import sucursalFormModel from './formModel/sucursalFormModel';
import { createSucursal } from '../../../services/firestore';
import { toast } from 'react-toastify';
import Tippy from '@tippyjs/react';

import es from 'date-fns/locale/es';
import 'resize-observer-polyfill/dist/ResizeObserver.global';
import {
  DefaultEventRootComponent,
  TimeGridScheduler,
  SchedulerContext,
  classes as classesScheduler,
} from '@remotelock/react-week-scheduler';
import '@remotelock/react-week-scheduler/index.css';
import { startOfWeek } from 'date-fns';
import DeleteIcon from '@material-ui/icons/Delete';
import { Link } from 'react-router-dom';

const {
  formId,
  formField: {
    name,
    address1,
    city,
    state,
    phone,
    googleLoc,
    numRooms,
    isVisible,
  },
} = sucursalFormModel;

const _states = [
  { label: 'Beni', value: 'Beni' },
  { label: 'Cochabamba', value: 'Cochabamba' },
  { label: 'La Paz', value: 'La Paz' },
  { label: 'Oruro', value: 'Oruro' },
  { label: 'Pando', value: 'Pando' },
  { label: 'Potosí', value: 'Potosí' },
  { label: 'Santa Cruz', value: 'Santa Cruz' },
  { label: 'Tarija', value: 'Tarija' },
];

const EventRoot = React.forwardRef(
  ({ handleDelete, disabled, ...props }, ref) => {
    return (
      <Tippy
        arrow
        interactive
        animation='fade'
        hideOnClick={false}
        className='tooltip'
        content={
          <button disabled={disabled} type='button' onClick={handleDelete}>
            <DeleteIcon className='icon' />
            Borrar
          </button>
        }
      >
        <DefaultEventRootComponent
          handleDelete={handleDelete}
          disabled={disabled}
          {...props}
          ref={ref}
        />
      </Tippy>
    );
  }
);

const CreateSucursalForm = () => {
  const classes = useStyles();
  const [schedule, setSchedule] = useState([]);
  const [scheduleError, setScheduleError] = useState(true);

  const handleSetSchedule = (schedule) => {
    if (!schedule.length) {
      setScheduleError(true);
      return;
    }
    setScheduleError(false);
    setSchedule(schedule);
  };
  const handleSubmit = async (values, { setSubmitting, resetForm }) => {
    if (!schedule.length) {
      setScheduleError(true);
      return;
    }
    try {
      await createSucursal({ ...values, schedule });
      resetForm();
      setSchedule([]);
      setScheduleError(false);
      toast.success('Sucursal creada');
    } catch (error) {
      console.log(error);
      toast.error('Error creando sucursal');
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <div>
      <Button
        component={Link}
        variant='outlined'
        color='primary'
        to={'/admin-dashboard/sucursales'}
      >
        {'< Volver'}
      </Button>
      <Typography variant='h5' color='primary' align='center' gutterBottom>
        Crear Sucursal
      </Typography>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({ values, handleChange, isSubmitting, isValid, dirty }) => (
          <Form id={formId} className={classes.form}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <MyTextInput name={name.name} label={name.label} fullWidth />
              </Grid>
              <Grid item xs={12}>
                <MyTextInput
                  name={address1.name}
                  label={address1.label}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <MyTextInput name={city.name} label={city.label} fullWidth />
              </Grid>
              <Grid item xs={12} sm={6}>
                <MySelectInput
                  name={state.name}
                  label={state.label}
                  data={_states}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <MyTextInput name={phone.name} label={phone.label} fullWidth />
              </Grid>
              <Grid item xs={12} sm={6}>
                <MyTextInput
                  name={numRooms.name}
                  type='number'
                  min={1}
                  InputProps={{ inputProps: { min: 1, max: 10 } }}
                  label={numRooms.label}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12}>
                <MyTextInput
                  name={googleLoc.name}
                  label={googleLoc.label}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12}>
                <MyCheckbox
                  name={isVisible.name}
                  color='primary'
                  label={isVisible.label}
                />
              </Grid>
              <Grid item xs={12}>
                <Typography
                  className={classes.schedulerTitle}
                  color='primary'
                  gutterBottom
                >
                  Horarios de Atención
                </Typography>
                <SchedulerContext.Provider value={{ locale: es }}>
                  <div
                    className='root'
                    style={{
                      width: '100%',
                      height: '600px',
                      '--cell-height': '20px',
                      '--cell-width': '20px',
                    }}
                  >
                    <TimeGridScheduler
                      classes={classesScheduler}
                      style={{ width: '100%', height: '100%' }}
                      originDate={startOfWeek(new Date('2020-01-01'), {
                        weekStartsOn: 1,
                      })}
                      schedule={schedule}
                      onChange={handleSetSchedule}
                      visualGridVerticalPrecision={30}
                      verticalPrecision={30}
                      cellClickPrecision={60}
                      eventRootComponent={EventRoot}
                    />
                  </div>
                </SchedulerContext.Provider>
                {scheduleError && (
                  <Typography className={classes.schedulerError}>
                    Por favor ingrese los horarias de la sucursal
                  </Typography>
                )}
              </Grid>

              <Grid item xs={12}>
                <MyButton
                  type='submit'
                  label='Guardar'
                  color='primary'
                  size='medium'
                  variant='contained'
                  className={classes.submit}
                  disabled={!isValid || !dirty || isSubmitting || scheduleError}
                  loading={isSubmitting}
                />
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default CreateSucursalForm;
