// eslint-disable-next-line import/no-anonymous-default-export
export default {
  formId: 'bookingForm',
  formField: {
    date: {
      name: 'date',
    },
    name: {
      name: 'name',
      label: 'Nombre*',
      requiredErrorMsg: 'Campo requerido',
    },
    lastName: {
      name: 'lastName',
      label: 'Apellidos*',
      requiredErrorMsg: 'Campo requerido',
    },
    symptoms: {
      name: 'symptoms',
      label: 'Síntomas',
      requiredErrorMsg: 'Campo requerido',
    },
    address1: {
      name: 'address1',
      label: 'Dirección 1*',
      requiredErrorMsg: 'Campo requerido',
    },
    address2: {
      name: 'address2',
      label: 'Dirección 2',
    },
    city: {
      name: 'city',
      label: 'Ciudad*',
      requiredErrorMsg: 'Campo requerido',
    },
    state: {
      name: 'state',
      label: 'Departamento',
      requiredErrorMsg: 'Campo requerido',
    },
    phone: {
      name: 'phone',
      label: 'Teléfono de Contacto',
      requiredErrorMsg: 'Campo requerido',
    },
  },
};
