import { makeStyles } from '@material-ui/core';

export default makeStyles((theme) => ({
  root: {
    padding: theme.spacing(1),
  },
  alerts: {
    '& > * + *': {
      marginTop: theme.spacing(2),
    },
  },
  alertLink: {
    textDecoration: 'none',
    color: 'inherit',
  },
}));
