import { useEffect, useState } from 'react';
import {
  Button,
  CircularProgress,
  Container,
  Typography,
} from '@material-ui/core';
import { Link } from 'react-router-dom';
import queryString from 'query-string';
import {
  deleteBooking,
  updateBookingToPaid,
} from '../../../services/firestore';
import CheckAnimation from '../../../assets/icons/check.gif';

const LiveesResult = ({ location: { search } }) => {
  const [success, setSuccess] = useState(false);
  const [loading, setLoading] = useState(true);

  const { result: bookingId, order_id: liveesId } = queryString.parse(search);

  useEffect(() => {
    handleLiveesResult();
  }, []);

  const handleLiveesResult = async () => {
    // handle success
    if (bookingId && liveesId) {
      // result = appointment.id ; order_id = liveesId

      await updateBookingToPaid(bookingId, liveesId);
      localStorage.removeItem('bookingId');
      setLoading(false);
      setSuccess(true);
    } else {
      // Delete Order and show failure message
      const id = localStorage.getItem('bookingId');
      await deleteBooking(id);
      localStorage.removeItem('bookingId');
      setLoading(false);
      setSuccess(true);
    }
  };

  return (
    <Container maxWidth='md'>
      <div style={{ padding: '5rem 0', textAlign: 'center' }}>
        {loading && (
          <>
            <Typography variant='h6' color='primary'>
              Por favor aguarda mientras procesamos tu consulta
            </Typography>
            <CircularProgress />
          </>
        )}
        {!loading &&
          (success ? (
            <>
              <img src={CheckAnimation} alt='success' width='300px' />
              <Typography
                variant='h5'
                color='primary'
                gutterBottom
                style={{ marginTop: '-2rem' }}
              >
                Gracias por crear una consulta.
              </Typography>
              <Typography variant='body1' gutterBottom>
                Enseguida recibirás un correo electrónico de confirmación con la
                información sobre tu consulta y el enlace para ingresar a tu
                consulta.
              </Typography>
              <Button
                component={Link}
                to='/patient-dashboard'
                color='primary'
                variant='contained'
                style={{ marginTop: '2rem' }}
              >
                Salir
              </Button>
            </>
          ) : (
            <>
              <Typography variant='h5' color='primary' gutterBottom>
                No se pudo procesar tu consulta
              </Typography>
              <Typography variant='body1' gutterBottom>
                Puede que haya habido un error procesando el pago. <br />{' '}
                ¿Quieres intentarlo de nuevo?
              </Typography>
              <Button
                component={Link}
                to='/patient-dashboard'
                color='primary'
                variant='contained'
                style={{ marginTop: '2rem' }}
              >
                Ver Doctores
              </Button>
            </>
          ))}
      </div>
    </Container>
  );
};

export default LiveesResult;
// 4nj9Rfn4dxnyAQcJB3rR
