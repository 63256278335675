import {
  Button,
  CircularProgress,
  Container,
  Step,
  StepLabel,
  Stepper,
  Typography,
} from '@material-ui/core';
import { Form, Formik } from 'formik';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router';
import MyButton from '../../../components/shared/form/MyButton';
import useFirestoreDoc from '../../../hooks/useFirestoreDoc';
import useFirestoreCollection from '../../../hooks/useFirestoreCollection';
import {
  dataFromSnapshot,
  getFutureDoctorBookings,
  getUserProfile,
  saveBookingInFirestore,
} from '../../../services/firestore';
import bookingFormModel from './FormModel/bookingFormModel';
import formInitialValues from './FormModel/formInitialValues';
import validationSchema from './FormModel/validationSchema';
import DateSelectionForm from './Forms/DateSelectionForm';
import PatientInfoForm from './Forms/PatientInfoForm';
import ReviewBooking from './ReviewBooking/ReviewBooking';

import useStyles from './styles';

const steps = ['Fecha de Consulta', 'Datos del Paciente', 'Revisar Datos'];
const { formId, formField } = bookingFormModel;

const BookingPage = ({ history }) => {
  const classes = useStyles();
  const { doctor_id } = useParams();
  const [activeStep, setActiveStep] = useState(0);
  const currentValidationSchema = validationSchema[activeStep];
  const isLastStep = activeStep === steps.length - 1;
  const { currentUser } = useSelector((state) => state.auth);
  const [doctorProfile, setDoctorProfile] = useState();
  const [doctorBookingDates, setDoctorBookingDates] = useState([]);

  useFirestoreDoc({
    query: () => getUserProfile(doctor_id),
    data: (profile) => setDoctorProfile(profile),
    deps: [doctor_id],
  });

  useFirestoreCollection({
    query: () => getFutureDoctorBookings(doctor_id),
    data: (bookings) => setDoctorBookingDates(bookings.map((b) => b.date)),
    deps: [doctor_id],
  });

  function _renderStepContent(step) {
    switch (step) {
      case 0:
        return (
          <DateSelectionForm
            schedule={doctorProfile?.schedule}
            prevBookings={doctorBookingDates}
            formField={formField}
          />
        );
      case 1:
        return <PatientInfoForm formField={formField} />;
      case 2:
        return <ReviewBooking doctor={doctorProfile} />;
      default:
        return <div>Not Found</div>;
    }
  }

  async function _submitForm(values, actions) {
    saveBookingInFirestore({
      date: values.date,
      isPaid: false,
      total: doctorProfile.paymentInfo.appointmentRate,
      doctor: {
        id: doctorProfile.id,
        email: doctorProfile.email,
        displayName: doctorProfile.displayName,
        name: doctorProfile.name,
        lastName: doctorProfile.lastName,
      },
      patient: {
        id: currentUser.id,
        email: currentUser.email,
        displayName: currentUser.displayName,
        name: values.name,
        lastName: values.lastName,
        symptoms: values.symptoms,
        address1: values.address1,
        address2: values.address2,
        city: values.city,
        state: values.state,
        phone: values.phone,
      },
    }).then((docRef) => {
      docRef.get().then((doc) => {
        history.push({
          pathname: '/patient-dashboard/livees-checkout',
          state: {
            appointment: dataFromSnapshot(doc),
          },
        });
      });
    });
  }

  function _handleSubmit(values, actions) {
    console.log('HANDLE SUBMIT');

    if (isLastStep) {
      _submitForm(values, actions);
    } else {
      setActiveStep(activeStep + 1);
      actions.setTouched({});
      actions.setSubmitting(false);
    }
  }

  function _handleBack() {
    setActiveStep(activeStep - 1);
  }

  return (
    <Container justify='center' align='center'>
      <Typography component='h1' variant='h4' color='primary' align='center'>
        Agendar Consulta
      </Typography>
      <Stepper activeStep={activeStep} className={classes.stepper}>
        {steps.map((label) => (
          <Step key={label}>
            <StepLabel>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>
      <>
        {activeStep === steps.length ? (
          <h1>Gracias por rellenar el formulario</h1>
        ) : (
          <Formik
            initialValues={formInitialValues}
            validationSchema={currentValidationSchema}
            onSubmit={_handleSubmit}
          >
            {({ isSubmitting, isValid }) => (
              <Form id={formId}>
                {_renderStepContent(activeStep)}

                <div className={classes.buttons}>
                  {activeStep !== 0 && (
                    <Button onClick={_handleBack} className={classes.button}>
                      Volver
                    </Button>
                  )}
                  <div className={classes.wrapper}>
                    <MyButton
                      disabled={isSubmitting}
                      loading={isSubmitting}
                      type='submit'
                      variant='contained'
                      color='primary'
                      className={classes.button}
                      label={
                        isLastStep
                          ? `Proceder al Pago (${doctorProfile?.paymentInfo.appointmentRate} Bs.)`
                          : 'Seguir'
                      }
                    />
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        )}
      </>
    </Container>
  );
};

export default BookingPage;
