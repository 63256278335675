import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  root: {
    paddingTop: '1rem',
    paddingBottom: '2rem',
    [theme.breakpoints.up('md')]: {
      paddingBottom: '3rem',
    },
  },
  textContent: {
    fontSize: '1.1rem',
    fontWeight: 300,
  },
  sectionTitle: {
    marginTop: '1rem',
    fontWeight: 600,
    fontSize: '2.2rem',
    fontStyle: 'italic',
    textAlign: 'center',
    [theme.breakpoints.up('md')]: {
      textAlign: 'left',
      fontSize: '2.5rem',
    },
  },
  alignRight: {
    [theme.breakpoints.up('md')]: {
      textAlign: 'right',
    },
  },
  img: {
    width: '100%',
    marginTop: theme.spacing(2),
    [theme.breakpoints.up('md')]: {
      marginTop: 0,
    },
  },
  quotes: {
    marginTop: '1rem',
    marginLeft: '2rem',
    width: '5rem',
  },
  secondRow: {
    [theme.breakpoints.up('md')]: {
      marginTop: '1.5rem',
    },
  },
  firstRowTextContent: {
    [theme.breakpoints.up('md')]: {
      textAlign: 'right',
      padding: theme.spacing(2),
    },
  },
  secondRowTextContent: {
    padding: theme.spacing(2),
  },
  separator: {
    border: '2px solid',
    borderColor: theme.palette.secondary.main,
    backgroundColor: theme.palette.secondary.main,
    width: '10rem',
    marginTop: '0.8rem',
    [theme.breakpoints.up('md')]: {
      marginLeft: 0,
    },
  },
  separatorRight: {
    [theme.breakpoints.up('md')]: {
      marginLeft: 'auto',
      marginRight: 0,
    },
  },
  lightTitle: {
    fontWeight: 500,
  },
}));
