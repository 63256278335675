import { Container, Grid, Typography } from '@material-ui/core';
import React from 'react';
import useStyles from './styles';
import img1 from '../../../../assets/images/about-2.jpg';

const WhoWeAre = () => {
  const classes = useStyles();
  return (
    <Container className={classes.root}>
      <Grid container alignItems='center'>
        <Grid item xs={12} md={6} className={classes.textGroup}>
          <Typography
            variant='h5'
            component='h2'
            color='primary'
            className={classes.title}
          >
            ¿Quiénes Somos?
          </Typography>
          <hr className={classes.separator} />
          <Typography
            variant='body1'
            className={classes.textContent}
            color='primary'
          >
            La idea de miDoctor nació para que el paciente, independientemente
            de la ubicación de su domicilio, tenga acceso a atención de medicina
            primaria o familiar, acercando el médico a los barrios para
            solucionar sus problemas de salud.
            <br />
            <br />
            Somos un grupo de profesionales médicos y enfermeras contribuyendo
            en la tarea de llegar con consultorios médicos y enfermerías a la
            comunidad y mediante una plataforma digital, facilitar el acceso a
            las diferentes especialidades médicas a través de consultas online
            para cubrir las necesidades del paciente con una atención medica
            integral.
          </Typography>
        </Grid>
        <Grid item xs={12} md={6}>
          <img src={img1} alt='' className={classes.img} />
        </Grid>
      </Grid>
    </Container>
  );
};

export default WhoWeAre;
