import { useSelector } from 'react-redux';
import { Redirect, Route } from 'react-router-dom';
import DashboardLayout from '../layout/DashboardLayout/DashboardLayout';

const PatientRoute = ({ component: Component, ...rest }) => {
  const { authenticated, currentUser } = useSelector((state) => state.auth);

  // Redirect to login if not yet authenticated
  if (!authenticated) return <Redirect to='/login' />;

  // Redirect to resolve auth if account is still processing.
  if (!currentUser.activated) return <Redirect to='/resolve-auth' />;

  return (
    <Route
      {...rest}
      render={(props) => (
        <DashboardLayout role={currentUser.role}>
          <Component {...props} />
        </DashboardLayout>
      )}
    />
  );
};

export default PatientRoute;
