import { Backdrop, CircularProgress, Typography } from '@material-ui/core';
import useStyles from './styles';

const Loader = ({ content = 'Cargando...' }) => {
  const classes = useStyles();

  return (
    <Backdrop className={classes.backdrop} open={true}>
      <CircularProgress color='inherit' />
      <Typography>{content}</Typography>
    </Backdrop>
  );
};

export default Loader;
