import { Grid, Paper, Typography } from '@material-ui/core';
import { useSelector } from 'react-redux';
import clsx from 'clsx';
import useStyles from './styles';
import StatusCard from '../../../components/shared/dashboards/StatusCard';
import LabLastFiles from './LabLastFiles';
import { Link } from 'react-router-dom';

const LabHome = () => {
  const classes = useStyles();

  const { currentUser } = useSelector((state) => state.auth);

  return (
    <>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Typography variant='h6' gutterBottom color='primary'>
            Hola {currentUser.displayName},
          </Typography>
          <Typography variant='body1' gutterBottom>
            Empieza a compartir archivos con otros doctores{' '}
            <Link to='/lab-dashboard/my-files'>aquí</Link>.
          </Typography>
        </Grid>
        {/* <Grid item xs={12} md={4} lg={3}>
          <Paper className={clsx(classes.minHeight, classes.paper)}>
            <StatusCard label='Reservas (mes)' text={reservations.length} />
          </Paper>
        </Grid> */}
        <Grid item xs={12}>
          <Paper className={clsx(classes.minHeight, classes.paper)}>
            <LabLastFiles />
          </Paper>
        </Grid>
      </Grid>
    </>
  );
};

export default LabHome;
