import { combineReducers } from 'redux';
import asyncReducer from './async/asyncReducer';
import authReducer from './auth/authReducer';
import profileReducer from './profile/profileReducer';
import doctorReducer from './doctor/doctorReducer';

const rootReducer = combineReducers({
  auth: authReducer,
  async: asyncReducer,
  profile: profileReducer,
  doctor: doctorReducer,
});

export default rootReducer;
