import { Button, Grid, Typography } from '@material-ui/core';
import React from 'react';
import { Link } from 'react-router-dom';
import useStyles from './styles';

const SpecialistsSection = () => {
  const classes = useStyles();
  return (
    <div>
      <Grid className={classes.root}>
        <Grid item xs={12} md={6} className={classes.gridContent}>
          <Typography
            variant='h5'
            component='h2'
            color='primary'
            className={classes.title}
          >
            ¿Eres especialista?
          </Typography>
          <hr className={classes.separator} />
          <Typography
            variant='body1'
            color='primary'
            className={classes.sectionText}
          >
            Esta plataforma digital permitirá optimizar tu tiempo, estando
            disponible para tus pacientes de una manera más efectiva y segura.
            La encontrarás muy conveniente para dar un mejor servicio a tus
            pacientes.
          </Typography>

          <Button component={Link} to='/register' className={classes.buttonCta}>
            Regístrate ahora
          </Button>
        </Grid>
      </Grid>
    </div>
  );
};

export default SpecialistsSection;
