import { Typography } from '@material-ui/core';
import DoctorWeekPlanner from './DoctorWeekPlanner';

const DoctorSchedule = () => {
  return (
    <div>
      <Typography variant='h4' align='center' color='primary' gutterBottom>
        Organiza tu semana
      </Typography>
      <DoctorWeekPlanner />
    </div>
  );
};

export default DoctorSchedule;
