import React from 'react';
import ReactDOM from 'react-dom';
import 'react-toastify/dist/ReactToastify.min.css';
import './assets/styles/style.scss';
import HttpsRedirect from 'react-https-redirect';
import App from './App';
import { Provider } from 'react-redux';
import { configureStore } from './redux/store';

const store = configureStore();

ReactDOM.render(
  <HttpsRedirect>
    <Provider store={store}>
      <App />
    </Provider>
  </HttpsRedirect>,
  document.getElementById('root')
);
