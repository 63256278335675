const initialValues = {
  displayName: '',
  billingName: '',
  billingId: '',
  description: '',
  address1: '',
  address2: '',
  state: '',
  phone: '',
  email: '',
  password: '',
  acceptedTerms: false,
  taxCertificate: null,
  sedesCertificate: null,
};

// const initialValues = {
//   displayName: 'LabRats',
//   billingName: 'Lab Rats Inc',
//   billingId: '4654645',
//   description: 'Somos un laboratorio',
//   address1: 'chichapi 198',
//   address2: '',
//   state: 'Santa Cruz',
//   phone: '62122298',
//   email: 'labrat@sharklasers.com',
//   password: 'labrat',
//   taxCertificate: null,
//   sedesCertificate: null,
//   acceptedTerms: false,
// };

export default initialValues;
