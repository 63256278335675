import { Typography } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import useFirestoreCollection from '../../../hooks/useFirestoreCollection';
import { getDoctorBookings } from '../../../services/firestore';
import useStyles from './styles';

const NextBookings = ({ user }) => {
  const classes = useStyles();
  const [bookings, setBookings] = useState([]);

  useFirestoreCollection({
    query: () => getDoctorBookings(),
    data: (data) =>
      setBookings(
        data.filter(
          (d) => d.date.getTime() > new Date().getTime() - 3600 * 1000 // Substract one hour in case there is a running booking
        )
      ),
    deps: [],
  });

  return (
    <div className={classes.root}>
      <Typography component='h2' variant='h6' color='primary' gutterBottom>
        Próximas Consultas
      </Typography>
      {bookings.length ? (
        <Alert severity='success'>
          Tiene {bookings.length} consulta{bookings.length > 1 ? 's' : ''}{' '}
          agendada{bookings.length > 1 ? 's' : ''}.{' '}
          <Link to='/doctor-dashboard/my-bookings'>Ver</Link>
        </Alert>
      ) : (
        <Alert severity='info'>No tiene consultas próximas agendadas</Alert>
      )}
    </div>
  );
};

export default NextBookings;
