import { Button, Container, Grid, Paper, Typography } from '@material-ui/core';
import { useSelector } from 'react-redux';
import { Link, Redirect } from 'react-router-dom';
import { toast } from 'react-toastify';
import { sendVerificationEmail } from '../../../services/firebaseAuth';
import { displayFirebaseAuthError } from '../../../util';

const AwaitingEmailVerificationPage = () => {
  const { currentUser } = useSelector((state) => state.auth);

  if (currentUser?.emailVerified) return <Redirect to='/resolve-auth' />;

  const handleSendVerificationEmail = async () => {
    try {
      await sendVerificationEmail();
      toast.success('Email de verificación enviado.');
    } catch (error) {
      toast.error(displayFirebaseAuthError(error) || error.message);
    }
  };

  return (
    <Container maxWidth='sm' style={{ marginTop: '2rem' }}>
      <Paper style={{ padding: '2rem' }}>
        <Grid container>
          <Typography variant='h5' gutterBottom>
            Estamos aguardando la verificación de tu correo elećtronico.
          </Typography>
          <Typography variant='body1' gutterBottom>
            Por favor revisa tus buzones de inbox y spam.
          </Typography>
          <Button
            variant='contained'
            color='primary'
            onClick={handleSendVerificationEmail}
          >
            Enviar de Nuevo
          </Button>
          <Button component={Link} to='/' color='primary'>
            Volver al Inicio
          </Button>
        </Grid>
      </Paper>
    </Container>
  );
};

export default AwaitingEmailVerificationPage;
