import {
  CLEAR_DOCTORS,
  CREATE_DOCTOR,
  DELETE_DOCTOR,
  FETCH_DOCTORS,
  LISTEN_TO_SELECTED_DOCTOR,
  UPDATE_DOCTOR,
} from './doctorTypes';

const initialState = {
  doctors: [],
  moreDoctors: true,
  selectedDoctor: null,
};

const doctorReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case CREATE_DOCTOR:
      return {
        ...state,
        doctors: [...state.doctors, payload],
      };
    case UPDATE_DOCTOR:
      return {
        ...state,
        doctors: state.doctors.map((doctor) =>
          doctor.id === payload.id ? payload : doctor
        ),
      };
    case DELETE_DOCTOR:
      return {
        ...state,
        doctors: state.doctors.filter((doctor) => doctor.id !== payload),
      };
    case FETCH_DOCTORS:
      return {
        ...state,
        doctors: payload,
      };
    case LISTEN_TO_SELECTED_DOCTOR:
      return {
        ...state,
        selectedEvent: payload,
      };
    case CLEAR_DOCTORS:
      return {
        ...state,
        doctors: [],
        moreDoctors: true,
      };
    default:
      return state;
  }
};

export default doctorReducer;
