import { toast } from 'react-toastify';
import firebase, { auth } from '../config/firebase';
import { displayFirebaseAuthError } from '../util';
import { setNewLabProfileData, setNewUserProfileData } from './firestore';

export const registerInFirebase = async (creds, role = 'patient') => {
  try {
    const result = await auth.createUserWithEmailAndPassword(
      creds.email,
      creds.password
    );
    await result.user.updateProfile({
      displayName: creds.email.split('@')[0],
    });
    if (result.additionalUserInfo.isNewUser) {
      const newUser = {
        ...result.user,
        role,
        activated: false,
      };
      await setNewUserProfileData(newUser);
      await result.user.sendEmailVerification({
        url: `${process.env.REACT_APP_CONFIRMATION_EMAIL_REDIRECT}`,
      });
    }
  } catch (error) {
    throw error;
  }
};

export const registerLab = async (userData) => {
  try {
    const result = await auth.createUserWithEmailAndPassword(
      userData.email,
      userData.password
    );
    await result.user.updateProfile({
      displayName: userData.displayName,
    });
    if (result.additionalUserInfo.isNewUser) {
      console.log(userData);
      const newUser = {
        ...result.user,
        ...userData,
        role: 'laboratory',
        activated: false,
      };
      await setNewLabProfileData(newUser);
      await result.user.sendEmailVerification({
        url: `${process.env.REACT_APP_CONFIRMATION_EMAIL_REDIRECT}`,
      });
    }
  } catch (error) {
    throw error;
  }
};

export const signInWithEmail = (creds) => {
  return auth.signInWithEmailAndPassword(creds.email, creds.password);
};

export const socialLogin = async (
  selectedProvider,
  role = 'patient',
  history
) => {
  let provider;
  if (selectedProvider === 'facebook') {
    provider = new firebase.auth.FacebookAuthProvider();
  }

  if (selectedProvider === 'google') {
    provider = new firebase.auth.GoogleAuthProvider();
  }

  try {
    localStorage.setItem('loginRole', role);
    const result = await auth.signInWithRedirect(provider);

    /*if (result.additionalUserInfo.isNewUser) {
      const newUser = {
        ...result.user,
        role,
        emailVerified:
          selectedProvider === 'facebook' ? true : result.user.emailVerified,
        activated: role !== 'doctor', // automatically activate user if not a doctor
      };
      await setNewUserProfileData(newUser);
    }*/
  } catch (error) {
    localStorage.removeItem('loginRole');
    history.goBack();
    toast.error(displayFirebaseAuthError(error.code) || error.message);
  }
};

export const signOutFirebase = async () => {
  return await firebase.auth().signOut();
};

export const sendVerificationEmail = async () => {
  const user = firebase.auth().currentUser;
  try {
    await user.sendEmailVerification();
  } catch (error) {
    throw error;
  }
};
