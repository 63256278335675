import { Grid, Paper, Typography } from '@material-ui/core';
import { useSelector } from 'react-redux';
import useStyles from './styles';
import Notifications from './home/Notifications';
import UserStatus from './home/UserStatus';
import NextBookings from './home/NextBookings';

const DoctorHome = () => {
  const classes = useStyles();
  const { currentUser } = useSelector((state) => state.auth);

  return (
    <>
      <Grid container spacing={3}>
        <Grid item xs={12} md={8} lg={9}>
          <Paper className={classes.minHeight}>
            <Notifications user={currentUser} />
          </Paper>
        </Grid>
        {/* Recent Deposits */}
        <Grid item xs={12} md={4} lg={3}>
          <Paper className={classes.userStatus}>
            <UserStatus user={currentUser} />
          </Paper>
        </Grid>
        {/* Recent Orders */}
        <Grid item xs={12}>
          <Paper className={classes.minHeight}>
            <NextBookings user={currentUser} />
          </Paper>
        </Grid>
      </Grid>
    </>
  );
};

export default DoctorHome;
