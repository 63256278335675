import { makeStyles } from '@material-ui/core';

export default makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
  },
  content: {
    marginTop: theme.spacing(5),
    flexGrow: 1,
    minHeight: 'auto',
  },
}));
