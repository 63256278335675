import { useEffect, useState } from 'react';
import useStyles from './styles';
import useFirestoreCollection from '../../hooks/useFirestoreCollection';
import { getDoctorBookings } from '../../services/firestore';
import {
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core';
import NoteIcon from '@material-ui/icons/Note';
import Loader from '../../components/layout/Loader/Loader';
import { useSelector } from 'react-redux';
import moment from 'moment';
import { Link as RouterLink } from 'react-router-dom';
import BookingModal from '../../components/BookingModal/BookingModal';

const DoctorBookings = () => {
  const classes = useStyles();
  const [bookings, setBookings] = useState([]);
  const [open, setOpen] = useState(false);
  const [filterBy, setFilterBy] = useState('Todos');
  const [filteredData, setFilteredData] = useState([]);
  const { loading } = useSelector((state) => state.async);
  const [selectedBooking, setSelectedBooking] = useState();

  useFirestoreCollection({
    query: () => getDoctorBookings(),
    data: (bookings) => setBookings(bookings),
    deps: [],
  });

  const isInFuture = (item) => {
    return item.date.getTime() > new Date().getTime();
  };

  const hasNotStarted = (item) => {
    return (
      isInFuture(item) &&
      item.date.getTime() + 3600 * 1000 > new Date().getTime()
    );
  };

  const hasEnded = (item) => {
    return item.date.getTime() + 3600 * 1000 < new Date().getTime();
  };

  const isActiveSession = (item) => {
    return !hasEnded(item) && !hasNotStarted(item);
  };

  const handleChange = (e) => {
    setFilterBy(e.target.value);
  };

  useEffect(() => {
    if (bookings) {
      if (filterBy === 'Todos') {
        setFilteredData(bookings);
      } else if (filterBy === 'Pasados') {
        setFilteredData(bookings.filter(hasEnded));
      } else if (filterBy === 'Futuros') {
        setFilteredData(bookings.filter(isInFuture));
      }
    }
  }, [bookings, filterBy, setFilteredData]);

  const renderStatus = (booking) => {
    if (isActiveSession(booking))
      return <Typography style={{ color: 'green' }}>En Curso</Typography>;
    else if (hasNotStarted(booking))
      return <Typography>No ha iniciado</Typography>;
    return <Typography>Expirado</Typography>;
  };

  if (loading) return <Loader />;

  return (
    <div>
      <div className={classes.filter}>
        <FormControl className={classes.formControl}>
          <InputLabel>Filtrar por</InputLabel>
          <Select
            value={filterBy}
            onChange={handleChange}
            inputProps={{ 'aria-label': 'Without label' }}
          >
            <MenuItem value={'Todos'}>Todos</MenuItem>
            <MenuItem value={'Pasados'}>Pasados</MenuItem>
            <MenuItem value={'Futuros'}>Futuros</MenuItem>
          </Select>
        </FormControl>
      </div>
      <TableContainer>
        <Table className={classes.table} aria-label='simple table'>
          <TableHead className={classes.tableHead}>
            <TableRow>
              <TableCell>Paciente</TableCell>
              <TableCell align='right'>Fecha</TableCell>
              <TableCell align='right'>Estado</TableCell>
              <TableCell align='right'>Detalles</TableCell>
              <TableCell align='right'></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredData.map((booking) => (
              <TableRow key={booking.id}>
                <TableCell component='th' scope='row'>
                  {booking.patient.name} {booking.patient.lastName}
                </TableCell>
                <TableCell align='right'>
                  {moment(booking.date).format('MMMM D, yyyy h:mm a')}
                </TableCell>
                <TableCell className={classes.bookingStatus} align='right'>
                  {renderStatus(booking)}
                </TableCell>
                <TableCell align='right'>
                  <NoteIcon
                    onClick={() => {
                      setOpen(true);
                      setSelectedBooking(booking);
                    }}
                    className={classes.detailsBtn}
                  />
                </TableCell>
                <TableCell align='right'>
                  <Button
                    component={RouterLink}
                    size='small'
                    variant='contained'
                    color='primary'
                    to={`/doctor-dashboard/my-bookings/${booking.id}`}
                  >
                    Entrar
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>

        {!bookings.length && (
          <Typography align='center' className={classes.noBookingsText}>
            No tiene consultas
          </Typography>
        )}
        {bookings.length > 0 && !filteredData.length && (
          <Typography align='center' style={{ padding: '5rem 0' }}>
            No hay resultados para mostrar
          </Typography>
        )}
      </TableContainer>
      <BookingModal open={open} setOpen={setOpen} booking={selectedBooking} />
    </div>
  );
};

export default DoctorBookings;
