const ConsultorioBooking = () => {
  return (
    <div style={{ overflow: 'hidden' }}>
      <iframe
        className='Video-Frame'
        title='video call iframe'
        height='800px'
        width='100%'
        style={{
          overflow: 'hidden',
        }}
        src='https://www.in-bolivia.com/reservas-consultorio-online'
      ></iframe>
    </div>
  );
};

export default ConsultorioBooking;
