import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  root: {
    minHeight: '80vh',
    backgroundImage: `url(${
      process.env.PUBLIC_URL + '/assets/images/bg-hero.jpg'
    })`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    display: 'flex',
    alignItems: 'center',
    padding: '0 2rem',
    [theme.breakpoints.up('md')]: {
      padding: '0 5rem',
      minHeight: '95vh',
    },
  },
  title: {
    textTransform: 'uppercase',
    fontSize: '3rem',
    fontWeight: 800,
    color: 'white',
    textShadow: '1px 0px 7px #969696',
    [theme.breakpoints.up('md')]: {
      fontSize: '5rem',
    },
  },
  buttonCta: {
    color: 'white',
    textTransform: 'unset',
    fontSize: '1.5rem',
    marginTop: '1rem',
    borderRadius: 0,
    padding: '0 2rem',
  },
}));
