import { Typography } from '@material-ui/core';
import { Link } from 'react-router-dom';
import useStyles from './styles';

const UserStatus = ({ label, text, subText2, text2, subText, link }) => {
  const classes = useStyles();

  return (
    <>
      <Typography component='h2' variant='h6' color='primary' gutterBottom>
        {label}
      </Typography>
      <Typography component='p' variant='h4'>
        {text}
      </Typography>
      <Typography style={{ flex: 1 }} component='p' variant='subtitle2'>
        {subText}
      </Typography>
      {subText2 && (
        <>
          <Typography component='p' variant='h4'>
            {text2}
          </Typography>
          <Typography style={{ flex: 1 }} component='p' variant='subtitle2'>
            {subText2}
          </Typography>
        </>
      )}
      {link && (
        <div>
          <Link color='primary' to={link}>
            Ver más
          </Link>
        </div>
      )}
    </>
  );
};

export default UserStatus;
