import { IconButton, Typography } from '@material-ui/core';
import CustomModal from '../../components/layout/CustomModal/CustomModal';
import useStyles from './styles';
import NoteIcon from '@material-ui/icons/Note';

const AdminLabsModal = ({ open, setOpen, lab }) => {
  const classes = useStyles();

  return (
    <CustomModal
      title='Detalles'
      open={open}
      setOpen={setOpen}
      cancelText='Cerrar'
    >
      <div className={classes.modalRoot}>
        {lab && (
          <div className={classes.textContent}>
            <Typography variant='h6' color='primary' gutterBottom>
              Datos del Cliente
            </Typography>
            <Typography variant='body2'>
              <strong>Usuario</strong>: {lab.displayName}
            </Typography>
            <Typography variant='body2'>
              <strong>Razón Social</strong>: {lab.billingName}
            </Typography>
            <Typography variant='body2'>
              <strong>NIT</strong>: {lab.billingId}
            </Typography>
            <Typography variant='body2'>
              <strong>Dirección1</strong>: {lab.address1}
            </Typography>
            <Typography variant='body2'>
              <strong>Dirección2</strong>: {lab.address2}
            </Typography>
            <Typography variant='body2'>
              <strong>Departamento</strong>: {lab.state}
            </Typography>
            <Typography variant='body2'>
              <strong>Teléfono</strong>: {lab.phone}
            </Typography>
            <Typography variant='body2'>
              <strong>Email</strong>: {lab.email}
            </Typography>
            <div className={classes.doctorFile}>
              <Typography variant='body2'>
                <strong>Copia NIT: </strong>:
              </Typography>
              <IconButton
                size='small'
                component='a'
                aria-label='download'
                href={lab.taxCertificate}
                target='_blank'
                rel='noreferrer'
                download
                color='primary'
              >
                <NoteIcon />
              </IconButton>
            </div>
            <div className={classes.doctorFile}>
              <Typography variant='body2'>
                <strong>Certiciado SEDES: </strong>:
              </Typography>
              <IconButton
                size='small'
                component='a'
                aria-label='download'
                href={lab.sedesCertificate}
                target='_blank'
                rel='noreferrer'
                download
                color='primary'
              >
                <NoteIcon />
              </IconButton>
            </div>
          </div>
        )}
      </div>
    </CustomModal>
  );
};

export default AdminLabsModal;
