import { Button, TextField, Typography } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import useStyles from './styles';
import useFirestoreCollection from '../../../hooks/useFirestoreCollection';
import { useEffect, useState } from 'react';
import {
  fetchActiveDoctors,
  setFilePermissions,
} from '../../../services/firestore';
import CustomModal from '../../../components/layout/CustomModal/CustomModal';
import { toast } from 'react-toastify';

export default function LabFilesPermissions({ file, open, setOpen }) {
  const classes = useStyles();
  const [doctors, setDoctors] = useState([]);
  const [permissions, setPermissions] = useState([]);
  const [loading, setLoading] = useState(false);

  useFirestoreCollection({
    query: () => fetchActiveDoctors(),
    data: (doctors) =>
      setDoctors(
        doctors.map((doctor) => ({
          id: doctor.id,
          text: doctor.displayName,
        }))
      ),
    deps: [],
  });

  useEffect(() => {
    if (file?.permissions) {
      // check for existing permissions on doctors
      const existing = doctors.filter((doctor) =>
        file.permissions.includes(doctor.id)
      );

      setPermissions(existing);
    }
  }, [doctors, file]);

  const handleSave = async () => {
    setLoading(true);
    try {
      await setFilePermissions(
        file.id,
        permissions.map((p) => p.id) // only save Ids
      );
      setLoading(false);
      setOpen(false);
      toast.success('Permisos guardados');
    } catch (error) {
      console.log(error);
      setLoading(false);
      toast.error('Error guardando permisos');
    }
  };

  return (
    <CustomModal
      open={open}
      setOpen={setOpen}
      title='Otorgar Permiso a Doctores'
      actions={
        <Button
          color='primary'
          variant='contained'
          onClick={handleSave}
          disabled={loading}
        >
          Guardar
        </Button>
      }
    >
      <div className={classes.permissionsRoot}>
        <Typography gutterBottom className={classes.permissionsText}>
          Busque el nombre del doctor en la lista para otogarle los permisos
          para ver sus archivos.
        </Typography>
        <Autocomplete
          multiple
          id='tags-standard'
          options={doctors}
          getOptionLabel={(option) => option.text}
          value={permissions}
          className={classes.autocomplete}
          onChange={(e, newValue) => setPermissions(newValue)}
          renderInput={(params) => (
            <TextField
              {...params}
              variant='standard'
              label='Doctores'
              placeholder='Buscar Doctores...'
            />
          )}
        />
      </div>
    </CustomModal>
  );
}
