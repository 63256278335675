import { createMuiTheme } from '@material-ui/core';
import 'typeface-nunito';

const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#2F6189',
    },
    secondary: {
      main: '#66c7ca',
      dark: '#3ba6a9',
    },
    lightGreen: { main: '#b8d98d' },
    info: { main: '#999999' },
    background: {
      default: '#fdfdfd',
    },
  },
  typography: {
    fontFamily: ['Nunito', 'sans-serif'].join(','),
    fontSize: 16,
    h1: {
      fontFamily: ['Gotham Bold', 'sans-serif'].join(','),
    },
    h2: {
      fontFamily: ['Gotham Bold', 'sans-serif'].join(','),
    },
    h3: {
      fontFamily: ['Gotham Bold', 'sans-serif'].join(','),
    },
    h4: {
      fontFamily: ['Gotham Bold', 'sans-serif'].join(','),
    },
    h5: {
      fontFamily: ['Gotham Bold', 'sans-serif'].join(','),
    },
    h6: {
      fontFamily: ['Gotham Bold', 'sans-serif'].join(','),
    },
    button: {
      fontFamily: ['Gotham Bold', 'sans-serif'].join(','),
    },
  },
});

export default theme;
