/* eslint-disable import/no-anonymous-default-export */
export default {
  formId: 'bookingForm',
  formField: {
    name: {
      name: 'name',
      label: 'Nombre*',
      requiredErrorMsg: 'Campo requerido',
    },
    address1: {
      name: 'address1',
      label: 'Dirección*',
      requiredErrorMsg: 'Campo requerido',
    },
    city: {
      name: 'city',
      label: 'Ciudad*',
      requiredErrorMsg: 'Campo requerido',
    },
    state: {
      name: 'state',
      label: 'Departamento*',
      requiredErrorMsg: 'Campo requerido',
    },
    phone: {
      name: 'phone',
      label: 'Teléfono',
      requiredErrorMsg: 'Campo requerido',
    },
    googleLoc: {
      name: 'googleLoc',
      label: 'Google Iframe Link*',
      requiredErrorMsg: 'Campo requerido',
    },
    numRooms: {
      name: 'numRooms',
      label: 'Nro. de Consultorios*',
      requiredErrorMsg: 'Campo requerido',
    },
    isVisible: {
      name: 'isVisible',
      label: 'Habilitar para reserva',
    },
  },
};
