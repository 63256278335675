import { Button, CircularProgress } from '@material-ui/core';

const MyButton = ({ loading, label, ...props }) => {
  return (
    <Button {...props} style={{ minWidth: '100px' }}>
      {loading ? (
        <CircularProgress style={{ marginLeft: 5, color: 'white' }} size={30} />
      ) : (
        label
      )}
    </Button>
  );
};

export default MyButton;
