import {
  FormControl,
  IconButton,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@material-ui/core';
import { useState } from 'react';
import AssignmentIcon from '@material-ui/icons/Assignment';
import useFirestoreCollection from '../../hooks/useFirestoreCollection';
import {
  getAllDoctors,
  updateDoctorActiveStatus,
} from '../../services/firestore';
import useStyles from './styles';
import AdminUserModal from './AdminUserModal';

const AdminUsers = () => {
  const classes = useStyles();
  const [users, setUsers] = useState([]);
  const [open, setOpen] = useState(false);
  const [selectedDoctor, setSelectedDoctor] = useState();

  useFirestoreCollection({
    query: () => getAllDoctors(),
    data: (users) =>
      setUsers(
        users.filter(
          (user) =>
            // Only show doctors who have filled the registration form
            user.medicalLicenseId
        )
      ),
    deps: [],
  });

  const handleActiveUserChange = async (value, userId) => {
    await updateDoctorActiveStatus(value, userId);
  };

  const isProfileComplete = (user) => {
    return user && user.paymentInfo && user.schedule;
  };

  return (
    <>
      <TableContainer>
        <Table aria-label='simple table'>
          <TableHead className={classes.tableHead}>
            <TableRow>
              <TableCell>Usuario</TableCell>
              <TableCell>Nombre</TableCell>
              <TableCell>Apellidos</TableCell>
              {/* <TableCell>Título Médico</TableCell>
            <TableCell>Especialización</TableCell> */}

              <TableCell>Perfil Completo</TableCell>
              <TableCell>Detalles</TableCell>
              <TableCell align='right'>Activado</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {users
              .filter((user) => user.emailVerified)
              .map((user) => (
                <TableRow key={user.id}>
                  <TableCell>{user.displayName}</TableCell>
                  <TableCell>{user.name}</TableCell>
                  <TableCell>{user.lastName}</TableCell>
                  {/* <TableCell align='center'>
                  {user.academicRecords && (
                    <Link href={user.academicRecords} target='_blank'>
                      <InsertDriveFileIcon />
                    </Link>
                  )}
                </TableCell>
                <TableCell align='center'>
                  {user.specializationRecords && (
                    <Link href={user.specializationRecords} target='_blank'>
                      <InsertDriveFileIcon />
                    </Link>
                  )}
                </TableCell> */}
                  <TableCell>{isProfileComplete(user) ? 'Si' : 'No'}</TableCell>
                  <TableCell>
                    <IconButton
                      color='primary'
                      onClick={() => {
                        setOpen(true);
                        setSelectedDoctor(user);
                      }}
                    >
                      <AssignmentIcon />
                    </IconButton>
                  </TableCell>
                  <TableCell align='center'>
                    <FormControl style={{ minWidth: 100, float: 'right' }}>
                      <Select
                        value={user.activated}
                        onChange={(e) =>
                          handleActiveUserChange(e.target.value, user.id)
                        }
                        inputProps={{ 'aria-label': 'Without label' }}
                      >
                        <MenuItem value={true}>Si</MenuItem>
                        <MenuItem value={false}>No</MenuItem>
                      </Select>
                    </FormControl>
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
      <AdminUserModal open={open} setOpen={setOpen} doctor={selectedDoctor} />
    </>
  );
};

export default AdminUsers;
