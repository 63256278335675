import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  root: {
    marginBottom: '6rem',
    '& > div': {
      marginTop: '1rem',
    },
    '& h4': {
      color: theme.palette.lightGreen.main,
      fontWeight: 1000,
      '& span': {
        color: theme.palette.secondary.main,
      },
      [theme.breakpoints.up('md')]: {
        fontSize: '6rem',
      },
    },
    '& h5': {
      color: theme.palette.primary.main,
      fontWeight: 600,
      [theme.breakpoints.up('md')]: {
        fontSize: '3rem',
      },
    },
  },
}));
