import {
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from '@material-ui/core';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import useFirestoreCollection from '../../../hooks/useFirestoreCollection';
import {
  getReservationsForSucursal,
  getSucursales,
} from '../../../services/firestore';
import { Calendar, momentLocalizer } from 'react-big-calendar';

import moment from 'moment';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import { addMinutes } from 'date-fns-2';
import 'moment/locale/es';
import useStyles from './styles';
import CustomModal from '../../../components/layout/CustomModal/CustomModal';
moment.locale('es-ES');
const localizer = momentLocalizer(moment);

const AdminSucursales = () => {
  const [sucursales, setSucursales] = useState([]);
  const [reservations, setReservations] = useState([]);
  const [selectedSucursal, setSelectedSucursal] = useState('');
  const [selectedEvent, setSelectedEvent] = useState();
  const [open, setOpen] = useState(false);

  const [selectedDay, setSelectedDay] = useState(new Date());

  const classes = useStyles();

  useFirestoreCollection({
    query: () => getSucursales(),
    data: (data) => {
      setSucursales(data);
      if (!selectedSucursal && data.length) {
        setSelectedSucursal(data[0].id);
      }
    },
    deps: [],
  });

  useFirestoreCollection({
    query: () => getReservationsForSucursal(selectedSucursal),
    data: (reservations) => {
      setReservations(
        reservations.map((r) => ({
          title: `${r.name} ${r.lastName}`,
          phone: r.phone,
          email: r.email,
          start: r.date,
          end: addMinutes(r.date, 30),
        }))
      );
    },
    deps: [selectedSucursal],
  });

  return (
    <div>
      <Button
        component={Link}
        to='/admin-dashboard/sucursales/create'
        variant='contained'
        color='primary'
        style={{
          textAlign: 'center',
          display: 'block',
          width: 200,
          margin: '0 auto',
        }}
      >
        Crear Sucursal
      </Button>
      <div className={classes.sucursalSelect}>
        <FormControl>
          <InputLabel>Sucursal</InputLabel>
          <Select
            value={selectedSucursal || ''}
            onChange={(e) => setSelectedSucursal(e.target.value)}
          >
            {sucursales.map((s) => (
              <MenuItem key={s} value={s.id}>
                {s.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        {selectedSucursal && (
          <Button
            variant='outlined'
            component={Link}
            to={`/admin-dashboard/sucursales/edit/${selectedSucursal}`}
            size='small'
          >
            Editar
          </Button>
        )}
      </div>
      <div style={{ marginTop: '3rem' }}>
        <Calendar
          selectable
          localizer={localizer}
          events={reservations}
          startAccessor='start'
          endAccessor='end'
          date={selectedDay}
          defaultView='week'
          views={['month', 'week', 'day']}
          onNavigate={(date) => {
            setSelectedDay(date);
          }}
          style={{ height: 500 }}
          onSelectEvent={(event) => {
            setSelectedEvent(event);
            setOpen(true);
          }}
          messages={{
            next: 'Sig',
            previous: 'Ant',
            today: 'Hoy',
            month: 'Mes',
            week: 'Semana',
            day: 'Día',
          }}
        />
      </div>
      <CustomModal
        title='Información de la Reserva'
        open={open}
        setOpen={setOpen}
      >
        <div style={{ minWidth: 400 }}>
          <Typography variant='body1'>
            Nombre: {selectedEvent?.title}{' '}
          </Typography>
          <Typography variant='body1'>Tel: {selectedEvent?.phone} </Typography>
          <Typography variant='body1'>
            Email: {selectedEvent?.email}{' '}
          </Typography>
        </div>
      </CustomModal>
    </div>
  );
};

export default AdminSucursales;
