import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  root: {
    minHeight: '70vh',
    backgroundImage: `url(${
      process.env.PUBLIC_URL + '/assets/images/specialist-1.jpg'
    })`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundPosition: '80% 50%',
    display: 'flex',
    alignItems: 'center',
    padding: '0 1rem',
    [theme.breakpoints.up('md')]: {
      padding: '0 5rem',
      justifyContent: 'flex-end',
      backgroundPosition: '50% 10%',
    },
    textAlign: 'right',
    position: 'relative',
    '&::after': {
      content: '""',
      position: 'absolute',
      //background: 'red',
      top: 0,
      bottom: 0,
      left: 0,
      right: 0,
      //background: 'rgba(255,255,255,.3)',
      background:
        'linear-gradient(to right, rgba(255,255,255,0), rgba(255,255,255,0.2), #fdfdfd)',
      pointerEvents: 'none',
    },
  },
  title: {
    textTransform: 'uppercase',
    fontWeight: 800,
    fontStyle: 'italic',
    [theme.breakpoints.up('md')]: {
      fontSize: '3rem',
    },
  },
  sectionText: {
    marginTop: '1rem',
    fontSize: '1.3rem',
    fontWeight: '300',
    textShadow:
      '2px 8px px rgba(0,0,0,.2), 0px -5px 20px rgba(255,255,255,0.3)',
  },
  buttonCta: {
    backgroundColor: theme.palette.lightGreen.main,
    color: 'white',
    textTransform: 'unset',
    fontSize: '1.5rem',
    fontWeight: '600',
    marginTop: '1rem',
    borderRadius: 0,
    padding: '0 2rem',
  },
  separator: {
    border: '2px solid',
    borderColor: theme.palette.secondary.main,
    backgroundColor: theme.palette.secondary.main,
    width: '10rem',
    marginTop: '0.8rem',
    marginRight: 0,
  },
  gridContent: {
    zIndex: 1,
  },
}));
