import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  root: {
    background: `linear-gradient(to right, ${theme.palette.secondary.main}, ${theme.palette.primary.main})`,
  },
  gridContainer: {
    paddingTop: theme.spacing(10),
    paddingBottom: theme.spacing(10),
    [theme.breakpoints.up('md')]: {
      margin: '5rem auto',
      padding: '5rem',
    },
  },
  gridItem: {
    fontWeight: 300,
    textAlign: 'center',
    color: 'white',
    display: 'flex',
    justifyContent: 'center',
    alignContent: 'center',
    [theme.breakpoints.up('md')]: {
      fontSize: '1rem',
      padding: '1rem 2rem',
    },
  },
  textContent: {
    fontSize: '1.1rem',
    marginTop: '1rem',
  },
  contentWrapper: {
    maxWidth: '500px',
  },
  img: {
    width: '100%',
    margin: '2rem 0',
    [theme.breakpoints.up('md')]: {
      margin: 0,
    },
  },
  secondRow: {
    marginTop: '1.5rem',
  },
  title: {
    fontWeight: 800,
    fontStyle: 'italic',
    textAlign: 'center',
    [theme.breakpoints.up('md')]: {
      fontSize: '3rem',
    },
  },
  separator: {
    border: '2px solid white',
    backgroundColor: 'white',
    width: '80%',
    marginTop: '0.8rem',
  },
  vision: {
    marginTop: theme.spacing(6),
    [theme.breakpoints.up('md')]: {
      marginTop: 0,
    },
  },
}));
