export const getFileExtension = (filename) => {
  return filename.slice(((filename.lastIndexOf('.') - 1) >>> 0) + 2); // the -1 handle hidden files (files starting with .)
};

export const displayFirebaseAuthError = (errorCode) => {
  const errorMsgs = {
    'auth/email-already-in-use': 'Ya existe una cuenta asociada a este correo.',
    'auth/wrong-password': 'El email o contraseña es incorrecto.',
    'auth/popup-closed-by-user':
      'No se ha podido finalizar la autentificación.',
    'auth/user-not-found': 'No existe un usuario asociado con esta cuenta.',
    'auth/weak-password':
      'La contraseña debe contener un mínimo de 6 caracteres',
  };

  return errorMsgs[errorCode];
};

export const setWithExpiry = (key, value, ttl = 1.8e6) => {
  const now = new Date();

  // `item` is an object which contains the original value
  // as well as the time when it's supposed to expire
  const item = {
    value: value,
    expiry: now.getTime() + ttl,
  };
  localStorage.setItem(key, JSON.stringify(item));
};

export const getWithExpiry = (key) => {
  const itemStr = localStorage.getItem(key);

  // if the item doesn't exist, return null
  if (!itemStr) {
    return null;
  }

  const item = JSON.parse(itemStr);
  const now = new Date();

  // compare the expiry time of the item with the current time
  if (!item.expiry || now.getTime() > item.expiry) {
    // If the item is expired, delete the item from storage
    // and return null
    localStorage.removeItem(key);
    return null;
  }
  return item.value;
};

export const getCategoriesFromDoctors = (doctors) => {
  const categories = [];
  doctors.forEach((doctor) => {
    if (
      doctor.schedule &&
      doctor.paymentInfo &&
      doctor.activated &&
      !categories.includes(doctor.specialtyPrimary)
    ) {
      categories.push(doctor.specialtyPrimary);
      if (
        doctor.specialtySecondary &&
        !categories.includes(doctor.specialtySecondary)
      ) {
        categories.push(doctor.specialtySecondary);
      }
    }
  });

  // Add a "todos" (all) category to front, then sort rest by name
  return [
    { name: 'todos' },
    ...categories
      .map((cat) => ({ name: cat }))
      .sort((a, b) => (a.name < b.name ? -1 : 1)),
  ];
};
