import { Container, Grid, Typography } from '@material-ui/core';
import clsx from 'clsx';
import useStyles from './styles';

const Mision = () => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Container maxWidth='lg'>
        <Grid container className={classes.gridContainer}>
          <Grid
            item
            xs={12}
            md={6}
            className={clsx(classes.gridItem, classes.mision)}
          >
            <div className={classes.contentWrapper}>
              <Typography variant='h4' component='h2' className={classes.title}>
                Misión
              </Typography>
              <hr className={classes.separator} />
              <Typography variant='body1' className={classes.textContent}>
                Acercar el servicio médico primario y familiar, con mayor
                facilidad, a los que lo necesitan. Conectar el paciente a los
                especialistas mediante una plataforma digital para tener acceso
                a un diagnóstico oportuno y poder tener un tratamiento adecuado.
              </Typography>
            </div>
          </Grid>
          <Grid
            item
            xs={12}
            md={6}
            className={clsx(classes.gridItem, classes.vision)}
          >
            <div className={classes.contentWrapper}>
              <Typography variant='h4' component='h2' className={classes.title}>
                Visión
              </Typography>
              <hr className={classes.separator} />
              <Typography variant='body1' className={classes.textContent}>
                Llegar a todos los distritos de las ciudades de Bolivia con
                nuestros centros de consultorios y enfermerías de atención
                primaria y familiar y poder conectar a los pacientes de las
                regiones y provincias a los especialistas médicos de nuestro
                país.
              </Typography>
            </div>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};

export default Mision;
