import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import DoctorList from '../../components/doctorList/DoctorList';
import MainLayout from '../../components/layout/MainLayout/MainLayout';

const UnauthDoctorListPage = ({ history }) => {
  const { authenticated, currentUser } = useSelector((state) => state.auth);

  useEffect(() => {
    if (authenticated) {
      if (currentUser.role === 'patient' && currentUser.emailVerified) {
        history.push('/patient-dashboard');
      } else if (currentUser.role === 'doctor') {
        history.push('/doctor-dashboard');
      }
    }
  }, [authenticated, currentUser, history]);

  return (
    <MainLayout>
      <DoctorList />
    </MainLayout>
  );
};

export default UnauthDoctorListPage;
