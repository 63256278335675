import {
  Button,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core';
import GetAppIcon from '@material-ui/icons/GetApp';
import BackupIcon from '@material-ui/icons/Backup';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { uploadBookingFile } from '../../services/firebaseStorage';
import { addFileToBooking } from '../../services/firestore';
import CustomModal from '../layout/CustomModal/CustomModal';
import { v4 as uuidv4 } from 'uuid';
import useStyles from './styles';
import CircularProgressWithLabel from '../shared/CircularProgressWithLabel/CircularProgressWithLabel';

const BookingModal = ({ open, setOpen, booking }) => {
  const [uploading, setUploading] = useState(false);
  const [progress, setProgress] = useState(0);
  const { currentUser } = useSelector((state) => state.auth);
  const classes = useStyles();

  const handleUpload = (e) => {
    const file = e.target.files[0];
    if (file == null) return;
    setUploading(true);
    try {
      const id = uuidv4();

      const uploadTask = uploadBookingFile(booking.id, file);
      uploadTask.on(
        'state_changed',
        (snapshot) => {
          const progress =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          setProgress(progress);
          console.log('Upload is ' + progress + '% done');
        },
        (error) => {
          toast.error(error.message);
        },
        () => {
          uploadTask.snapshot.ref.getDownloadURL().then(async (downloadURL) => {
            await addFileToBooking(booking.id, {
              id,
              name: file.name,
              path: downloadURL,
            });

            setUploading(false);
            toast.success('Archivo cargado con éxito');
          });
        }
      );
    } catch (error) {
      toast.error('Error cargando el archivo');
      console.log(error);
    }
  };

  return (
    <CustomModal
      title='Detalles'
      open={open}
      setOpen={setOpen}
      cancelText='Cerrar'
    >
      {booking && (
        <div className={classes.root}>
          <Typography color='primary' gutterBottom>
            Paciente
          </Typography>
          <div className={classes.textContent}>
            <Typography>
              {booking.patient.name} {booking.patient.lastName}
            </Typography>
            <Typography>{booking.patient.city}</Typography>
            <Typography>{booking.patient.state}</Typography>
            <Typography>Tel: {booking.patient.phone}</Typography>
            <Typography>Síntomas: {booking.patient.symptoms}</Typography>
          </div>
          <Typography
            color='primary'
            gutterBottom
            className={classes.textHeading}
          >
            Doctor
          </Typography>
          <div className={classes.textContent}>
            <Typography>{booking.doctor.displayName}</Typography>
          </div>
          {booking.files && (
            <TableContainer>
              <Table className={classes.table} aria-label='simple table'>
                <TableHead className={classes.tableHead}>
                  <TableRow>
                    <TableCell component='th'>Archivo</TableCell>
                    <TableCell component='th' align='right'>
                      Descargar
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody className={classes.tableBody}>
                  {booking.files.map((file) => (
                    <TableRow key={file.id}>
                      <TableCell>{file.name}</TableCell>
                      <TableCell align='right'>
                        <IconButton
                          component='a'
                          aria-label='download'
                          size='medium'
                          href={file.path}
                          target='_blank'
                          rel='noreferrer'
                          download
                          color='primary'
                        >
                          <GetAppIcon className={classes.downloadIcon} />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          )}

          {currentUser.role === 'doctor' && (
            <div style={{ textAlign: 'center' }}>
              <Button
                variant='contained'
                color='primary'
                component='label'
                aria-label='upload'
                disabled={uploading}
                startIcon={!uploading ? <BackupIcon /> : null}
                size='small'
                className={classes.fileBtn}
              >
                {uploading ? (
                  <CircularProgressWithLabel
                    value={progress}
                    style={{ color: 'white' }}
                  />
                ) : (
                  'Subir Archivo'
                )}
                <input
                  type='file'
                  onChange={handleUpload}
                  style={{ opacity: 0, position: 'absolute', left: '-9999px' }}
                />
              </Button>
            </div>
          )}
        </div>
      )}
    </CustomModal>
  );
};

export default BookingModal;
