import { Button, Typography } from '@material-ui/core';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import MyButton from '../../../components/shared/form/MyButton';
import { uploadProfilePhoto } from '../../../services/firebaseStorage';
import { updateUserProfile } from '../../../services/firestore';
import useStyles from './styles';

const DoctorProfileImage = () => {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const { currentUser } = useSelector((state) => state.auth);

  const handlePhotoUpload = (file) => {
    setLoading(true);
    const uploadTask = uploadProfilePhoto(file);
    uploadTask.on(
      'state_changed',
      (snapshot) => {
        const progress =
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        console.log('Upload is ' + progress + '% done');
      },
      (error) => {
        toast.error(error.message);
      },
      () => {
        uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
          updateUserProfile({
            photoURL: downloadURL,
          })
            .then(() => {
              setLoading(false);
            })
            .catch((error) => {
              toast.error(error.message);
              setLoading(false);
            });
        });
      }
    );
  };

  return (
    <>
      <Typography variant='h5' color='primary' align='center' gutterBottom>
        Foto del Perfil
      </Typography>
      <div className={classes.profilePhotoWrapper}>
        <img src={currentUser.photoURL} height='100%' alt='user' />
      </div>
      <Button
        className={classes.photoBtn}
        component='label'
        variant='contained'
        color='primary'
      >
        {loading ? 'Guardando...' : 'Cambiar'}
        <input
          name='photoURL'
          type='file'
          accept='image/png, image/jpeg'
          onChange={(e) => handlePhotoUpload(e.currentTarget.files[0])}
          hidden
        />
      </Button>
    </>
  );
};

export default DoctorProfileImage;
