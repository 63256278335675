import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  root: {
    '& div:nth-child(2)': {
      background: theme.palette.secondary.main,
    },
  },
  itemWrapper: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    flexDirection: 'column',
    marginTop: '2rem',
    padding: '2rem',
    [theme.breakpoints.up('md')]: {
      padding: '4rem',
    },
  },
  midItemWrapper: {
    '& hr': {
      border: '2px solid #2F6189',
    },
    '& h4, p': {
      color: 'white',
    },
  },
  icon: {
    height: '7rem',
  },
  separator: {
    width: '10rem',
    marginTop: '0.8rem',
    border: '2px solid',
    borderColor: theme.palette.secondary.main,
    backgroundColor: theme.palette.secondary.main,
  },
  midSeparator: {
    borderColor: theme.palette.primary.main,
    backgroundColor: theme.palette.primary.main,
  },
  sectionTitle: {
    fontSize: '2rem',
    fontWeight: 600,
    textAlign: 'center',
  },
  sectionText: {
    marginTop: '1rem',
    fontSize: '1.3rem',
  },
  link: {
    textDecoration: 'none',
  },
}));
