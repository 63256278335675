import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  itemPaper: {
    padding: theme.spacing(2),
    margin: '1rem auto',
    boxShadow: 'none',
    borderBottom: '1px solid #bbb',
  },
  itemGrid: {
    textAlign: 'center',
    [theme.breakpoints.up('sm')]: {
      textAlign: 'left',
    },
  },
  filterPaper: {
    padding: theme.spacing(1),
    '& > *': {
      textTransform: 'capitalize',
    },
  },
  imageWrapper: {
    margin: '0 auto',
  },
  image: {
    width: 128,
    height: 128,
  },
  specialties: {
    textTransform: 'capitalize',
    '& > *': {
      margin: theme.spacing(0.2),
      color: 'white',
      '&:hover': { background: theme.palette.secondary.dark },
    },
  },
  state: {
    textTransform: 'capitalize',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    margin: '0.6rem 0',
    [theme.breakpoints.up('sm')]: {
      justifyContent: 'flex-start',
    },
  },
  btnGroup: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    '& > *': {
      width: 'fit-content',
      fontSize: '0.9rem',
      letterSpacing: '1px',
    },
    [theme.breakpoints.up('sm')]: {
      alignItems: 'flex-end',
    },
  },
  rate: {
    fontSize: '1.2rem',
    marginBottom: '1rem',
  },
  noResults: {
    padding: '2rem 0',
  },
}));

export default useStyles;
