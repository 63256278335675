import {
  FormControl,
  IconButton,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core';
import { useState } from 'react';
import AssignmentIcon from '@material-ui/icons/Assignment';
import useFirestoreCollection from '../../hooks/useFirestoreCollection';
import {
  getLaboratories,
  updateDoctorActiveStatus,
} from '../../services/firestore';
import useStyles from './styles';
import AdminLabsModal from './AdminLabsModal';

const AdminLabs = () => {
  const classes = useStyles();
  const [users, setUsers] = useState([]);
  const [open, setOpen] = useState(false);
  const [selectedLab, setSelectedLab] = useState();

  useFirestoreCollection({
    query: () => getLaboratories(),
    data: (users) => setUsers(users),
    deps: [],
  });

  const handleActiveUserChange = async (value, userId) => {
    await updateDoctorActiveStatus(value, userId);
  };

  return (
    <>
      <TableContainer>
        <Table aria-label='simple table'>
          <TableHead className={classes.tableHead}>
            <TableRow>
              <TableCell>Nombre</TableCell>
              <TableCell>Razón Social</TableCell>
              <TableCell>NIT</TableCell>
              {/* <TableCell>Título Médico</TableCell>
            <TableCell>Especialización</TableCell> */}

              <TableCell># Archivos</TableCell>
              <TableCell>Detalles</TableCell>
              <TableCell align='right'>Activado</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {users
              .filter((user) => user.emailVerified)
              .map((user) => (
                <TableRow key={user.id}>
                  <TableCell>{user.displayName}</TableCell>
                  <TableCell>{user.billingName}</TableCell>
                  <TableCell>{user.billingId}</TableCell>
                  {/* <TableCell align='center'>
                  {user.academicRecords && (
                    <Link href={user.academicRecords} target='_blank'>
                      <InsertDriveFileIcon />
                    </Link>
                  )}
                </TableCell>
                <TableCell align='center'>
                  {user.specializationRecords && (
                    <Link href={user.specializationRecords} target='_blank'>
                      <InsertDriveFileIcon />
                    </Link>
                  )}
                </TableCell> */}
                  <TableCell>{user.files?.length || 0}</TableCell>
                  <TableCell>
                    <IconButton
                      color='primary'
                      onClick={() => {
                        setOpen(true);
                        setSelectedLab(user);
                      }}
                    >
                      <AssignmentIcon />
                    </IconButton>
                  </TableCell>
                  <TableCell align='center'>
                    <FormControl style={{ minWidth: 100, float: 'right' }}>
                      <Select
                        value={user.activated}
                        onChange={(e) =>
                          handleActiveUserChange(e.target.value, user.id)
                        }
                        inputProps={{ 'aria-label': 'Without label' }}
                      >
                        <MenuItem value={true}>Si</MenuItem>
                        <MenuItem value={false}>No</MenuItem>
                      </Select>
                    </FormControl>
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
        {!users.length && (
          <Typography align='center' style={{ padding: '5rem' }}>
            No hay usuarios para mostrar
          </Typography>
        )}
      </TableContainer>
      <AdminLabsModal open={open} setOpen={setOpen} lab={selectedLab} />
    </>
  );
};

export default AdminLabs;
