import { makeStyles } from '@material-ui/core';

export default makeStyles((theme) => ({
  table: {
    marginTop: '2rem',
  },
  tableContainer: {
    marginTop: theme.spacing(5),
  },
  title: {
    marginTop: theme.spacing(3),
  },
  tableHead: {
    '& *': {
      fontWeight: 600,
    },
  },
  tableTitle: {
    fontWeight: 600,
  },
}));
