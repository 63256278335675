import {
  Avatar,
  Button,
  Chip,
  Grid,
  Paper,
  Typography,
} from '@material-ui/core';
import EventAvailableIcon from '@material-ui/icons/EventAvailable';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import { Link, Link as RouterLink } from 'react-router-dom';
import useStyles from './styles.js';

const DoctorListItem = ({ doctor, authenticated, currentUser, filterData }) => {
  const classes = useStyles();

  return (
    <Paper className={classes.itemPaper} square elevation={1}>
      <Grid container spacing={2} className={classes.itemGrid}>
        <Grid item className={classes.imageWrapper}>
          <Avatar
            alt='Dr House'
            src={doctor.photoURL}
            className={classes.image}
            style={{
              width: 128,
              height: 128,
            }}
          />
        </Grid>
        <Grid item xs={12} sm container>
          <Grid item xs container direction='column' spacing={2}>
            <Grid item xs>
              <Typography
                component={Link}
                to={
                  authenticated && currentUser?.role === 'patient'
                    ? `/patient-dashboard/doctors/${doctor.id}`
                    : authenticated && currentUser?.role === 'doctor'
                    ? `/doctor-dashboard/doctors/${doctor.id}`
                    : `/doctores/${doctor.id}`
                }
                gutterBottom
                variant='h6'
                color='primary'
                style={{ textDecoration: 'none' }}
              >
                {doctor.displayName}
              </Typography>
              <div className={classes.specialties}>
                <Chip
                  className='doctorListItem__specialty'
                  label={doctor.specialtyPrimary}
                  clickable
                  color='secondary'
                  onClick={() => filterData(doctor.specialtyPrimary)}
                />
                {doctor.specialtySecondary && (
                  <Chip
                    label={doctor.specialtySecondary}
                    clickable
                    color='secondary'
                    onClick={() => filterData(doctor.specialtySecondary)}
                  />
                )}
              </div>

              <Typography
                variant='body2'
                component='p'
                color='textSecondary'
                className={classes.state}
              >
                <LocationOnIcon fontSize='small' style={{ color: '#d50000' }} />
                {doctor.state}
              </Typography>
            </Grid>
          </Grid>
          <Grid item xs={12} sm className={classes.btnGroup}>
            {currentUser?.role !== 'doctor' && (
              <Button
                variant='contained'
                component={RouterLink}
                color='primary'
                startIcon={<EventAvailableIcon />}
                to={
                  authenticated
                    ? `/patient-dashboard/booking/${doctor.id}`
                    : '/login'
                }
              >
                Agendar Cita
              </Button>
            )}
          </Grid>
        </Grid>
      </Grid>
    </Paper>
  );
};

export default DoctorListItem;
