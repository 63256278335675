import * as Yup from 'yup';
const reqMsg = 'Campo requerido';

export const initialValues = {
  description: '',
  file: null,
};

export const validationSchema = Yup.object({
  description: Yup.string(), //.required('Debes dejar un descripción'),
  file: Yup.string().required('Debes adjuntar un archivo'),
});
