import React from 'react';
import useStyles from './styles';
import icon1 from '../../../../assets/images/hiw-icon-1.png';
import icon2 from '../../../../assets/images/hiw-icon-2.png';
import icon3 from '../../../../assets/images/hiw-icon-3.png';
import icon4 from '../../../../assets/images/hiw-icon-4.png';
import icon5 from '../../../../assets/images/hiw-icon-5.png';
import icon6 from '../../../../assets/images/hiw-icon-6.png';
import { Container, Grid, Typography } from '@material-ui/core';

const HowItWorks = () => {
  const classes = useStyles();
  return (
    <Container maxWidth='lg' className={classes.root} id='howitworks'>
      <Typography
        variant='h2'
        align='center'
        color='primary'
        className={classes.title}
      >
        ¿Cómo funciona?
      </Typography>
      <Grid container justify='center' alignItems='flex-start' direction='row'>
        <Grid item xs={12} sm={6} md={4} className={classes.gridItem}>
          <img src={icon1} alt='' className={classes.img} />
          <Typography color='primary'>Regístrate</Typography>
        </Grid>
        <Grid item xs={12} sm={6} md={4} className={classes.gridItem}>
          <img src={icon2} alt='' className={classes.img} />
          <Typography color='primary'>Busca un especialista</Typography>
        </Grid>
        <Grid item xs={12} sm={6} md={4} className={classes.gridItem}>
          <img src={icon3} alt='' className={classes.img} />
          <Typography color='primary'>Reserva una cita</Typography>
        </Grid>
        <Grid item xs={12} sm={6} md={4} className={classes.gridItem}>
          <img src={icon4} alt='' className={classes.img} />
          <Typography color='primary'>Sube tus datos médicos</Typography>
        </Grid>
        <Grid item xs={12} sm={6} md={4} className={classes.gridItem}>
          <img src={icon5} alt='' className={classes.img} />
          <Typography color='primary'>Elige tu forma de pago</Typography>
        </Grid>
        <Grid item xs={12} sm={6} md={4} className={classes.gridItem}>
          <img src={icon6} alt='' className={classes.img} />
          <Typography color='primary'>
            Ingresa con un click a tu consulta
          </Typography>
        </Grid>
      </Grid>
    </Container>
  );
};
export default HowItWorks;
