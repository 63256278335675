import DoctorFilters from './DoctorFilters';
import DoctorListItem from './DoctorListItem';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  fetchActiveDoctors,
  clearDoctors,
} from '../../redux/doctor/doctorActions';
import DoctorListSkeleton from './DoctorListSkeleton.jsx';
import { Paper, Typography } from '@material-ui/core';
import { getCategoriesFromDoctors } from '../../util';
import useStyles from './styles.js';

const DoctorList = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [filteredData, setFilteredData] = useState([]);
  const [categories, setCategories] = useState([]);
  const [selected, setSelected] = useState('todos');
  const { loading } = useSelector((state) => state.async);
  const { doctors } = useSelector((state) => state.doctor);
  const { authenticated, currentUser } = useSelector((state) => state.auth);

  useEffect(() => {
    dispatch(fetchActiveDoctors());
    return () => {
      dispatch(clearDoctors());
    };
  }, [dispatch]);

  useEffect(() => {
    if (doctors.length > 0) {
      setCategories(getCategoriesFromDoctors(doctors));
      setFilteredData(doctors);
    }
  }, [doctors]);

  const handleFilterData = (filterBy) => {
    setSelected(filterBy);
    if (filterBy === 'todos') {
      setFilteredData(doctors);
    } else {
      setFilteredData(
        doctors.filter(
          (doctor) =>
            doctor.specialtyPrimary === filterBy ||
            doctor.specialtySecondary === filterBy
        )
      );
    }
  };

  const handleFilterBySearch = (searchTerm) => {
    if (searchTerm) {
      // TODO: Filter on active doctors
      setFilteredData(
        doctors.filter(
          (doctor) =>
            doctor.specialtyPrimary
              .toLowerCase()
              .includes(searchTerm.toLowerCase()) ||
            doctor?.specialtySecondary
              .toLowerCase()
              .includes(searchTerm.toLowerCase()) ||
            doctor?.displayName
              .toLowerCase()
              .includes(searchTerm.toLowerCase()) ||
            doctor?.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
            doctor?.lastName.toLowerCase().includes(searchTerm.toLowerCase())
        )
      );
    } else {
      setFilteredData(doctors);
    }
  };

  return loading ? (
    <DoctorListSkeleton />
  ) : (
    <>
      <DoctorFilters
        filterData={handleFilterData}
        searchByTerm={handleFilterBySearch}
        selected={selected}
        categories={categories}
      />
      <Paper className={classes.listPaper} elevation={0}>
        {filteredData.map((doctor) => (
          <DoctorListItem
            key={doctor.id}
            doctor={doctor}
            authenticated={authenticated}
            currentUser={currentUser}
            filterData={handleFilterData}
          />
        ))}
        {!filteredData.length && (
          <Typography
            variant='body1'
            align='center'
            className={classes.noResults}
          >
            No hay resultados
          </Typography>
        )}
      </Paper>
    </>
  );
};

export default DoctorList;
