import * as Yup from 'yup';
import moment from 'moment';
import bookingFormModel from './bookingFormModel';
const {
  formField: { date, name, lastName, symptoms, address1, city, state, phone },
} = bookingFormModel;

// eslint-disable-next-line import/no-anonymous-default-export
export default [
  Yup.object({
    [date.name]: Yup.string().required(`${date.requiredErrorMsg}`),
  }),
  Yup.object({
    [name.name]: Yup.string().required(`${name.requiredErrorMsg}`),
    [lastName.name]: Yup.string().required(`${lastName.requiredErrorMsg}`),
    [symptoms.name]: Yup.string().required(`${symptoms.requiredErrorMsg}`),
    [address1.name]: Yup.string().required(`${address1.requiredErrorMsg}`),
    [city.name]: Yup.string().required(`${city.requiredErrorMsg}`),
    [state.name]: Yup.string().required(`${state.requiredErrorMsg}`),
    [phone.name]: Yup.string().required(`${state.requiredErrorMsg}`),
  }),
];
