import { Box, Container, Grid, Typography } from '@material-ui/core';
import clsx from 'clsx';
import img1 from '../../../../assets/images/about-3.jpg';
import img2 from '../../../../assets/images/about-4.jpg';
import useStyles from './styles';

const OurServices = () => {
  const classes = useStyles();

  return (
    <Container maxWidth='lg' className={classes.root}>
      <Grid container alignItems='center'>
        <Box clone order={{ xs: 2, sm: 2, md: 1 }}>
          <Grid item xs={12} md={7}>
            <img src={img1} alt='' className={classes.img} />
          </Grid>
        </Box>
        <Box clone order={{ xs: 1, sm: 1, md: 2 }}>
          <Grid item xs={12} md={5}>
            <div className={classes.firstRowTextContent}>
              <Typography
                variant='h3'
                color='primary'
                className={clsx(classes.sectionTitle, classes.alignRight)}
              >
                <span className={classes.lightTitle}>Nuestros</span>
                <br />
                Consultorios
              </Typography>
              <hr className={clsx(classes.separator, classes.separatorRight)} />
              <Typography
                variant='body1'
                className={classes.textContent}
                color='primary'
              >
                Están abiertos a la población que lo necesite, dando atención
                médica con las herramientas y los recursos necesarios según las
                normas en materia de salud. El paciente debe apersonarse a su
                centro miDoctor más cercano o hacer una reserva a través de
                nuestra página web.
              </Typography>
            </div>
          </Grid>
        </Box>
      </Grid>

      <Grid container className={classes.secondRow} alignItems='center'>
        <Grid item xs={12} md={5}>
          <div className={classes.secondRowTextContent}>
            <Typography
              variant='h3'
              color='primary'
              className={classes.sectionTitle}
            >
              <span className={classes.lightTitle}>Nuestros</span>
              <br />
              Doctores
            </Typography>
            <hr className={classes.separator} />

            <Typography
              variant='body1'
              className={`${classes.textContent}`}
              color='primary'
            >
              Nuestros doctores en medicina familiar y primaria son
              profesionales certificados con matrícula en el colegio médico de
              Bolivia y registrados en el SEDES de cada departamento. Los
              especialistas médicos en nuestra plataforma cuentan con su título
              de especialidad y tienen experiencia en su materia.
            </Typography>
          </div>
        </Grid>
        <Grid item xs={12} md={7}>
          <img src={img2} alt='' className={classes.img} />
        </Grid>
      </Grid>
    </Container>
  );
};

export default OurServices;
