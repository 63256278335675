import { Button, Grid, Typography } from '@material-ui/core';
import React from 'react';
import useStyles from './styles';

const HeroSection = () => {
  const classes = useStyles();
  return (
    <div>
      <Grid className={classes.root}>
        <Grid item xs={12} md={8}>
          <Typography variant='h1' component='h1' className={classes.title}>
            Tu salud
            <br />
            Ahora más fácil
          </Typography>
          <Button
            className={classes.buttonCta}
            variant='contained'
            color='secondary'
            size='large'
            type='submit'
            component='a'
            href='#products'
          >
            ver más
          </Button>
        </Grid>
      </Grid>
    </div>
  );
};

export default HeroSection;
