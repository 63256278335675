import { Button } from '@material-ui/core';
import FacebookIcon from '@material-ui/icons/Facebook';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import GoogleIcon from '../../../assets/icons/google.svg';
import { socialLogin } from '../../../services/firebaseAuth';

import useStyles from './styles';

const SocialLogin = ({ role }) => {
  const classes = useStyles();
  const history = useHistory();

  const handleSocialLogin = (provider) => {
    socialLogin(provider, role, history);
  };

  return (
    <>
      <Button
        size='large'
        variant='contained'
        fullWidth
        startIcon={<FacebookIcon />}
        className={`${classes.socialBtn} ${classes.socialBtnFacebook}`}
        onClick={() => handleSocialLogin('facebook')}
      >
        Entrar con Facebook
      </Button>
      <Button
        size='large'
        variant='contained'
        fullWidth
        className={`${classes.socialBtn} ${classes.socialBtnGoogle}`}
        onClick={() => handleSocialLogin('google')}
      >
        <img src={GoogleIcon} alt='google icon' height='18px' />
        Entrar con Google
      </Button>
    </>
  );
};

export default SocialLogin;
