import * as Yup from 'yup';
import consultorioFormModel from './consultorioFormModel';
const {
  formField: { sucursal, date, name, lastName, email, phone },
} = consultorioFormModel;

// eslint-disable-next-line import/no-anonymous-default-export
export default [
  Yup.object({
    [sucursal.name]: Yup.string().required(`${sucursal.requiredErrorMsg}`),
    [date.name]: Yup.string().required(`${date.requiredErrorMsg}`),
  }),
  Yup.object({
    [name.name]: Yup.string().required(`${name.requiredErrorMsg}`),
    [lastName.name]: Yup.string().required(`${lastName.requiredErrorMsg}`),
    [email.name]: Yup.string().required(`${email.requiredErrorMsg}`),
    [phone.name]: Yup.string().required(`${phone.requiredErrorMsg}`),
  }),
];
