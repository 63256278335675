import React, { useCallback, useEffect, useState } from 'react';
import Tippy from '@tippyjs/react';

import es from 'date-fns/locale/es';
import 'resize-observer-polyfill/dist/ResizeObserver.global';
import {
  DefaultEventRootComponent,
  TimeGridScheduler,
  SchedulerContext,
  classes,
} from '@remotelock/react-week-scheduler';
import '@remotelock/react-week-scheduler/index.css';
import DeleteIcon from '@material-ui/icons/Delete';

import { startOfWeek } from 'date-fns';
import { Button } from '@material-ui/core';
import { saveDoctorSchedule } from '../../services/firestore';
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';
import { Prompt } from 'react-router';

const EventRoot = React.forwardRef(
  ({ handleDelete, disabled, ...props }, ref) => {
    return (
      <Tippy
        arrow
        interactive
        animation='fade'
        hideOnClick={false}
        className='tooltip'
        content={
          <button disabled={disabled} onClick={handleDelete}>
            <DeleteIcon className='icon' />
            Borrar
          </button>
        }
      >
        <DefaultEventRootComponent
          handleDelete={handleDelete}
          disabled={disabled}
          {...props}
          ref={ref}
        />
      </Tippy>
    );
  }
);

const DoctorWeekPlanner = () => {
  const [schedule, setSchedule] = useState([]);
  const [isEdited, setIsEdited] = useState(false);
  const { currentUser } = useSelector((state) => state.auth);

  useEffect(() => {
    if (currentUser.schedule) {
      const defaultSchedule = Object.keys(currentUser.schedule).map((key) => [
        currentUser.schedule[key][0].toDate(),
        currentUser.schedule[key][1].toDate(),
      ]);
      setSchedule(defaultSchedule);
      setIsEdited(false);
    }
  }, [currentUser.schedule]);

  const handleSetSchedule = useCallback((schedule) => {
    setIsEdited(true);
    setSchedule(schedule);
  }, []);

  const handleSave = async () => {
    try {
      setIsEdited(false);
      await saveDoctorSchedule(schedule);
      toast.success('Tus cambios han sido guardados');
    } catch (error) {
      console.log(error);
      toast.error('Error guardando tus datos');
    }
  };

  return (
    <>
      <Prompt
        when={isEdited}
        message='Tienes cambios sin guardar. ¿Estás seguro que quieres salir?'
      />
      <SchedulerContext.Provider value={{ locale: es }}>
        <div
          className='root'
          style={{
            width: '100%',
            height: '600px',
            '--cell-height': '20px',
            '--cell-width': '20px',
          }}
        >
          <TimeGridScheduler
            classes={classes}
            style={{ width: '100%', height: '100%' }}
            originDate={startOfWeek(new Date('2020-01-01'), {
              weekStartsOn: 1,
            })}
            schedule={schedule}
            onChange={handleSetSchedule}
            visualGridVerticalPrecision={30}
            verticalPrecision={30}
            cellClickPrecision={60}
            eventRootComponent={EventRoot}
          />
        </div>
        <Button
          variant='contained'
          color='primary'
          disabled={!isEdited}
          onClick={handleSave}
          style={{ marginTop: '1rem' }}
        >
          Guardar
        </Button>
      </SchedulerContext.Provider>
    </>
  );
};

export default DoctorWeekPlanner;
