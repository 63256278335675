import { Grid, Divider as MuiDivider } from '@material-ui/core';

const MyDivider = ({ children, ...props }) => (
  <Grid container alignItems='center' spacing={3} {...props}>
    <Grid item xs>
      <MuiDivider />
    </Grid>
    <Grid item>{children}</Grid>
    <Grid item xs>
      <MuiDivider />
    </Grid>
  </Grid>
);

export default MyDivider;
