import { getYear } from 'date-fns';
import { getDay, getHours } from 'date-fns-2';
import MyDateInput from '../../../../components/shared/form/MyDateInput';

const DateSelectionForm = ({ schedule, prevBookings }) => {
  const filterDate = (date) => {
    if (schedule) {
      const currentDay = getDay(date);

      // Map through time ranges. Only first item is needed to calculate what day it is
      const days = [
        ...new Set(
          Object.keys(schedule).map((key) => getDay(schedule[key][0].toDate()))
        ),
      ];
      return days.includes(currentDay);
    }
    return false;
  };

  // Datepicker will loop through every day in calendar month to filter out dates

  // TODO: Prevent Appointment Overlap
  const filterTime = (date) => {
    if (schedule) {
      const currentDay = getDay(date);

      const currentTime = getHours(date) + date.getMinutes() / 60;

      // Map through time ranges. Only first item is needed to calculate what day it is
      const times = [
        ...new Set(
          Object.keys(schedule)
            .map((key) => [
              schedule[key][0].toDate(),
              schedule[key][1].toDate(),
            ])
            .filter((day) => {
              return getDay(day[0]) === currentDay;
            })
        ),
      ];
      const now = new Date();

      for (let booking of prevBookings) {
        if (booking.getTime() === date.getTime()) {
          return false;
        }
      }

      for (let time of times) {
        const startTime = getHours(time[0]) + time[0].getMinutes() / 60;
        const endTime = getHours(time[1]) + time[1].getMinutes() / 60;

        if (currentTime >= startTime && currentTime < endTime) {
          // Filter previous times from now
          if (date.toDateString() === now.toDateString()) {
            if (currentTime < getHours(now) + now.getMinutes() / 60) {
              return false;
            }
          }

          return true;
        }
      }
    }
    return false;
  };

  return (
    <>
      <MyDateInput
        name='date'
        disablePast
        timeFormat='HH:mm'
        timeIntervals={30}
        showTimeSelect
        timeCaption='Hora'
        dateFormat='MMMM d, yyyy h:mm a'
        autoComplete='off'
        filterDate={filterDate}
        filterTime={filterTime}
        prevBookings={prevBookings}
        schedule={schedule}
        minDate={new Date()}
        inline
      />
    </>
  );
};

export default DateSelectionForm;
