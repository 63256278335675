import { Container, Grid, Typography } from '@material-ui/core';
import { useFormikContext } from 'formik';
import useStyles from './styles.js';
import { format } from 'date-fns-2';
import es from 'date-fns-2/locale/es';

const ReviewBooking = ({ doctor }) => {
  const { values } = useFormikContext();
  const classes = useStyles();

  const {
    date,
    name,
    lastName,
    symptoms,
    address1,
    address2,
    city,
    state,
    phone,
  } = values;

  return (
    <Container maxWidth='md'>
      <Typography
        variant='h5'
        align='center'
        color='primary'
        style={{ marginBottom: '2rem' }}
        gutterBottom
      >
        Por favor revise sus datos y proceda al pago
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6} className={classes.textAlignLeft}>
          {doctor && (
            <>
              <Typography color='primary' gutterBottom>
                Especialista
              </Typography>
              <Typography gutterBottom className={classes.reviewText}>
                {`${doctor.name} ${doctor.lastName}`}
              </Typography>
              <Typography color='primary' gutterBottom>
                Precio de la Consulta
              </Typography>
              <Typography gutterBottom className={classes.reviewText}>
                {doctor.paymentInfo.appointmentRate} Bs.
              </Typography>
              <Typography color='primary' gutterBottom>
                Fecha y Hora
              </Typography>
              <Typography gutterBottom className={classes.reviewText}>
                {format(new Date(date), 'MMMM d, yyyy h:mm a', {
                  locale: es,
                })}
              </Typography>
            </>
          )}
        </Grid>

        <Grid item xs={12} sm={6} className={classes.textAlignLeft}>
          <Typography color='primary' gutterBottom>
            Síntomas
          </Typography>
          <Typography gutterBottom className={classes.reviewText}>
            {symptoms}
          </Typography>
          <Typography color='primary' gutterBottom>
            Datos del Paciente
          </Typography>
          <Typography>{`${name} ${lastName}`}</Typography>
          <Typography>{`${address1}`}</Typography>
          {address2 && <Typography>{`${address2}`}</Typography>}
          <Typography>{`${city}`}</Typography>
          <Typography>{`${state}`}</Typography>
          <Typography>Tel: {`${phone}`}</Typography>
        </Grid>
      </Grid>
    </Container>
  );
};

export default ReviewBooking;
