import { makeStyles } from '@material-ui/core';

export default makeStyles((theme) => ({
  root: {
    width: '100%',
    maxWidth: 900,
    margin: '0 auto',
  },
  flexColumn: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },

  userPhotoWrapper: {
    height: 200,
    width: 200,
    borderRadius: '50%',
    overflow: 'hidden',
  },
  userPhoto: {
    height: '100%',
    objectFit: 'cover',
  },
  userName: {
    marginLeft: '2rem',
  },
  noBookingsText: {
    padding: theme.spacing(5, 0),
  },
  tableHead: {
    '&  *': {
      fontWeight: 600,
    },
  },
  detailsBtn: {
    cursor: 'pointer',
    '& :hover': {
      color: theme.palette.primary.main,
    },
  },
  filter: {
    float: 'right',
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  bookingStatus: {
    '& > *': {
      fontSize: '1rem',
    },
  },
  minHeight: {
    minHeight: 240,
  },
  userStatus: {
    height: '100%',
  },
}));
