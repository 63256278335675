import { useState } from 'react';
import {
  Box,
  Button,
  Container,
  Grid,
  Paper,
  Typography,
} from '@material-ui/core';
import useStyles from './styles';
import * as Yup from 'yup';
import MyTextInput from '../../../../components/shared/form/MyTextInput';
import Footer from '../../../../components/layout/Footer/Footer';
import MySelectInput from '../../../../components/shared/form/MySelectInput';
import { Form, Formik } from 'formik';
import MyButton from '../../../../components/shared/form/MyButton';
import MyCheckbox from '../../../../components/shared/form/MyCheckbox';
import initialValues from './formModel/formInitialValues';
import { toast } from 'react-toastify';
import { Link as RouterLink, Redirect } from 'react-router-dom';
import MyFileInput from '../../../../components/shared/form/MyFileInput';
import {
  uploadAcademicDocs,
  uploadSpecalizationDocs,
} from '../../../../services/firebaseStorage';
import {
  getCategories,
  updateUserProfile,
} from '../../../../services/firestore';
import { useSelector } from 'react-redux';
import useFirestoreCollection from '../../../../hooks/useFirestoreCollection';

const reqMsg = 'Campo requerido';

const _states = [
  { label: 'Beni', value: 'Beni' },
  { label: 'Cochabamba', value: 'Cochabamba' },
  { label: 'La Paz', value: 'La Paz' },
  { label: 'Oruro', value: 'Oruro' },
  { label: 'Pando', value: 'Pando' },
  { label: 'Potosí', value: 'Potosí' },
  { label: 'Santa Cruz', value: 'Santa Cruz' },
  { label: 'Tarija', value: 'Tarija' },
];

const _validationSchema = Yup.object({
  name: Yup.string().required(reqMsg),
  lastName: Yup.string().required(reqMsg),
  billingName: Yup.string(),
  billingId: Yup.string(),
  description: Yup.string(),
  medicalLicenseId: Yup.string().required(reqMsg),
  specialtyPrimary: Yup.string().required(reqMsg),
  specialtySecondary: Yup.string(),
  university: Yup.string().required(reqMsg),
  address1: Yup.string().required(reqMsg),
  address2: Yup.string(),
  state: Yup.string().required(reqMsg),
  phone: Yup.string().required(reqMsg),
  acceptedTerms: Yup.boolean()
    .required('Debes aceptar los términos y condiciones')
    .oneOf([true], 'Debes aceptar los términos y condiciones'),
  academicRecords: Yup.string().required(reqMsg),
  specializationRecords: Yup.string().required(reqMsg),
});

const DoctorCompleteRegistrationForm = ({ mode }) => {
  const classes = useStyles();
  const [categories, setCategories] = useState([]);
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const { currentUser } = useSelector((state) => state.auth);

  useFirestoreCollection({
    query: () => getCategories(),
    data: (categories) =>
      setCategories(
        categories.map((cat) => ({
          label: cat.name,
          value: cat.name,
        }))
      ),
    deps: [],
  });

  const handleSubmit = (values) => {
    setLoading(true);
    const uploadTask1 = uploadAcademicDocs(values.academicRecords);
    uploadTask1.on(
      'state_changed',
      (snapshot) => {
        const progress =
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        console.log('Upload is ' + progress + '% done');
      },
      (error) => {
        toast.error(error.message);
      },
      () => {
        uploadTask1.snapshot.ref.getDownloadURL().then((downloadURL) => {
          values.academicRecords = downloadURL;

          const uploadTask2 = uploadSpecalizationDocs(
            values.specializationRecords
          );
          uploadTask2.on(
            'state_changed',
            (snapshot) => {
              const progress =
                (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
              console.log('Upload is ' + progress + '% done');
            },
            (error) => {
              toast.error(error.message);
            },
            () => {
              uploadTask2.snapshot.ref.getDownloadURL().then((downloadURL) => {
                values.specializationRecords = downloadURL;
                values.isProcessing = true;
                values.activated = false;
                values.profileComplete = false;
                updateUserProfile(values)
                  .then(() => {
                    setLoading(true);
                    setSuccess(true);
                  })
                  .catch((error) => {
                    toast.error(error.message);
                    setLoading(true);
                  });
              });
            }
          );
        });
      }
    );
  };

  // Prefill a displayName if available from auth provider
  const setInitialValues = () => {
    if (currentUser?.displayName) {
      initialValues.displayName = currentUser.displayName;
      return initialValues;
    }
    return initialValues;
  };

  const renderForm = () => (
    <Paper className={classes.paper} elevation={2}>
      <Typography
        align='center'
        color='primary'
        variant='h4'
        component='h1'
        className={classes.title}
      >
        Ingresa tus datos
      </Typography>
      <Typography
        align='center'
        variant='body1'
        component='p'
        className={classes.subHeading}
        gutterBottom
      >
        Para finalizar el proceso de registro necesitamos unos datos
      </Typography>
      <Formik
        initialValues={setInitialValues()}
        validationSchema={_validationSchema}
        onSubmit={handleSubmit}
      >
        {({ values, isSubmitting, isValid, dirty }) => (
          <Form className={classes.form}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <MyTextInput
                  name='displayName'
                  label='Nombre de usuario público'
                  fullWidth
                />
                <Typography variant='subtitle2' color='primary'>
                  * Con este nombre te verán otros usuarios.
                </Typography>
              </Grid>

              <Grid item xs={12} sm={6}>
                <MyTextInput name='name' label='Nombre' fullWidth />
              </Grid>

              <Grid item xs={12} sm={6}>
                <MyTextInput name='lastName' label='Apellidos' fullWidth />
              </Grid>

              <Grid item xs={12} sm={6}>
                <MyTextInput
                  name='billingName'
                  label='Razón Social'
                  fullWidth
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <MyTextInput name='billingId' label='NIT' fullWidth />
              </Grid>
              <Grid item xs={12}>
                <MyTextInput
                  name='description'
                  label='Descripción'
                  multiline
                  rows={3}
                  rowsMax={5}
                  fullWidth
                />
                <Typography variant='subtitle2' color='primary'>
                  * ¿Que quiere que sus pacientes sepan de usted?
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <MyTextInput name='address1' label='Dirección 1' fullWidth />
              </Grid>
              <Grid item xs={12}>
                <MyTextInput name='address2' label='Dirección 2' fullWidth />
              </Grid>
              <Grid item xs={12} sm={6}>
                <MySelectInput
                  name='state'
                  label='Departamento'
                  data={_states}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <MyTextInput name='phone' label='Teléfono' fullWidth />
              </Grid>

              <Grid item xs={12} sm={6}>
                <MySelectInput
                  name='specialtyPrimary'
                  label='Especialidad Primaria'
                  data={categories}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <MySelectInput
                  name='specialtySecondary'
                  label='Especialidad Secundaria'
                  data={categories}
                  nullField={'Ninguna'}
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <MyTextInput name='university' label='Universidad' fullWidth />
              </Grid>
              <Grid item xs={12} sm={6}>
                <MyTextInput
                  name='medicalLicenseId'
                  label='Nro. Matrícula'
                  fullWidth
                />
              </Grid>
              {/* <Button variant='contained' component='label'>
                Upload File
                <input type='file' name='academicRecords' hidden />
              </Button> */}
              <Grid item xs={12}>
                <MyFileInput
                  variant='outlined'
                  label='Subir Títulación Médica'
                  id='academicRecords'
                  name='academicRecords'
                />
                {values.academicRecords == null && (
                  <Typography variant='subtitle2' color='primary'>
                    {' '}
                    * requerido / subir como PDF o imagen
                  </Typography>
                )}
              </Grid>
              <Grid item xs={12}>
                <MyFileInput
                  variant='outlined'
                  label='Subir Especialización'
                  id='specializationRecords'
                  name='specializationRecords'
                />
                {values.academicRecords == null && (
                  <Typography variant='subtitle2' color='primary'>
                    {' '}
                    * requerido / subir como PDF o imagen
                  </Typography>
                )}
              </Grid>
              <Grid item xs={12} className={classes.termsCheckbox}>
                <MyCheckbox
                  name='acceptedTerms'
                  color='primary'
                  style={{ width: 'auto' }}
                  termsLink='/terms-of-use'
                />
              </Grid>
              <Grid item xs={12}>
                <MyButton
                  type='submit'
                  label='Enviar'
                  color='primary'
                  size='medium'
                  variant='contained'
                  className={classes.submit}
                  disabled={!isValid || !dirty || isSubmitting || loading}
                  loading={loading}
                />
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
    </Paper>
  );

  const renderSuccess = () => (
    <div className={classes.paper}>
      <Typography
        align='center'
        color='primary'
        variant='h4'
        component='h1'
        gutterBottom
        className={classes.title}
      >
        Muchas gracias por registrarte a miDoctor.
      </Typography>
      <Typography
        align='center'
        variant='body1'
        component='p'
        className={classes.title}
      >
        Por favor aguarda mientras procesamos tus datos. Recibirás una
        notificación por correo electrónico cuando se haya activado tu cuenta.
      </Typography>
      <Button
        to='/'
        component={RouterLink}
        variant='contained'
        color='primary'
        className={classes.backBtn}
      >
        Volver al inicio
      </Button>
    </div>
  );

  if (!currentUser) return <Redirect to='/register' />;

  return (
    <div className={classes.root}>
      <Container component='main' maxWidth='md' className={classes.mainContent}>
        {currentUser?.isProcessing || success ? renderSuccess() : renderForm()}
      </Container>
      <Footer />
    </div>
  );
};

export default DoctorCompleteRegistrationForm;
