import { Button, Container, Grid, Paper, Typography } from '@material-ui/core';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

const AwaitingActivationPage = () => {
  const { currentUser } = useSelector((state) => state.auth);

  return (
    <Container maxWidth='sm' style={{ marginTop: '2rem' }}>
      <Paper style={{ padding: '2rem' }}>
        <Grid container>
          <Typography variant='h6' gutterBottom>
            ¡Hola {currentUser?.displayName}!
          </Typography>
          <Typography variant='body1' gutterBottom>
            Su cuenta todavía no ha sido activada. Recibirá un e-mail de
            notificación cuando hayamos procesado los datos de su cuenta.
          </Typography>
          <Button component={Link} to='/' color='primary' variant='contained'>
            Volver al Inicio
          </Button>
        </Grid>
      </Paper>
    </Container>
  );
};

export default AwaitingActivationPage;
