import { SIGN_IN_USER, SIGN_OUT_USER } from './authTypes';
import firebase from '../../config/firebase';
import { APP_LOADED } from '../async/asyncReducer';
import {
  dataFromSnapshot,
  getUserProfile,
  setEmailVerified,
  setNewUserProfileData,
} from '../../services/firestore';
//import { listenToCurrentUserProfile } from '../profile/profileActions';

export const signInUser = (payload) => {
  return {
    type: SIGN_IN_USER,
    payload,
  };
};
export const signOutUser = (payload) => {
  return {
    type: SIGN_OUT_USER,
    payload,
  };
};

export const verifyAuth = () => (dispatch) => {
  return firebase.auth().onAuthStateChanged((user) => {
    if (user) {
      if (user.uid === firebase.auth().currentUser.uid) {
        const profileRef = getUserProfile(user.uid);
        profileRef.onSnapshot((snapshot) => {
          // loads additional user data from firestore
          // Also checks that the currentUser is same as in . Caused bug where previous user's profile was being signed in

          // handle if new user (user is coming from a Social Login redirect)
          // providerData.providerId: google.com/facebook.com
          if (
            !snapshot.exists &&
            firebase.auth().currentUser.uid === user.uid &&
            localStorage.getItem('loginRole')
          ) {
            const selectedProvider = user.providerData[0].providerId.split(
              '.'
            )[0];
            const role = localStorage.getItem('loginRole');
            const newUser = {
              ...user,
              role,
              emailVerified:
                selectedProvider === 'facebook' ? true : user.emailVerified,
              activated: role !== 'doctor', // automatically activate user if not a doctor
            };
            setNewUserProfileData(newUser);
            localStorage.removeItem('loginRole');
          }

          // handle case where user and profile have already been created
          if (snapshot.exists && firebase.auth().currentUser.uid === user.uid) {
            const profileData = dataFromSnapshot(snapshot);
            if (user?.emailVerified && !profileData?.emailVerified) {
              setEmailVerified(user.uid, profileData?.role);
            }
            dispatch(signInUser(profileData));

            if (localStorage.getItem('loginRole')) {
              localStorage.removeItem('loginRole');
            }
          }
          dispatch({ type: APP_LOADED });
        });
      }
    } else {
      dispatch(signOutUser());
      dispatch({ type: APP_LOADED });
    }
  });
};
