import { Container } from '@material-ui/core';
import Footer from '../../components/layout/Footer/Footer';

const PrivacyPolicy = () => {
  return (
    <>
      <Container maxWidth='lg' style={{ margin: '3rem auto' }}>
        <h1>Políticas de privacidad de ABMEDICAL SRL</h1>
        <h3>ANTECEDENTES</h3>
        <p>
          I. La presente página web o plataforma digital www.midoctor.com.bo
          pertenece a la empresa ABMEDICAL SRL, con matrícula de comercio
          435324, con NIT 394609024, con domicilio en la calle Puerto Rico No 74
          en la ciudad de Santa Cruz de la Sierra – Bolivia, que en adelante y a
          los fines de este contrato se denominará la “EMPRESA”.
        </p>
        <p>
          II. El paciente y el médico que utilizarán los servicios de la
          presente página web serán denominados como el “USUARIO”.
        </p>
        <p>
          III. La EMPRESA tiene la presente página web que presta el servicio de
          captación o búsqueda de un médico o doctor que pueda proveer atención
          de consulta vía online, a favor del paciente. Por tanto, el paciente y
          el médico utilizan la presente plataforma para agendar, reunirse y
          concretar consultas médicas.
        </p>
        <h3>CONFIDENCIALIDAD Y PRIVACIDAD</h3>
        <p>
          I. La EMPRESA y el USUARIO están obligados a guardar en la más
          absoluta reserva y confidencialidad todos los datos e informaciones
          que llegaran a conocer durante la prestación del servicio, sin
          limitarse a notas, informes, información, análisis, compilaciones,
          traducciones, estudios o cualquier otro tipo de documento o hecho que
          obtengan y/o lleguen a conocer durante la prestación del servicio,
          aunque esta documentación no constituya información directamente
          relacionada con la ejecución de las prestaciones convenidas.
        </p>
        <p>
          II. La EMPRESA y el USUARIO son responsables de asegurar que las
          personas que reciban la información precedentemente especificada
          guarden la respectiva confidencialidad, de acuerdo con estos mismos
          términos y condiciones.
        </p>
        <p>
          III. Las obligaciones de confidencialidad de la EMPRESA y el USUARIO
          subsistirán una vez finalizado el servicio o se de de baja una cuenta
          creada por el USUARIO
        </p>
        <p>
          IV. Cualquier divulgación de la citada información confidencial debe
          contar con la previa autorización del USUARIO y la EMPRESA, y en su
          caso a través de orden judicial emanada por autoridad competente. V.
          La legislación aplicable para el servicio es la normativa de Bolivia,
          a través de su código civil.
        </p>
        <h2>Políticas de Privacidad de Terceros o Socios Publicitarios</h2>
        <p>
          La presente plataforma virtual puede contener enlaces, links,
          contenidos y referencias de servicios y aplicaciones de terceras
          personas, empresas y/o otros sitios web, que emplean tecnologías como
          cookies, JavaScript o Web Beacons, que se utilizan en sus respectivos
          anuncios y enlaces que aparecen en la presente plataforma, que se
          envían directamente al navegador de los usuarios. Reciben
          automáticamente su dirección IP cuando esto ocurre. Estas tecnologías
          se utilizan para medir la efectividad de sus campañas publicitarias y
          / o para personalizar el contenido publicitario que ve en los sitios
          web que visita. Tenga en cuenta que la presente plataforma no tiene
          acceso ni control sobre estas cookies que utilizan los anunciantes de
          terceros.
        </p>
        <h3>ACEPTACIÓN</h3>
        <p>
          Al hacer clic o presionar en el botón de aceptar, el USUARIO declara
          que ha leído, entendido y aceptado los términos de privacidad y
          confidencialidad.
        </p>
      </Container>
      <Footer />
    </>
  );
};

export default PrivacyPolicy;
