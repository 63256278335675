import React, { useState } from 'react';
import {
  Box,
  Collapse,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core';

import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import GetAppIcon from '@material-ui/icons/GetApp';

import useFirestoreCollection from '../../../hooks/useFirestoreCollection';
import { getLaboratories } from '../../../services/firestore';

import useStyles from './styles';
import { useSelector } from 'react-redux';

function Row(props) {
  const { row } = props;
  const [open, setOpen] = useState(false);
  const classes = useStyles();

  return (
    <React.Fragment>
      <TableRow className={classes.root}>
        <TableCell scope='row'>{row.name}</TableCell>
        <TableCell scope='row'>{row.labName}</TableCell>
        <TableCell scope='row' align='center'>
          <IconButton
            component='a'
            aria-label='download'
            size='medium'
            href={row.path}
            target='_blank'
            rel='noreferrer'
          >
            <GetAppIcon />
          </IconButton>
        </TableCell>
        <TableCell align='center'>
          <IconButton
            aria-label='expand row'
            size='small'
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout='auto' unmountOnExit>
            <Box margin={1}>
              <Typography variant='h6' gutterBottom component='div'>
                Descripción
              </Typography>
              <Typography variant='body1'>{row.description}</Typography>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

const DoctorLabFiles = () => {
  const classes = useStyles();
  const [files, setFiles] = useState([]);
  const { currentUser } = useSelector((state) => state.auth);

  useFirestoreCollection({
    query: () => getLaboratories(),
    data: (users) => {
      const totalFiles = [];
      users.forEach((user) => {
        if (user.files) {
          const filesToAdd = user.files
            .filter((file) => {
              if (file.permissions) {
                return file.permissions.includes(currentUser.id);
              }
              return false;
            })
            .map((file) => ({
              ...file,
              labName: user.displayName,
            }));
          totalFiles.push(...filesToAdd);
        }
      });
      setFiles(totalFiles);
    },
    deps: [currentUser],
  });

  return (
    <TableContainer className={classes.tableContainer}>
      <Typography
        variant='h6'
        color='primary'
        align='center'
        className={classes.title}
      >
        Archivos Compartidos
      </Typography>
      <Table aria-label='simple table' className={classes.table}>
        <TableHead className={classes.tableHead}>
          <TableRow>
            <TableCell component='th'>Nombre de Archivo</TableCell>
            <TableCell component='th'>Laboratorio/Centro</TableCell>
            <TableCell component='th'>Descargar</TableCell>
            <TableCell component='th'>Detalles</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {files.map((file) => (
            <Row row={file} key={file.id} />
          ))}
        </TableBody>
      </Table>

      {!files.length && (
        <Typography align='center' style={{ padding: '5rem' }}>
          No hay archivos compartidos para mostrar
        </Typography>
      )}
    </TableContainer>
  );
};

export default DoctorLabFiles;
