import { useSelector } from 'react-redux';
import { Form, Formik } from 'formik';
import { Grid, Typography } from '@material-ui/core';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import { updateUserProfile } from '../../../services/firestore';
import MyTextInput from '../../../components/shared/form/MyTextInput';
import MySelectInput from '../../../components/shared/form/MySelectInput';
import MyButton from '../../../components/shared/form/MyButton';
import useStyles from './styles';
import { useState } from 'react';

const reqMsg = 'Campo requerido';

const monedas = [
  { value: 'BOB', label: 'Bs.' },
  { value: 'USD', label: 'Dólares' },
];

const tipoCuentas = [
  {
    value: 'ahorro',
    label: 'Ahorro',
  },
  {
    value: 'corrient',
    label: 'Corriente',
  },
];

const DoctorPaymentForm = () => {
  const classes = useStyles();
  const { currentUser } = useSelector((state) => state.auth);
  const [loading, setLoading] = useState(false);

  const handleSubmit = async (values, { setSubmitting }) => {
    try {
      await updateUserProfile({
        paymentInfo: values,
      });
      toast.success('Datos guardados');
    } catch (error) {
      toast.error('Hubo un error guardando tus datos');
    }
  };

  const setInitialValues = () => {
    if (currentUser.paymentInfo) {
      return currentUser.paymentInfo;
    } else {
      return {
        bankOwner: '',
        bankOwnerId: '',
        bankAccount: '',
        bankName: '',
        currency: 'BOB',
      };
    }
  };

  return (
    <>
      <Typography variant='h5' color='primary' align='center' gutterBottom>
        Datos de Pagos
      </Typography>
      <Typography
        variant='subtitle1'
        color='primary'
        align='center'
        gutterBottom
      >
        Ingrese el precio por consulta y cuenta donde le gustaría recibir sus
        honorarios
      </Typography>
      <Formik
        initialValues={setInitialValues()}
        validationSchema={Yup.object({
          appointmentRate: Yup.number().required(reqMsg),
          bankOwner: Yup.string().required(reqMsg),
          bankOwnerId: Yup.string().required(reqMsg),
          bankAccount: Yup.number().required(reqMsg),
          bankAccountType: Yup.string().required(reqMsg),
          bankName: Yup.string().required(reqMsg),
          currency: Yup.string().required(reqMsg),
        })}
        onSubmit={handleSubmit}
      >
        {({ values, isSubmitting, isValid, dirty }) => (
          <Form className={classes.form}>
            <Grid container spacing={2}>
              <Grid item xs={6} style={{ margin: '2rem 0' }}>
                <MyTextInput
                  name='appointmentRate'
                  label='Precio por Consulta (Bs.)'
                  type='number'
                  min={1}
                  InputProps={{ inputProps: { min: 0 } }}
                  fullWidth
                />
              </Grid>
              <Grid item xs={6}></Grid>
              <Grid item xs={6}>
                <MyTextInput
                  name='bankOwner'
                  label='Titular de la Cuenta'
                  fullWidth
                />
              </Grid>
              <Grid item xs={6}>
                <MyTextInput name='bankOwnerId' label='CI / NIT' fullWidth />
              </Grid>
              <Grid item xs={8} sm={8} lg={4}>
                <MyTextInput
                  name='bankAccount'
                  label='Nro. Cuenta'
                  type='number'
                  fullWidth
                />
              </Grid>
              <Grid item xs={4} sm={4} lg={2}>
                <MySelectInput name='currency' label='Moneda' data={monedas} />
              </Grid>
              <Grid item xs={12} sm={8} lg={4}>
                <MyTextInput
                  name='bankName'
                  label='Nombre del Banco'
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} sm={4} lg={2}>
                <MySelectInput
                  name='bankAccountType'
                  label='Tipo de Cuenta'
                  data={tipoCuentas}
                />
              </Grid>
              <Grid item xs={12}>
                <MyButton
                  type='submit'
                  label='Guardar'
                  color='primary'
                  size='medium'
                  variant='contained'
                  className={classes.submit}
                  disabled={!isValid || !dirty || isSubmitting || loading}
                  loading={loading}
                />
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default DoctorPaymentForm;
