const initialValues = {
  displayName: '',
  address1: '',
  address2: '',
  state: '',
  phone: '',
  specialtyPrimary: '',
  specialtySecondary: '',
  university: '',
  academicRecords: null,
};

export default initialValues;
