import { useSelector } from 'react-redux';
import { Form, Formik } from 'formik';
import { Button, Grid, Typography } from '@material-ui/core';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import { getCategories, updateUserProfile } from '../../../services/firestore';
import useFirestoreCollection from '../../../hooks/useFirestoreCollection';
import MyTextInput from '../../../components/shared/form/MyTextInput';
import MySelectInput from '../../../components/shared/form/MySelectInput';
import MyButton from '../../../components/shared/form/MyButton';
import useStyles from './styles';
import { useState } from 'react';
import DoctorProfileImage from './DoctorProfileImage';
import { Link } from 'react-router-dom';

const _states = [
  { label: 'Beni', value: 'Beni' },
  { label: 'Cochabamba', value: 'Cochabamba' },
  { label: 'La Paz', value: 'La Paz' },
  { label: 'Oruro', value: 'Oruro' },
  { label: 'Pando', value: 'Pando' },
  { label: 'Potosí', value: 'Potosí' },
  { label: 'Santa Cruz', value: 'Santa Cruz' },
  { label: 'Tarija', value: 'Tarija' },
];

const reqMsg = 'Campo requerido';

const DoctorProfileEdit = () => {
  const classes = useStyles();
  const { currentUser } = useSelector((state) => state.auth);
  const [categories, setCategories] = useState([]);
  const [loading, setLoading] = useState(false);

  useFirestoreCollection({
    query: () => getCategories(),
    data: (categories) =>
      setCategories(
        categories.map((cat) => ({
          label: cat.name,
          value: cat.name,
        }))
      ),
    deps: [],
  });

  const handleSubmit = async (values, { setSubmitting, resetForm }) => {
    try {
      await updateUserProfile(values);
      setSubmitting(false);
      resetForm({ values });
      toast.success('Sus cambios han sido guardados');
    } catch (error) {
      console.log(error);
      toast.error('Hubo un error guardando sus datos');
      setSubmitting(false);
    }
  };

  return (
    <div className={classes.root}>
      <div style={{ textAlign: 'right' }}>
        <Button
          component={Link}
          to={`/doctor-dashboard/doctors/${currentUser.id}`}
          variant='outlined'
          size='small'
          color='primary'
        >
          Ver Perfil Público
        </Button>
      </div>
      <DoctorProfileImage />
      <Typography variant='h5' color='primary' align='center' gutterBottom>
        Datos del Perfil
      </Typography>
      <Formik
        initialValues={currentUser}
        validationSchema={Yup.object({
          name: Yup.string().required(reqMsg),
          lastName: Yup.string().required(reqMsg),
          billingName: Yup.string(),
          billingId: Yup.string(),
          description: Yup.string(),
          medicalLicenseId: Yup.string().required(reqMsg),
          specialtyPrimary: Yup.string().required(reqMsg),
          specialtySecondary: Yup.string(),
          university: Yup.string().required(reqMsg),
          address1: Yup.string().required(reqMsg),
          address2: Yup.string(),
          state: Yup.string().required(reqMsg),
          phone: Yup.string().required(reqMsg),
        })}
        onSubmit={handleSubmit}
      >
        {({ values, isSubmitting, isValid, dirty }) => (
          <Form className={classes.form}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <MyTextInput
                  name='displayName'
                  label='Nombre de usuario público'
                  fullWidth
                />
                <Typography variant='subtitle2' color='primary'>
                  * Nombre de usuario público
                </Typography>
              </Grid>

              <Grid item xs={12} sm={6}>
                <MyTextInput name='name' label='Nombre' fullWidth />
              </Grid>

              <Grid item xs={12} sm={6}>
                <MyTextInput name='lastName' label='Apellidos' fullWidth />
              </Grid>

              <Grid item xs={12} sm={6}>
                <MyTextInput
                  name='billingName'
                  label='Razón Social'
                  fullWidth
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <MyTextInput name='billingId' label='NIT' fullWidth />
              </Grid>
              <Grid item xs={12}>
                <MyTextInput
                  name='description'
                  label='Descripción'
                  multiline
                  rows={3}
                  rowsMax={5}
                  fullWidth
                />
                <Typography variant='subtitle2' color='primary'>
                  * ¿Que quiere que sus pacientes sepan de usted?
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <MyTextInput name='address1' label='Dirección 1' fullWidth />
              </Grid>
              <Grid item xs={12}>
                <MyTextInput name='address2' label='Dirección 2' fullWidth />
              </Grid>
              <Grid item xs={12} sm={6}>
                <MySelectInput
                  name='state'
                  label='Departamento'
                  data={_states}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <MyTextInput name='phone' label='Teléfono' fullWidth />
              </Grid>

              <Grid item xs={12} sm={6}>
                <MySelectInput
                  name='specialtyPrimary'
                  label='Especialidad Primaria'
                  data={categories}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <MySelectInput
                  name='specialtySecondary'
                  label='Especialidad Secundaria'
                  data={categories}
                  nullField={'Ninguna'}
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <MyTextInput name='university' label='Universidad' fullWidth />
              </Grid>
              <Grid item xs={12} sm={6}>
                <MyTextInput
                  name='medicalLicenseId'
                  label='Nro. Matrícula'
                  fullWidth
                />
              </Grid>
              <Grid item xs={12}>
                <MyButton
                  type='submit'
                  label='Guardar'
                  color='primary'
                  size='medium'
                  variant='contained'
                  className={classes.submit}
                  disabled={!isValid || !dirty || isSubmitting}
                  loading={loading}
                />
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default DoctorProfileEdit;
