import { makeStyles } from '@material-ui/core';

export default makeStyles((theme) => ({
  root: {
    marginTop: '1rem',
    [theme.breakpoints.up('sm')]: {
      marginTop: theme.spacing(8),
    },
    display: 'flex',
    flexDirection: 'column',
  },
  schedulerTitle: {
    marginTop: theme.spacing(2),
    fontWeight: 600,
  },
  schedulerError: {
    color: 'red',
  },
  sucursalSelect: {
    marginTop: theme.spacing(3),
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    '& > div': {
      minWidth: 180,
    },
    '& > a': {
      marginLeft: theme.spacing(2),
    },
  },
}));
