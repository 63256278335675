import React from 'react';
import { Box, Grid, Typography } from '@material-ui/core';
import useStyles from './styles';
import icon1 from '../../../../assets/images/psection-icon-1.svg';
import icon2 from '../../../../assets/images/psection-icon-2.svg';
import icon3 from '../../../../assets/images/psection-icon-3.svg';
import { Link } from 'react-router-dom';

const ProductSection = () => {
  const classes = useStyles();

  return (
    <Grid container className={classes.root} id='products'>
      <Grid item xs={12} sm={4} className={classes.gridItem}>
        <Link to='/consultorios' className={classes.link}>
          <Box px='2rem' className={classes.itemWrapper}>
            <img src={icon1} alt='' className={classes.icon} />
            <hr className={classes.separator} />
            <Typography
              variant='h4'
              color='primary'
              className={classes.sectionTitle}
            >
              Consultas Presenciales
            </Typography>
            <Typography
              variant='body1'
              color='primary'
              align='center'
              className={classes.sectionText}
            >
              Contamos con una red de consultorios en zonas importantes de la
              ciudad, para llevar la salud más cerca de usted.
            </Typography>
          </Box>
        </Link>
      </Grid>
      <Grid item xs={12} sm={4} className={classes.gridItem}>
        <a href='#howitworks' className={classes.link}>
          <Box
            px='2rem'
            className={`${classes.itemWrapper} ${classes.midItemWrapper}`}
          >
            <img src={icon2} alt='' className={classes.icon} />
            <hr className={`${classes.separator} ${classes.midSeparator}`} />
            <Typography
              variant='h4'
              color='secondary'
              className={classes.sectionTitle}
            >
              Video Consulta
            </Typography>
            <Typography
              variant='body1'
              color='primary'
              align='center'
              className={classes.sectionText}
            >
              Gracias a nuestra tecnología podrás hablar con tu doctor desde la
              comodidad de tu casa.
            </Typography>
          </Box>
        </a>
      </Grid>
      <Grid item xs={12} sm={4} className={classes.gridItem}>
        <Link to='/doctores' className={classes.link}>
          <Box px='2rem' className={classes.itemWrapper}>
            <img src={icon3} alt='' className={classes.icon} />
            <hr className={classes.separator} />
            <Typography
              variant='h4'
              color='primary'
              className={classes.sectionTitle}
            >
              Encuentra tu Especialista
            </Typography>
            <Typography
              variant='body1'
              color='primary'
              align='center'
              className={classes.sectionText}
            >
              Contamos con todas las especialidades. Encuentra un profesional
              médico para lo que estás necesitando.
            </Typography>
          </Box>
        </Link>
      </Grid>
    </Grid>
  );
};

export default ProductSection;
