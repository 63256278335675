import { makeStyles } from '@material-ui/core';

export default makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column',
  },
  minHeight: {
    minHeight: 240,
  },
  tableContainer: {
    marginTop: theme.spacing(5),
  },
  tableHead: {
    '& *': {
      fontWeight: 600,
    },
  },
  tableTitle: {
    fontWeight: 600,
  },

  // User Modal Styles
  modalRoot: {
    minWidth: 350,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      minWidth: 550,
    },
  },
  doctorFile: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
}));
