import { Box, Grid, Typography } from '@material-ui/core';
import React from 'react';
import useStyles from './styles';

const HeroSection = () => {
  const classes = useStyles();
  return (
    <div>
      <Grid className={classes.root}>
        <Grid item xs={12} md={8}>
          <Box px='5rem'>
            <Typography variant='h1' component='h1' className={classes.title}>
              La solución que estabas buscando
            </Typography>
          </Box>
        </Grid>
      </Grid>
    </div>
  );
};

export default HeroSection;
