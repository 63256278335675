import { makeStyles } from '@material-ui/core';

export default makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    flexGrow: 1,
    padding: theme.spacing(2),
    [theme.breakpoints.up('sm')]: {
      padding: theme.spacing(8),
    },
  },
  paper: {
    padding: '3rem 1rem',
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(5),
  },
  divider: {
    margin: '0 8px',
    width: '100%',
  },
  termsCheckbox: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    color: theme.palette.primary.main,
    fontWeight: 600,
    fontSize: '1.1rem',
    '& a': {
      fontWeight: 600,
      color: theme.palette.secondary.main,
      textDecoration: 'none',
      transition: 'all 0.3s ease',
      marginLeft: '5px',
      '&:hover': {
        color: theme.palette.primary.main,
      },
    },
  },
}));
