import { makeStyles } from '@material-ui/core';

export default makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
  },
  mainContent: {
    margin: '3rem auto',
    [theme.breakpoints.up('sm')]: {
      margin: '3rem auto',
    },
    flexGrow: 1,
  },
  title: {
    paddingBottom: theme.spacing(3),
  },

  media: {
    minHeight: 200,
  },
  card: {
    padding: theme.spacing(1),
    cursor: 'pointer',
    transition: 'all 0.2s ease-in-out',
    '&:hover': {
      background: theme.palette.secondary.main,
      color: 'white',
    },
  },
  labRegisterText: {
    textAlign: 'center',
    marginTop: '3rem',
    padding: '1rem',
    paddingTop: '1.2rem',
    '& .flask-icon': {
      width: '50px',
      height: '50px',
      margin: 0,
      '& g': {
        fill: theme.palette.primary.main,
      },
    },
    '& a': {
      fontWeight: 600,
      color: theme.palette.secondary.main,
      textDecoration: 'none',
      transition: 'all 0.3s ease',
      '&:hover': {
        color: theme.palette.primary.main,
        textDecoration: 'underline',
      },
    },
  },
}));
