import * as Yup from 'yup';

const reqMsg = 'Campo requerido';

const validationSchema = Yup.object({
  displayName: Yup.string().required(reqMsg),
  billingName: Yup.string().required(reqMsg),
  billingId: Yup.string().required(reqMsg),
  description: Yup.string(),
  address1: Yup.string().required(reqMsg),
  address2: Yup.string(),
  state: Yup.string().required(reqMsg),
  phone: Yup.string().required(reqMsg),
  email: Yup.string().required(reqMsg),
  password: Yup.string().required(reqMsg),
  acceptedTerms: Yup.boolean()
    .required('Debes aceptar los términos y condiciones')
    .oneOf([true], 'Debes aceptar los términos y condiciones'),
  taxCertificate: Yup.string().required(reqMsg),
  sedesCertificate: Yup.string().required(reqMsg),
});

export default validationSchema;
