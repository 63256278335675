import { Grid, Typography } from '@material-ui/core';
import useStyles from './styles';

const StatsSection = () => {
  const classes = useStyles();
  return (
    <Grid container justify='center' className={classes.root}>
      <Grid item xs={12} sm={4}>
        <Typography variant='h2' component='h4' align='center'>
          <span>+</span>21
        </Typography>
        <Typography variant='h5' component='h5' align='center'>
          MÉDICOS
        </Typography>
      </Grid>
      <Grid item xs={12} sm={4}>
        <Typography variant='h2' component='h4' align='center'>
          <span>+</span>48
        </Typography>
        <Typography variant='h5' component='h5' align='center'>
          PACIENTES
        </Typography>
      </Grid>
      <Grid item xs={12} sm={4}>
        <Typography variant='h2' component='h4' align='center'>
          <span>+</span>12
        </Typography>
        <Typography variant='h5' component='h5' align='center'>
          CONSULTAS
        </Typography>
      </Grid>
    </Grid>
  );
};

export default StatsSection;
