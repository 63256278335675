import { Paper } from '@material-ui/core';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import DoctorProfilePage from '../../components/doctorList/DoctorProfile/DoctorProfilePage';
import MainLayout from '../../components/layout/MainLayout/MainLayout';

const UnauthDoctorProfilePage = ({ history }) => {
  const { authenticated, currentUser } = useSelector((state) => state.auth);

  useEffect(() => {
    if (authenticated) {
      if (currentUser.role === 'patient' && currentUser.emailVerified) {
        history.push('/patient-dashboard');
      } else if (currentUser.role === 'doctor') {
        history.push('/doctor-dashboard');
      }
    }
  }, [authenticated, currentUser, history]);

  return (
    <MainLayout>
      <Paper>
        <DoctorProfilePage />
      </Paper>
    </MainLayout>
  );
};

export default UnauthDoctorProfilePage;
