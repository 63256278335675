import {
  AppBar,
  Box,
  Button,
  Divider,
  Drawer,
  IconButton,
  Link,
  MenuItem,
  Toolbar,
  Typography,
} from '@material-ui/core';
import InputIcon from '@material-ui/icons/Input';
import PersonIcon from '@material-ui/icons/Person';
import MenuIcon from '@material-ui/icons/Menu';
import React, { useState } from 'react';
import { Link as RouterLink, NavLink, useHistory } from 'react-router-dom';
import useStyles from './styles';
import logo from '../../../assets/images/logo.svg';
import { useSelector } from 'react-redux';
import useNavResponsive from '../../../hooks/useNavResponsive';
import { toast } from 'react-toastify';
import { signOutFirebase } from '../../../services/firebaseAuth';
import UserDropdownLinks from './UserDropdownLinks';

const Navbar = () => {
  const classes = useStyles();
  const [drawerOpen, setDrawerOpen] = useState(false);
  const { authenticated, currentUser } = useSelector((state) => state.auth);
  const history = useHistory();
  const isMobile = useNavResponsive();

  const navigationLinks = [
    {
      label: 'Nosotros',
      path: '/nosotros',
    },
    {
      label: 'Consultorios',
      path: '/consultorios',
    },
    {
      label: 'Buscar Doctores',
      path:
        currentUser?.role === 'patient'
          ? '/patient-dashboard'
          : currentUser?.role === 'doctor'
          ? '/doctor-dashboard/doctors'
          : '/doctores',
    },
  ];

  const handleLogout = async () => {
    setDrawerOpen(false);
    history.push('/');
    try {
      await signOutFirebase();
      history.push('/');
    } catch (error) {
      toast.error(error.message);
    }
  };

  const displayNavLinks = () => {
    return navigationLinks.map(({ label, path }) => (
      <Link key={label} component={NavLink} to={path} underline='none'>
        {isMobile ? (
          <MenuItem onClick={() => setDrawerOpen(false)}>{label}</MenuItem>
        ) : (
          <Typography component='span'>{label}</Typography>
        )}
      </Link>
    ));
  };

  const displayAuthLinks = () => {
    return !authenticated ? (
      <div className={classes.authLinks}>
        <Button
          component={RouterLink}
          to='/register'
          variant='contained'
          size='medium'
          color='primary'
          startIcon={<PersonIcon />}
          onClick={() => setDrawerOpen(false)}
        >
          Regístrate
        </Button>

        <Button
          component={RouterLink}
          to='/login'
          variant='contained'
          size='medium'
          color='primary'
          startIcon={<InputIcon />}
          onClick={() => setDrawerOpen(false)}
        >
          Ingresar
        </Button>
      </div>
    ) : (
      <>
        <UserDropdownLinks currentUser={currentUser} logout={handleLogout} />
      </>
    );
  };

  const displayMobile = () => {
    return (
      <Toolbar className={classes.toolBar}>
        <RouterLink to='/'>
          <img src={logo} alt='Mi Doctor' className={classes.logo} />
        </RouterLink>
        <IconButton
          {...{
            edge: 'start',
            color: 'inherit',
            'aria-label': 'menu',
            'aria-haspopup': 'true',
            onClick: () => setDrawerOpen(true),
          }}
        >
          <MenuIcon />
        </IconButton>
        <Drawer
          {...{
            anchor: 'left',
            open: drawerOpen,
            onClose: () => setDrawerOpen(false),
          }}
        >
          <div className={classes.drawerContainer}>
            <RouterLink to='/'>
              <img src={logo} alt='' className={classes.logo} />
            </RouterLink>
            {displayNavLinks()}
            <Divider />
            {displayAuthLinks()}
          </div>
        </Drawer>
      </Toolbar>
    );
  };

  const displayDesktop = () => {
    return (
      <Toolbar className={classes.toolBar}>
        <RouterLink to='/'>
          <img src={logo} alt='' className={classes.logo} />
        </RouterLink>
        <div className={classes.navLinks}>{displayNavLinks()}</div>
        <Box>{displayAuthLinks()}</Box>
      </Toolbar>
    );
  };

  return (
    <>
      <AppBar position='fixed' className={classes.appBar} elevation={2}>
        {isMobile ? displayMobile() : displayDesktop()}
      </AppBar>
      <div className={classes.offset}></div>
    </>
  );
};

export default Navbar;
