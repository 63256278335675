import {
  Button,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core';
import BackupIcon from '@material-ui/icons/Backup';
import { useState } from 'react';
import {
  deleteUserFile,
  getUserProfile,
  saveFileInFirestore,
} from '../../../services/firestore';
import {
  uploadUserFile,
  deleteUserFileFromStorage,
} from '../../../services/firebaseStorage';
import { v4 as uuidv4 } from 'uuid';
import { toast } from 'react-toastify';
import useFirestoreDoc from '../../../hooks/useFirestoreDoc';
import { useSelector } from 'react-redux';
import DeleteIcon from '@material-ui/icons/Delete';
import GetAppIcon from '@material-ui/icons/GetApp';
import GroupAddIcon from '@material-ui/icons/GroupAdd';
import useStyles from './styles';
import CircularProgressWithLabel from '../../../components/shared/CircularProgressWithLabel/CircularProgressWithLabel';
import Permissions from './Permissions';

const PatientFiles = () => {
  const classes = useStyles();
  const [uploading, setUploading] = useState(false);
  const [progress, setProgress] = useState(0);
  const [userFiles, setUserFiles] = useState([]);
  const [selectedFile, setSelectedFile] = useState();
  const { currentUser } = useSelector((state) => state.auth);
  const [open, setOpen] = useState(false);

  const handleOpen = async (file) => {
    setOpen(true);
    setSelectedFile(file);
  };

  const handleUpload = async (e) => {
    const file = e.target.files[0];
    if (file == null) return;
    setUploading(true);
    try {
      const id = uuidv4();

      const uploadTask = uploadUserFile(file);
      uploadTask.on(
        'state_changed',
        (snapshot) => {
          const progress =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          setProgress(progress);
          console.log('Upload is ' + progress + '% done');
        },
        (error) => {
          toast.error(error.message);
        },
        () => {
          uploadTask.snapshot.ref.getDownloadURL().then(async (downloadURL) => {
            await saveFileInFirestore({
              id,
              name: file.name,
              path: downloadURL,
            });

            setUploading(false);
            toast.success('Archivo cargado con éxito');
          });
        }
      );
    } catch (error) {
      toast.error('Error cargando el archivo');
      console.log(error);
    }
  };

  useFirestoreDoc({
    query: () => getUserProfile(currentUser.id),
    data: (user) => {
      if (user.files) {
        setUserFiles(user.files);
      }
    },
    deps: [currentUser.id],
  });

  const handleDelete = async (file) => {
    try {
      await deleteUserFile(file.id);
      await deleteUserFileFromStorage(file.name);
      toast.success('Archivo borrado');
    } catch (error) {
      console.log(error);
      toast.error('Error borrando el archivo');
    }
  };

  return (
    <>
      <Button
        variant='contained'
        color='primary'
        component='label'
        aria-label='upload'
        disabled={uploading}
        startIcon={!uploading ? <BackupIcon /> : null}
        className={classes.fileBtn}
      >
        {uploading ? (
          <CircularProgressWithLabel
            value={progress}
            style={{ color: 'white' }}
          />
        ) : (
          'Subir Archivo'
        )}
        <input
          type='file'
          onChange={handleUpload}
          style={{ opacity: 0, position: 'absolute', left: '-9999px' }}
        />
      </Button>
      <TableContainer className={classes.tableContainer}>
        <Table aria-label='simple table'>
          <TableHead className={classes.tableHead}>
            <TableRow>
              <TableCell component='th'>Nombre de Archivo</TableCell>
              <TableCell component='th' align='center'>
                Descargar
              </TableCell>
              <TableCell component='th' align='center'>
                Permisos
              </TableCell>
              <TableCell component='th' align='center'>
                Borrar
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {userFiles.map((file) => (
              <TableRow key={file.id}>
                <TableCell scope='row'>{file.name}</TableCell>

                <TableCell scope='row' align='center'>
                  <IconButton
                    component='a'
                    aria-label='download'
                    size='medium'
                    target='_blank'
                    rel='noreferrer'
                    href={file.path}
                    download
                  >
                    <GetAppIcon />
                  </IconButton>
                </TableCell>
                <TableCell scope='row' align='center'>
                  <IconButton
                    aria-label='openModal'
                    onClick={() => handleOpen(file)}
                  >
                    <GroupAddIcon />
                  </IconButton>
                </TableCell>
                <TableCell scope='row' align='center'>
                  <IconButton
                    aria-label='delete'
                    className={classes.margin}
                    size='medium'
                    onClick={() => handleDelete(file)}
                  >
                    <DeleteIcon fontSize='inherit' />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>

        {!userFiles.length && (
          <Typography align='center' style={{ padding: '5rem' }}>
            No tienes archivos
          </Typography>
        )}
      </TableContainer>
      <Permissions
        file={selectedFile}
        setSelectedFile={setSelectedFile}
        open={open}
        setOpen={setOpen}
      />
    </>
  );
};

export default PatientFiles;
