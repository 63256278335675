import { useEffect, useState } from 'react';
import { Container, Grid, Paper, Typography } from '@material-ui/core';
import useStyles from './styles';
import initialValues from './formModel/formInitialValues';
import validationSchema from './formModel/validationSchema';
import { Form, Formik } from 'formik';
import MyTextInput from '../../../../components/shared/form/MyTextInput';
import MyButton from '../../../../components/shared/form/MyButton';
import MyCheckbox from '../../../../components/shared/form/MyCheckbox';
import MySelectInput from '../../../../components/shared/form/MySelectInput';
import { useHistory } from 'react-router-dom';
import { registerLab } from '../../../../services/firebaseAuth';
import { displayFirebaseAuthError } from '../../../../util';
import { toast } from 'react-toastify';
import MyFileInput from '../../../../components/shared/form/MyFileInput';
import {
  uploadSedesDocs,
  uploadTaxDocs,
} from '../../../../services/firebaseStorage';
import { updateUserProfile } from '../../../../services/firestore';

const _states = [
  { label: 'Beni', value: 'Beni' },
  { label: 'Cochabamba', value: 'Cochabamba' },
  { label: 'La Paz', value: 'La Paz' },
  { label: 'Oruro', value: 'Oruro' },
  { label: 'Pando', value: 'Pando' },
  { label: 'Potosí', value: 'Potosí' },
  { label: 'Santa Cruz', value: 'Santa Cruz' },
  { label: 'Tarija', value: 'Tarija' },
];

const LabRegisterForm = () => {
  const classes = useStyles();
  const history = useHistory();
  //const { authenticated } = useSelector((state) => state.auth);
  const [success, setSuccess] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (success) {
      history.push('/resolve-auth');
    }
  }, [success, history]);

  const _handleSubmit = async (values, { setSubmitting }) => {
    try {
      console.log(values);
      await registerLab(values);
      setSubmitting(false);
    } catch (error) {
      console.log(error);
      toast.error(displayFirebaseAuthError(error.code));
    }
  };

  const handleSubmit = async (values, { setSubmitting }) => {
    setSubmitting(true);
    setLoading(true);
    await registerLab(values);

    const uploadTask1 = uploadTaxDocs(values.taxCertificate);
    uploadTask1.on(
      'state_changed',
      (snapshot) => {
        const progress =
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        console.log('Upload is ' + progress + '% done');
      },
      (error) => {
        toast.error(error.message);
      },
      () => {
        uploadTask1.snapshot.ref.getDownloadURL().then((downloadURL) => {
          values.taxCertificate = downloadURL;

          const uploadTask2 = uploadSedesDocs(values.sedesCertificate);
          uploadTask2.on(
            'state_changed',
            (snapshot) => {
              const progress =
                (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
              console.log('Upload is ' + progress + '% done');
            },
            (error) => {
              toast.error(error.message);
            },
            () => {
              uploadTask2.snapshot.ref
                .getDownloadURL()
                .then(async (downloadURL) => {
                  values.sedesCertificate = downloadURL;
                  updateUserProfile(values)
                    .then(() => {
                      setSubmitting(false);
                      setSuccess(true);
                      setLoading(false);
                    })
                    .catch((error) => {
                      toast.error(error.message);
                      setSubmitting(false);
                      setLoading(false);
                    });
                });
            }
          );
        });
      }
    );
  };

  return (
    <div className={classes.root}>
      <Container className={classes.mainContent}>
        <Paper className={classes.paper}>
          <Typography
            align='center'
            color='primary'
            variant='h4'
            component='h1'
            className={classes.title}
          >
            Ingresa tus datos
          </Typography>
          <Typography
            align='center'
            variant='body1'
            component='p'
            className={classes.subHeading}
            gutterBottom
          >
            Rellena el formulario para darte de alta como laboratorio o centro
            médico
          </Typography>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
          >
            {({ values, isSubmitting, isValid, dirty }) => (
              <Form className={classes.form}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <MyTextInput
                      name='displayName'
                      label='Nombre de usuario público'
                      fullWidth
                    />
                    <Typography variant='subtitle2' color='primary'>
                      * Con este nombre te verán otros usuarios.
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <MyTextInput name='email' label='Email' fullWidth />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <MyTextInput
                      name='password'
                      label='Contraseña'
                      type='password'
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <MyTextInput
                      name='billingName'
                      label='Razón Social'
                      fullWidth
                    />
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <MyTextInput name='billingId' label='NIT' fullWidth />
                  </Grid>
                  <Grid item xs={12}>
                    <MyTextInput
                      name='description'
                      label='Descripción'
                      multiline
                      rows={3}
                      rowsMax={5}
                      fullWidth
                    />
                    <Typography variant='subtitle2' color='primary'>
                      * ¿Que quiere que los demás sepan de usted?
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <MyTextInput
                      name='address1'
                      label='Dirección 1'
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <MyTextInput
                      name='address2'
                      label='Dirección 2'
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <MySelectInput
                      name='state'
                      label='Departamento'
                      data={_states}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <MyTextInput name='phone' label='Teléfono' fullWidth />
                  </Grid>
                  <Grid item xs={12}>
                    <MyFileInput
                      variant='outlined'
                      label='Copia de su NIT'
                      id='taxCertificate'
                      name='taxCertificate'
                    />
                    {values.taxCertificate == null && (
                      <Typography variant='subtitle2' color='primary'>
                        {' '}
                        * requerido / subir como PDF o imagen
                      </Typography>
                    )}
                  </Grid>
                  <Grid item xs={12}>
                    <MyFileInput
                      variant='outlined'
                      label='Copia de Registro en el SEDES'
                      id='sedesCertificate'
                      name='sedesCertificate'
                    />
                    {values.sedesCertificate == null && (
                      <Typography variant='subtitle2' color='primary'>
                        {' '}
                        * requerido / subir como PDF o imagen
                      </Typography>
                    )}
                  </Grid>
                  <Grid item xs={12} className={classes.termsCheckbox}>
                    <MyCheckbox
                      name='acceptedTerms'
                      color='primary'
                      style={{ width: 'auto' }}
                      termsLink='/terms-of-use'
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <MyButton
                      type='submit'
                      label='Enviar'
                      color='primary'
                      size='medium'
                      variant='contained'
                      className={classes.submit}
                      disabled={!isValid || !dirty || isSubmitting || loading}
                      loading={loading || isSubmitting}
                    />
                  </Grid>
                </Grid>
              </Form>
            )}
          </Formik>
        </Paper>
      </Container>
    </div>
  );
};

export default LabRegisterForm;
