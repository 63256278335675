import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { toast } from 'react-toastify';
import Loader from '../../../components/layout/Loader/Loader';

const ResolveAuthPage = ({ history }) => {
  const { currentUser } = useSelector((state) => state.auth);

  // useEffect(() => {
  //   let timeout;
  //   if (!currentUser) {
  //     timeout = setTimeout(() => {
  //       history.push('/');
  //       toast.error(
  //         'Hubo un error con la autentificación. Intente ingresar de nuevo.'
  //       );
  //     }, 60000);
  //   }
  //   return () => clearTimeout(timeout);
  // }, [currentUser]);

  if (!currentUser) return <Redirect to='/login' />;

  if (currentUser.emailVerified) {
    switch (currentUser.role) {
      case 'doctor':
        if (!currentUser.activated)
          return <Redirect to='/register/doctor-complete' />;
        else return <Redirect to='/doctor-dashboard' />;
      case 'patient':
        return <Redirect to='/patient-dashboard' />;
      case 'laboratory':
        if (!currentUser.activated) {
          return <Redirect to='/awaiting-activation' />;
        }
        return <Redirect to='/lab-dashboard' />;
      case 'admin':
        return <Redirect to='/admin-dashboard' />;
      default:
        return <Redirect to='/' />;
    }
  } else {
    return <Redirect to='/register/email-verification' />;
  }
};

export default ResolveAuthPage;
