import { useEffect, useState } from 'react';

const useNavResponsive = () => {
  const [isMobile, setIsMobile] = useState(false);
  useEffect(() => {
    const setResponsiveness = () => {
      return window.innerWidth < 959 ? setIsMobile(true) : setIsMobile(false);
    };
    setResponsiveness();
    window.addEventListener('resize', () => setResponsiveness());
    return () => {
      window.removeEventListener('resize', () => setResponsiveness());
    };
  }, []);

  return isMobile;
};

export default useNavResponsive;
