import HomeIcon from '@material-ui/icons/Home';
import CalendarTodayIcon from '@material-ui/icons/CalendarToday';
import AccountBoxIcon from '@material-ui/icons/AccountBox';
import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFile';
import AccountTreeIcon from '@material-ui/icons/AccountTree';
import PersonIcon from '@material-ui/icons/Person';
import AccountBalanceIcon from '@material-ui/icons/AccountBalance';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import StoreIcon from '@material-ui/icons/Store';
import { ReactComponent as FlaskIcon } from '../../../assets/icons/flask-sidebar.svg';

const sidebarLinks = {
  doctor: [
    {
      text: 'Inicio',
      path: '/doctor-dashboard',
      icon: <HomeIcon color='primary' />,
    },
    {
      text: 'Mi Perfil',
      path: '/doctor-dashboard/profile/edit',
      icon: <AccountBoxIcon color='primary' />,
    },
    {
      text: 'Pagos',
      path: '/doctor-dashboard/payment',
      icon: <AccountBalanceIcon color='primary' />,
    },
    {
      text: 'Mis Horarios',
      path: '/doctor-dashboard/schedule',
      icon: <AccessTimeIcon color='primary' />,
    },
    {
      text: 'Mis Consultas',
      path: '/doctor-dashboard/my-bookings',
      icon: <CalendarTodayIcon color='primary' />,
    },
    {
      text: 'Laboratorios',
      path: '/doctor-dashboard/lab-files',
      icon: <FlaskIcon className='flask-icon' />,
    },
  ],
  patient: [
    {
      text: 'Inicio',
      path: '/patient-dashboard',
      icon: <HomeIcon color='primary' />,
    },
    {
      text: 'Mis Archivos',
      path: '/patient-dashboard/my-files',
      icon: <InsertDriveFileIcon color='primary' />,
    },
    {
      text: 'Mis Consultas',
      path: '/patient-dashboard/my-bookings',
      icon: <CalendarTodayIcon color='primary' />,
    },
  ],
  laboratory: [
    {
      text: 'Inicio',
      path: '/lab-dashboard',
      icon: <HomeIcon color='primary' />,
    },
    {
      text: 'Archivos',
      path: '/lab-dashboard/my-files',
      icon: <InsertDriveFileIcon color='primary' />,
    },
  ],
  admin: [
    {
      text: 'Inicio',
      path: '/admin-dashboard',
      icon: <HomeIcon color='primary' />,
    },
    {
      text: 'Doctores',
      path: '/admin-dashboard/users',
      icon: <PersonIcon color='primary' />,
    },
    {
      text: 'Categorías',
      path: '/admin-dashboard/categories',
      icon: <AccountTreeIcon color='primary' />,
    },
    {
      text: 'Sucursales',
      path: '/admin-dashboard/sucursales',
      icon: <StoreIcon color='primary' />,
    },
    {
      text: 'Laboratorios',
      path: '/admin-dashboard/laboratorios',
      //icon: <FlaskIcon style={{ height: '20px' }} />,
      icon: (
        <FlaskIcon
          className='flask-icon'
          style={{ height: '22px', width: '22px' }}
        />
      ),
    },
  ],
};

export default sidebarLinks;
