import { Typography } from '@material-ui/core';
import { getYear } from 'date-fns';
import { getDay, getHours } from 'date-fns-2';
import { useFormikContext } from 'formik';
import { useEffect, useState } from 'react';
import MyDateInput from '../../../components/shared/form/MyDateInput';
import MySelectInput from '../../../components/shared/form/MySelectInput';
import useFirestoreCollection from '../../../hooks/useFirestoreCollection';
import {
  getReservationsForSucursal,
  getSucursales,
} from '../../../services/firestore';

const DateReservationForm = ({ selectedSucursal, setSelectedSucursal }) => {
  const [sucursales, setSucursales] = useState([]);
  const [prevReservations, setPrevReservations] = useState([]);

  const { values } = useFormikContext();

  useFirestoreCollection({
    query: () => getSucursales(),
    data: (data) => setSucursales(data.filter((s) => s.isVisible)),
    deps: [],
  });

  useFirestoreCollection({
    query: () => getReservationsForSucursal(values.sucursal),
    data: (reservations) => {
      setPrevReservations(reservations.map((r) => r.date));
    },
    deps: [values.sucursal],
  });

  useEffect(() => {
    if (values.sucursal) {
      setSelectedSucursal(sucursales.find((s) => s.id === values.sucursal));
    }
  }, [values.sucursal, sucursales]);

  const filterDate = (date) => {
    if (selectedSucursal.schedule) {
      const schedule = selectedSucursal.schedule;
      const currentDay = getDay(date);

      // Map through time ranges. Only first item is needed to calculate what day it is
      const days = [
        ...new Set(
          Object.keys(schedule).map((key) => getDay(schedule[key][0].toDate()))
        ),
      ];
      return days.includes(currentDay);
    }
    return false;
  };

  // TODO: Prevent Appointment Overlap
  const filterTime = (date) => {
    if (selectedSucursal.schedule) {
      const schedule = selectedSucursal.schedule;
      const currentDay = getDay(date);

      const currentTime = getHours(date) + date.getMinutes() / 60;

      // Map through time ranges. Only first item is needed to calculate what day it is
      const times = [
        ...new Set(
          Object.keys(schedule)
            .map((key) => [
              schedule[key][0].toDate(),
              schedule[key][1].toDate(),
            ])
            .filter((day) => {
              return getDay(day[0]) === currentDay;
            })
        ),
      ];
      const now = new Date();

      // Limit number of bookings to "numRooms"
      // Create hashmap with booking <UnixTimestamp, Integer>
      const bookingAmt = {};
      for (let booking of prevReservations) {
        let unixTimestamp = Math.floor(booking.getTime() / 1000);
        if (!bookingAmt[unixTimestamp]) {
          bookingAmt[unixTimestamp] = 1;
        } else {
          bookingAmt[unixTimestamp] += 1;
        }
      }

      if (
        bookingAmt[Math.floor(date.getTime() / 1000)] >=
        selectedSucursal.numRooms
      ) {
        return false;
      }

      for (let time of times) {
        const startTime = getHours(time[0]) + time[0].getMinutes() / 60;
        const endTime = getHours(time[1]) + time[1].getMinutes() / 60;

        if (currentTime >= startTime && currentTime < endTime) {
          // Filter previous times from now
          if (date.toDateString() === now.toDateString()) {
            if (currentTime < getHours(now) + now.getMinutes() / 60) {
              return false;
            }
          }

          return true;
        }
      }
    }
    return false;
  };

  return (
    <div style={{ textAlign: 'center' }}>
      <div style={{ margin: '0 auto', maxWidth: '450px' }}>
        <Typography variant='h6' color='primary' gutterBottom>
          Elige el lugar de la consulta
        </Typography>
        <MySelectInput
          name='sucursal'
          label='Elegir Consultorio'
          data={sucursales.map((s) => ({
            value: s.id,
            label: s.name,
          }))}
        />
      </div>
      {selectedSucursal && (
        <div
          style={{
            margin: '2rem auto',
            width: '100%',
            maxWidth: '450px',
            height: '300px',
          }}
        >
          <iframe
            src={selectedSucursal.googleLoc}
            title='Google Iframe'
            width='100%'
            height='100%'
            style={{ border: 0 }}
            allowFullScreen=''
            loading='lazy'
          ></iframe>
        </div>
      )}
      {values.sucursal && selectedSucursal && (
        <>
          <Typography variant='h6' color='primary' gutterBottom>
            Elige una fecha
          </Typography>
          <MyDateInput
            name='date'
            disablePast
            timeFormat='HH:mm'
            timeIntervals={30}
            showTimeSelect
            timeCaption='Hora'
            dateFormat='MMMM d, yyyy h:mm a'
            autoComplete='off'
            schedule={selectedSucursal?.schedule}
            maxPerSlot={selectedSucursal.numRooms}
            prevBookings={prevReservations}
            filterDate={filterDate}
            filterTime={filterTime}
            minDate={new Date()}
            inline
          />
        </>
      )}
    </div>
  );
};

export default DateReservationForm;
