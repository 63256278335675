import { makeStyles } from '@material-ui/core';

export default makeStyles((theme) => ({
  appBar: {
    background: '#fff',
    color: 'black',
    overflow: 'hidden',
  },
  toolBar: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  navLinks: {
    marginRight: 'auto',
    '& a': {
      position: 'relative',
      display: 'inline-block',
      '&:hover': {
        color: theme.palette.primary.dark,
      },
      '&::after': {
        position: 'absolute',
        bottom: 0,
        left: 0,
        right: 0,
        margin: 'auto',
        width: '100%',
        content: '.',
        color: 'transparent',
        background: '#aaa',
        height: '1px',
      },
    },

    '& a:not(:last-child)': {
      [theme.breakpoints.up('md')]: {
        marginRight: '1rem',
      },
    },
  },
  authLinks: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'left',
    marginTop: '1rem',
    [theme.breakpoints.up('md')]: {
      marginTop: 0,
      flexDirection: 'row',
      alignItems: 'flex-end',
    },
    '& a:last-child': {
      marginTop: '1rem',
      [theme.breakpoints.up('md')]: {
        marginLeft: '1rem',
        marginTop: '0',
      },
    },
  },
  logo: {
    height: '3.5rem',
    [theme.breakpoints.up('md')]: {
      marginRight: '2rem',
    },
  },
  offset: theme.mixins.toolbar,
  drawerContainer: {
    padding: '20px 30px',
  },
  avatarMenu: {
    display: 'flex',
    alignItems: 'center',
    textTransform: 'capitalize',
    '& > span > *': {
      margin: theme.spacing(0.3),
    },
  },
  dropdownMenu: {
    '& > *': {
      color: theme.palette.primary.main,
    },
  },
  small: {
    width: theme.spacing(4),
    height: theme.spacing(4),
  },
}));
