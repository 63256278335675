import { makeStyles } from '@material-ui/core';

export default makeStyles((theme) => ({
  footer: {
    padding: theme.spacing(5, 2),
    marginTop: 'auto',
    background: `linear-gradient(to right, ${theme.palette.secondary.main}, ${theme.palette.primary.main})`,
    color: 'white',
  },
  footerTitle: {
    fontSize: '1.1rem',
    fontWeight: 600,
    textTransform: 'uppercase',
    [theme.breakpoints.down('md')]: {
      marginTop: '1rem',
    },
  },
  connect: {
    fontWeight: 400,
    fontSize: '1.1rem',
  },
  socialIcons: {
    marginTop: '16px',
  },
  socialIcon: {
    color: 'white',
    '&:hover': {
      color: theme.palette.primary.main,
    },
  },
  divider: {
    color: 'white',
    background: 'white',
    width: '70%',
  },
  siteMapLinks: {
    listStyle: 'none',
    padding: 0,
    '& li': {
      fontSize: '1.1rem',
      '& a': {
        textDecoration: 'none !important',
        color: 'white',
        '&:hover': {
          color: theme.palette.primary.main,
        },
      },
    },
  },
  copyright: {
    marginTop: '2rem',
    textAlign: 'center',
  },
}));
