import { CircularProgress, Paper, Typography } from '@material-ui/core';
import { useParams } from 'react-router-dom';

import useStyles from './styles';
import VideoCallFrame from '../daily/VideoCallFrame';
import useFirestoreDoc from '../../hooks/useFirestoreDoc';
import { getBookingOnce } from '../../services/firestore';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import FilesTable from '../doctor/videoCall/FilesTable';

const PatientCallPage = () => {
  const classes = useStyles();
  const { bookingId } = useParams();
  const [booking, setBooking] = useState();
  const [isActiveSession, setIsActiveSession] = useState(false);
  const { loading } = useSelector((state) => state.async);

  useFirestoreDoc({
    query: () => getBookingOnce(bookingId),
    data: (data) => setBooking(data),
    deps: [bookingId],
  });

  useEffect(() => {
    let interval;
    if (booking && hasStarted() && !hasEnded()) {
      setIsActiveSession(true);
    } else {
      interval = setInterval(() => {
        if (booking && hasStarted() && !hasEnded()) {
          setIsActiveSession(true);
        }
      }, 5000);
    }
    return () => clearInterval(interval);
  }, [booking]);

  const hasStarted = () => {
    if (booking) {
      return booking.date.getTime() < new Date().getTime();
    }
    return false;
  };

  const hasEnded = () => {
    return booking.date.getTime() + 3600 * 1000 < new Date().getTime();
  };

  if (loading && !booking) {
    return <CircularProgress style={{ margin: '0 auto' }} />;
  }

  if (isActiveSession)
    return (
      <div className={classes.root}>
        <VideoCallFrame roomName={booking.room.name} />
        <FilesTable booking={booking} />
      </div>
    );

  return (
    <div className={classes.root}>
      {booking && !hasStarted() ? (
        <Typography align='center' variant='h6' color='primary'>
          La consulta no ha empezado
        </Typography>
      ) : booking && hasEnded() ? (
        <Typography align='center' variant='h6' color='primary'>
          La consulta ha finalizado
        </Typography>
      ) : (
        <Typography>Hubo un error con la consulta</Typography>
      )}
      {booking && <FilesTable booking={booking} />}
    </div>
  );
};

export default PatientCallPage;
