import bookingFormModel from './bookingFormModel';
const {
  formField: {
    date,
    name,
    lastName,
    symptoms,
    address1,
    address2,
    city,
    state,
    phone,
  },
} = bookingFormModel;

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  [date.name]: '',
  [name.name]: '',
  [lastName.name]: '',
  [address1.name]: '',
  [address2.name]: '',
  [symptoms.name]: '',
  [city.name]: '',
  [state.name]: '',
  [phone.name]: '',
};
