import {
  Button,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core';
import BackupIcon from '@material-ui/icons/Backup';
import { useState } from 'react';
import { deleteUserFile, getUserProfile } from '../../../services/firestore';
import { deleteUserFileFromStorage } from '../../../services/firebaseStorage';
import { toast } from 'react-toastify';
import useFirestoreDoc from '../../../hooks/useFirestoreDoc';
import { useSelector } from 'react-redux';
import DeleteIcon from '@material-ui/icons/Delete';
import GetAppIcon from '@material-ui/icons/GetApp';
import GroupAddIcon from '@material-ui/icons/GroupAdd';
import useStyles from './styles';
import LabFilesPermissions from './LabFilesPermisions';
import LabFileModal from './LabFileModal';

const LabFiles = () => {
  const classes = useStyles();

  const [userFiles, setUserFiles] = useState([]);
  const [selectedFile, setSelectedFile] = useState();
  const { currentUser } = useSelector((state) => state.auth);
  const [open, setOpen] = useState(false);
  const [openUploadModal, setOpenUploadModal] = useState(false);

  const handleOpen = async (file) => {
    setOpen(true);
    setSelectedFile(file);
  };

  useFirestoreDoc({
    query: () => getUserProfile(currentUser.id),
    data: (user) => {
      if (user.files) {
        setUserFiles(user.files);
      }
    },
    deps: [currentUser.id],
  });

  const handleDelete = async (file) => {
    try {
      await deleteUserFile(file.id);
      await deleteUserFileFromStorage(file.name);
      toast.success('Archivo borrado');
    } catch (error) {
      console.log(error);
      toast.error('Error borrando el archivo');
    }
  };

  return (
    <>
      <Button
        color='primary'
        variant='contained'
        onClick={() => setOpenUploadModal(true)}
      >
        <BackupIcon style={{ marginRight: '1rem' }} />
        Subir Archivo
      </Button>
      <TableContainer className={classes.tableContainer}>
        <Table aria-label='simple table'>
          <TableHead className={classes.tableHead}>
            <TableRow>
              <TableCell component='th'>Nombre de Archivo</TableCell>
              <TableCell component='th' align='center'>
                Descargar
              </TableCell>
              <TableCell component='th' align='center'>
                Permisos
              </TableCell>
              <TableCell component='th' align='center'>
                Borrar
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {userFiles.map((file) => (
              <TableRow key={file.id}>
                <TableCell scope='row'>{file.name}</TableCell>

                <TableCell scope='row' align='center'>
                  <IconButton
                    component='a'
                    aria-label='download'
                    size='medium'
                    target='_blank'
                    rel='noreferrer'
                    href={file.path}
                    download
                  >
                    <GetAppIcon />
                  </IconButton>
                </TableCell>
                <TableCell scope='row' align='center'>
                  <IconButton
                    aria-label='openModal'
                    onClick={() => handleOpen(file)}
                  >
                    <GroupAddIcon />
                  </IconButton>
                </TableCell>
                <TableCell scope='row' align='center'>
                  <IconButton
                    aria-label='delete'
                    className={classes.margin}
                    size='medium'
                    onClick={() => handleDelete(file)}
                  >
                    <DeleteIcon fontSize='inherit' />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>

        {!userFiles.length && (
          <Typography align='center' style={{ padding: '5rem' }}>
            No tienes archivos
          </Typography>
        )}
      </TableContainer>
      <LabFileModal open={openUploadModal} setOpen={setOpenUploadModal} />
      <LabFilesPermissions
        file={selectedFile}
        setSelectedFile={setSelectedFile}
        open={open}
        setOpen={setOpen}
      />
    </>
  );
};

export default LabFiles;
