import { useEffect, useState } from 'react';
import useStyles from './styles';
import clsx from 'clsx';
import {
  Divider,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from '@material-ui/core';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import sidebarLinks from './sidebarLinks';
import { NavLink } from 'react-router-dom';
import useNavResponsive from '../../../hooks/useNavResponsive';

const Sidebar = ({ role }) => {
  const classes = useStyles();
  const [open, setOpen] = useState(true);

  const handleDrawerOpen = () => {
    setOpen(true);
  };
  const handleDrawerClose = () => {
    setOpen(false);
  };

  const isMobile = useNavResponsive();

  useEffect(() => {
    if (isMobile) {
      handleDrawerClose();
    } else {
      handleDrawerOpen();
    }
  }, [isMobile]);

  const renderSidebarLinks = () => {
    const links = sidebarLinks[role];
    return links.map((link) => (
      <ListItem key={link.text} component={NavLink} exact to={link.path}>
        <ListItemIcon>{link.icon}</ListItemIcon>
        <ListItemText primary={link.text} />
      </ListItem>
    ));
  };

  return (
    <Drawer
      variant='permanent'
      id='dashboard-sidebar'
      classes={{
        paper: clsx(classes.drawerPaper, !open && classes.drawerPaperClose),
      }}
      open={open}
    >
      <div className={classes.toolbarIcon}>
        <IconButton onClick={open ? handleDrawerClose : handleDrawerOpen}>
          {open ? <ChevronLeftIcon /> : <ChevronRightIcon />}
        </IconButton>
      </div>
      <Divider />
      <List className={classes.sidebarLinks}>{renderSidebarLinks()}</List>
      <Divider />
      {/* <List>{secondaryListItems}</List> */}
    </Drawer>
  );
};

export default Sidebar;
