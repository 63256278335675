import ScrollMenu from 'react-horizontal-scrolling-menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import { Paper } from '@material-ui/core';
import useStyles from './styles.js';
import SearchBox from './SearchBox.jsx';

// One item component
// selected prop will be passed
const MenuItem = ({ text, selected }) => {
  return <div className={`menu-item ${selected ? 'active' : ''}`}>{text}</div>;
};

// All items component
// Important! add unique key
export const Menu = (list, selected) =>
  list.map((el) => {
    const { name } = el;

    return <MenuItem text={name} key={name} selected={selected} />;
  });

const DoctorFilters = ({ selected, filterData, searchByTerm, categories }) => {
  const classes = useStyles();

  const menu = Menu(categories, selected);

  return (
    <Paper className={classes.filterPaper} elevation={0}>
      <ScrollMenu
        data={menu}
        arrowLeft={<ChevronLeftIcon />}
        arrowRight={<ChevronRightIcon />}
        selected={selected}
        onSelect={filterData}
      />
      <SearchBox placeholder='Buscar' fullWidth searchByTerm={searchByTerm} />
    </Paper>
  );
};

export default DoctorFilters;
