import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemText from '@material-ui/core/ListItemText';
import { Avatar, ListItemIcon, Typography } from '@material-ui/core';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import HomeIcon from '@material-ui/icons/Home';
import useStyles from './styles';
import { Link } from 'react-router-dom';

const UserDropdownLinks = ({ currentUser, logout }) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const resolveDashboardLink = () => {
    switch (currentUser.role) {
      case 'doctor':
        return '/doctor-dashboard';
      case 'patient':
        return '/patient-dashboard';
      case 'admin':
        return '/admin-dashboard';
      case 'laboratory':
        return '/lab-dashboard';
      default:
        return;
    }
  };

  return (
    <>
      <Button
        aria-controls='customized-menu'
        aria-haspopup='true'
        color='primary'
        onClick={handleClick}
        className={classes.avatarMenu}
      >
        <Avatar
          alt={currentUser.displayName}
          src={currentUser.photoURL}
          className={classes.small}
        />
        <Typography>{currentUser.displayName}</Typography>
        <KeyboardArrowDownIcon fontSize='small' />
      </Button>
      <Menu
        elevation={0}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        id='customized-menu'
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        className={classes.dropdownMenu}
      >
        <MenuItem component={Link} to={resolveDashboardLink()}>
          <ListItemIcon>
            <HomeIcon color='primary' />
          </ListItemIcon>
          <ListItemText color='info'>Inicio</ListItemText>
        </MenuItem>
        {/* <MenuItem component={Link} to={`${resolveDashboardLink()}/profile`}>
          <ListItemIcon>
            <AccountBoxIcon color='primary' />
          </ListItemIcon>
          <ListItemText color='info'>Mi Perfil</ListItemText>
        </MenuItem> */}
        <MenuItem onClick={logout}>
          <ListItemIcon>
            <ExitToAppIcon color='primary' />
          </ListItemIcon>
          <ListItemText color='info'>Salir</ListItemText>
        </MenuItem>
      </Menu>
    </>
  );
};

export default UserDropdownLinks;
