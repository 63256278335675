import {
  asyncActionError,
  asyncActionFinish,
  asyncActionStart,
} from '../async/asyncReducer';
import {
  dataFromSnapshot,
  fetchDoctorsFromFirestore,
} from '../../services/firestore';
import { CLEAR_DOCTORS, FETCH_DOCTORS } from './doctorTypes';

export const fetchActiveDoctors = () => async (dispatch) => {
  dispatch(asyncActionStart());
  try {
    const snapshot = await fetchDoctorsFromFirestore().get();
    const doctors = snapshot.docs.map((doc) => dataFromSnapshot(doc));
    const activeDoctors = doctors.filter(
      (doctor) =>
        doctor.activated &&
        doctor.paymentInfo &&
        doctor.schedule &&
        Object.keys(doctor.schedule).length > 0
    );
    dispatch({ type: FETCH_DOCTORS, payload: activeDoctors });
    dispatch(asyncActionFinish());
    return;
  } catch (error) {
    console.log(error);
    dispatch(asyncActionError(error));
  }
};

export const clearDoctors = () => {
  return {
    type: CLEAR_DOCTORS,
  };
};
