import { makeStyles } from '@material-ui/core';

export default makeStyles((theme) => ({
  pickerPaper: {
    width: '100%',
    maxWidth: 900,
    margin: '0 auto',
    padding: theme.spacing(3, 0),
    display: 'flex',
    alignItems: 'center',
    textAlign: 'center',
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
  },
  submit: {
    marginTop: '1rem',
  },
  table: {
    // minWidth: 450,
  },
  detailsBtn: {
    cursor: 'pointer',
    '& :hover': {
      color: theme.palette.primary.main,
    },
  },
  filter: {
    float: 'right',
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  bookingStatus: {
    '& > *': {
      fontSize: '1rem',
    },
  },
}));
