import { Container, Grid, Typography } from '@material-ui/core';
import { useFormikContext } from 'formik';
import useStyles from './styles.js';
import { format } from 'date-fns-2';
import es from 'date-fns-2/locale/es';

const ReviewReservation = ({ selectedSucursal }) => {
  const { values } = useFormikContext();
  const classes = useStyles();

  const { date, name, lastName, email, phone } = values;

  return (
    <Container maxWidth='md'>
      <Typography
        variant='h5'
        align='center'
        color='primary'
        style={{ marginBottom: '2rem' }}
        gutterBottom
      >
        Por favor revise sus datos y confirma la reserva
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={3}></Grid>
        <Grid item xs={12} sm={6} className={classes.textAlignLeft}>
          <Typography color='primary' gutterBottom>
            Fecha y Hora de la reserva
          </Typography>
          <Typography gutterBottom>
            {format(new Date(date), 'MMMM d, yyyy h:mm a', {
              locale: es,
            })}
          </Typography>
          <Typography
            color='primary'
            style={{ marginTop: '2rem' }}
            gutterBottom
          >
            Lugar de la reserva
          </Typography>
          <Typography gutterBottom>
            {selectedSucursal.address1}
            <br />
            {selectedSucursal.city}
            <br />
            {selectedSucursal.state}
            <br />
            Tel: {selectedSucursal.phone}
          </Typography>
          <Typography
            color='primary'
            style={{ marginTop: '2rem' }}
            gutterBottom
          >
            Datos del Paciente
          </Typography>
          <Typography gutterBottom>{`${name} ${lastName}`}</Typography>
          <Typography gutterBottom>{`${email}`}</Typography>
          <Typography gutterBottom>Tel: {`${phone}`}</Typography>
        </Grid>
        <Grid item xs={12} sm={3}></Grid>
        <Grid item xs={12} style={{ textAlign: 'center', margin: '2rem 0' }}>
          <div
            style={{
              margin: '0 auto',
              width: '100%',
              maxWidth: '450px',
              height: '300px',
            }}
          >
            <iframe
              src={selectedSucursal.googleLoc}
              width='100%'
              height='100%'
              title='Google Location'
              style={{ border: 0 }}
              allowFullScreen=''
              loading='lazy'
            ></iframe>
          </div>
        </Grid>
      </Grid>
    </Container>
  );
};

export default ReviewReservation;
