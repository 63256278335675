import { Box } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';

const DoctorListSkeleton = () => (
  <Box pt={0.5}>
    <Skeleton
      animation='wave'
      style={{ marginBottom: '1rem', height: '3rem' }}
    />
    <Skeleton
      variant='rect'
      width='100%'
      height={160}
      animation='wave'
      style={{ marginBottom: '1rem' }}
    />
    <Skeleton
      variant='rect'
      width='100%'
      height={160}
      animation='wave'
      style={{ marginBottom: '1rem' }}
    />
  </Box>
);

export default DoctorListSkeleton;
